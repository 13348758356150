import React, { useEffect, useCallback, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; 
import Typography from '@mui/material/Typography'; 
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import "css/generalSettings.css";
import PropTypes from 'prop-types';
import image1 from "components/photos/sidebars/colorsTypes.jpg.webp"; 
import image2 from "components/photos/sidebars/documentsType.jpg.webp"; 
import image3 from "components/photos/sidebars/languages.jpeg";  
import image4 from "components/photos/sidebars/countries.jpeg"; 
import image5 from "components/photos/sidebars/roadTypes.jpg"; 
import image6 from "components/photos/sidebars/communities.jpeg";      
import image7 from "components/photos/sidebars/taxes.jpg";      
import image8 from "components/photos/sidebars/codeler.jpg"; 
import image9 from "components/photos/sidebars/description.jpg"; 
import image10 from "components/photos/sidebars/treatmentype.jpeg"; 
import image11 from "components/photos/sidebars/processtype.jpeg"; 
import image12 from "components/photos/sidebars/dangerousness.jpg"; 
import image13 from "components/photos/sidebars/buttons.jpg.webp"; 
import image14 from "components/photos/sidebars/opttyes.jpeg"; 
import image15 from "components/photos/sidebars/payment.png"; 
import image16 from "components/photos/sidebars/status.jpeg"; 
import image17 from "components/photos/sidebars/reason.png"; 


const data = [{
  "id": 1,
  "title": "Text_Type_Colors",
  "information": "Text_Information_Type_Colors",
  "borde":"card border-left-danger shadow h-100 py-2",
  "icon":image1,
  "isEnabled":true
 
},{
  "id": 2,
  "title": "Text_Type_Documents",
  "information": "Text_Information_Type_Documents",
  "borde":"card border-left-success shadow h-100 py-2",
  "icon":image2,
  "isEnabled":true
},{
  "id": 3,
  "title": "Text_Type_Languages",
  "information": "Text_Information_Type_Languages",
  "borde":"card border-left-warning shadow h-100 py-2",
  "icon":image3,
  "isEnabled":true
},{
  "id": 4,
  "title": "Text_Type_Locations",
  "information": "Text_Information_Type_Locations",
  "borde":"card border-left-primary shadow h-100 py-2",
  "icon":image4,
  "isEnabled":true
},{
  "id": 5,
  "title": "Text_Type_Roads",
  "information": "Text_Information_Type_Roads",
  "borde":"card border-left-success shadow h-100 py-2",
  "icon":image5,
  "isEnabled":true
},{
  "id": 6,
  "title": "Text_Type_Community",
  "information": "Text_Information_Type_Community",
  "borde":"card border-left-warning shadow h-100 py-2",
  "icon":image6,
  "isEnabled":true
},{
  "id": 7,
  "title": "Text_Type_TypeofTaxes",
  "information": "Text_Information_Type_TypesOfTaxes",
  "borde":"card border-left-primary shadow h-100 py-2",
  "icon":image7,
  "isEnabled":true
},{
  "id": 8,
  "title": "Text_Type_CodeLer",
  "information": "Text_Information_Type_CodeLer",
  "borde":"card border-left-success shadow h-100 py-2",
  "icon":image8,
  "isEnabled":true
},{
  "id": 9,
  "title": "Text_Type_Description",
  "information": "Text_Information_Type_Description",
  "borde":"card border-left-warning shadow h-100 py-2",
  "icon":image9,
  "isEnabled":true
},{
  "id": 10,
  "title": "Text_Type_TreatmentType",
  "information": "Text_Information_Type_TreatmentType",
  "borde":"card border-left-primary shadow h-100 py-2",
  "icon":image10,
  "isEnabled":true
},{
  "id": 11,
  "title": "Text_Type_ProcessType",
  "information": "Text_Information_Type_ProcessType",
  "borde":"card border-left-success shadow h-100 py-2",
  "icon":image11,
  "isEnabled":true
},{
  "id": 12,
  "title": "Text_Type_Dangerousness",
  "information": "Text_Information_Type_Dangerousness",
  "borde":"card border-left-warning shadow h-100 py-2",
  "icon":image12,
  "isEnabled":true
},{
  "id": 13,
  "title": "Text_Type_ArticlesButtons",
  "information": "Text_Information_Type_ArticlesButtons",
  "borde":"card border-left-primary shadow h-100 py-2",
  "icon":image13,
  "isEnabled":true
},{
  "id": 14,
  "title": "Text_Type_OptType",
  "information": "Text_Information_Type_OptTypes",
  "borde":"card border-left-success shadow h-100 py-2",
  "icon":image14,
  "isEnabled":true
},{
  "id": 15,
  "title": "Text_Type_PaymentType",
  "information": "Text_Information_Type_PaymentType",
  "borde":"card border-left-warning shadow h-100 py-2",
  "icon":image15,
  "isEnabled":true
},{
  "id": 16,
  "title": "Text_Type_Status",
  "information": "Text_Information_Type_Status",
  "borde":"card border-left-primary shadow h-100 py-2",
  "icon":image16,
  "isEnabled":true
},{
  "id": 17,
  "title": "Text_Type_Reasons",
  "information": "Text_Information_Type_Reasons",
  "borde":"card border-left-success shadow h-100 py-2",
  "icon":image17,
  "isEnabled":true
}]

const GeneralSettings = ({ setShowPrincipalMenu,showModalGeneralSettings, setShowModalGeneralSettings, setIndexShowSettings }) => {

  const {t} = useTranslation(['listDataGeneralSettings']);
  const [inputText, setInputText] = useState("");
  const roles = useSelector((state) => state.loginUser.roles); 
  data[0].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Colors'))?true:false; 
  data[1].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Docuemnts'))?true:false;
  data[2].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Languages'))?true:false; 
  data[3].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Locations'))?true:false;
  data[4].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Roads'))?true:false;
  data[5].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Community'))?true:false;   
  data[6].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_TypesOfTax_Create'))?true:false;   
  data[7].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_CodeLers'))?true:false;
  data[8].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Desciption'))?true:false;
  data[9].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TreatmentType'))?true:false;
  data[10].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_ProcessType'))?true:false;
  data[11].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Dangerousness'))?true:false;
  data[12].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_ArticleButton'))?true:false;
  data[13].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TypesOpt'))?true:false;
  data[14].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TypesOfPayment'))?true:false;
  data[15].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Status'))?true:false;
  data[16].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Reasons'))?true:false;
  
   const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalGeneralSettings) {
        setInputText("")
        setShowPrincipalMenu(true);
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
      }
    },
    [ setShowPrincipalMenu,showModalGeneralSettings, setShowModalGeneralSettings, setIndexShowSettings]
  );

  const closeModalModalGeneralSettings = () => {
    setInputText("")
    setShowPrincipalMenu(true);
    setShowModalGeneralSettings(false);
    setIndexShowSettings(0);
  };

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const closeModalStore = (id) => {
    setInputText("");
    setIndexShowSettings(id);
    setShowModalGeneralSettings(false);
  };

  const filteredData = data.filter((el) => {
      if (inputText === '') {
          return el;
      }
      else {
          let dataTranslate=t(el.text);
          return dataTranslate.toLowerCase().includes(inputText);
      }
  })

  const listData= (
    <>
      {filteredData.map((post) => (
        post.isEnabled &&          
            <Grid item xs={2} sm={2} md={2} key={post.id}>
              <div className="card"  style={{width: 200, height:350}}>   
                <div className="card-header p-0">
                  <img src={post.icon} alt={post.title} style={{width: 198, height:120}}/>   
                </div>
                <div className="card-body align-middle"> 
                  <Typography className='text-center text-dark' gutterBottom variant="h6" component="div">
                    {t(post.title)}
                  </Typography>
                  <Typography className='text-justify' variant="body2" color="text.secondary">
                    {t(post.information)}
                  </Typography>
                  
                </div>
                <div className="card-footer">
                  <Button size="small" variant="contained" color="primary" onClick={(e) => closeModalStore(post.id)}>{t('Text_Select')}</Button>
                </div>
              </div>

              
         
        </Grid>
      ))}
    </>    
  );
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  return (
    <> 
      {showModalGeneralSettings ? (     
        //* Begin Page Content *//
        <div className="container-fluid">

          {/* Search input */}
          <div id="myDIV">
          <h1 className="h1NewStyle mb-2"><SettingsIcon style={{color:"#ffc107", fontSize:56 }} /> {t('Text_Settings')}</h1>
              <div>
                <button type="button" className="buttonBack" onClick={closeModalModalGeneralSettings}>{t('Button_Back')}</button>
              </div>
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputText}
              placeholder={t('Text_Search')} 
              onChange={inputHandler}
              fullWidth
              label={t('Text_Search')}>
            </TextField>
          </div>
          <br/>
          {/* Content Row */}
          <Grid
            container
            spacing={2}
            columns={12}
            justify="center"
          >
            {listData}
          </Grid>
          {/* End Content Row */}
        </div>  
        //* End Page Content *//
      ) :  null }
    </>
  );
};

GeneralSettings.propTypes = {
  setShowPrincipalMenu: PropTypes.func.isRequired,
  showModalGeneralSettings: PropTypes.bool.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired,
  setIndexShowSettings: PropTypes.func.isRequired,
};

export default GeneralSettings;