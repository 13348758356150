import React, { useEffect} from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css";  
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';

const TicketsPrint = ({ rowEditTicketsDetails, viewPrint, setViewPrint }) => {
  const { t, i18n } = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const ENDPOINTPRINTER = useSelector((state) => state.loginUser.defaultticketsformat.endpoinrprinter);
  const numberoftickets = useSelector((state) => state.loginUser.defaultticketsformat.numberoftickets); 
  const headerPrinter = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinter);
  const printerticket_type = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_type);
  const printerticket_interface = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_interface);
  const printerticket_driver = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_driver);
  
  const dispatch = useDispatch();

  const formatNumber = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) + ' Kg';
  };

  const formatNumberNoWeight = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchDataPrintTicket = async (dataToPrint) => {
      let isMounted = true;
      try {
        let respondeData={
          "headerPrinter":headerPrinter,
          "numberoftickets":numberoftickets,
          "printerticket_type":printerticket_type,
          "printerticket_interface":printerticket_interface,
          "printerticket_driver":printerticket_driver,
          "start_date_ticket":dataToPrint.start_date_ticket,
          "numberticket":(dataToPrint.numberticket.toString() || ''),
          "ori_tras_tradename": (dataToPrint.ori_tras_tradename || '') ,
          "rel_trans_tradename": (dataToPrint.rel_trans_tradename || ''),
          "vehiclestore_licenseplate":(dataToPrint.vehiclestore_licenseplate || ''), 
          "ori_tras_street":(dataToPrint.ori_tras_street || ''), 
          "linestickets": [],
          "linesticketsWithOutWeight":[]
        } 
        dataToPrint.linestickets.forEach((line) => {
          let formattedFirstWeight = formatNumber(line.firstweight);
          let formattedSecondWeight = line.secondweight!==0?formatNumber(line.secondweight):"";
          let formattedNetoWeight = line.secondweight!==0?formatNumber(line.firstweight - line.secondweight):"";
          let lineToAdd={
            "article_code":(line.article_code || ''),
            "article_namees":(line.article_namees || ''),
            "start_date_ticket":line.start_date_ticket,
            "end_date_ticket":line.end_date_ticket,
            "firstweight":formattedFirstWeight,
            "secondweight": formattedSecondWeight,
            "totalweight": formattedNetoWeight
          }
          respondeData.linestickets.push(lineToAdd); 
        });
        dataToPrint.linesticketsWithOutWeight.forEach((line) => {
          let formattedFirstWeight = formatNumberNoWeight(line.firstweight);
          let formattedSecondWeight = line.secondweight!==0?formatNumberNoWeight(line.secondweight):"";
          let formattedNetoWeight = line.secondweight!==0?formatNumberNoWeight(line.firstweight - line.secondweight):"";
          let lineToAdd={
            "article_code":(line.article_code.substring(0, 33) || ''),
            "article_namees":(line.article_namees.substring(0, 33) || ''),
            "firstweight":formattedFirstWeight,
            "secondweight": formattedSecondWeight,
            "totalweight": formattedNetoWeight
          }
          respondeData.linesticketsWithOutWeight.push(lineToAdd); 
        });

        const getData = await helpHttp().sendTickets(ENDPOINTPRINTER, `api/print-ticket`,{respondeData}); 
        if (getData.message === "OK") {
          dispatch(successErrorAction(t('Text_PRINTER_SEND'))); 
        } else {
          if (isMounted ) {
            dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
          }
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
      }
      return () => { isMounted = false;};
    };

    const printTicket = async () => {
      try {
        const response = await fetch("https://192.168.1.86:9100/print", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            text: "¡Hola, este es tu ticket!",
          }),
        });
        if (!response.ok) {
          console.error("Error al imprimir el ticket");
        }
      } catch (error) {
        console.error("Error:");
      }
    };

    const fetchDataTickets = async () => {
     
      try {
        const getData = await helpHttp().get2(ENDPOINT, `ticketsdetails/getticketbyid/`, token, rowEditTicketsDetails.id);
        if (getData.err) {
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          fetchDataPrintTicket(getData); 
         
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (rowEditTicketsDetails!==undefined && rowEditTicketsDetails.id!==undefined  && rowEditTicketsDetails.id!==null  && rowEditTicketsDetails.id!=="" && rowEditTicketsDetails.id!=="0" && rowEditTicketsDetails.id!==0
      && defaulttypesdi!==undefined  && defaulttypesdi!==null  && defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
    ) {
      if (viewPrint){
        fetchDataTickets();
        setViewPrint(false);
      }
      printTicket();
    
    }
    
    return () => {isMounted = false;};
  }, [defaulttypesdi, rowEditTicketsDetails, ENDPOINT, dispatch, t, token,i18n.language,ENDPOINTPRINTER,headerPrinter,numberoftickets,printerticket_driver,printerticket_interface,printerticket_type,setViewPrint,viewPrint]);  
 
  return (
     <>
     </>
  );
};


TicketsPrint.propTypes = {
  rowEditTicketsDetails: PropTypes.object.isRequired,
  viewPrint: PropTypes.bool.isRequired,
  setViewPrint: PropTypes.func.isRequired
};

export default TicketsPrint;
