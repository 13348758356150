import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material'; 
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import TableSortingTicketsVehicleStore from 'pages/tickets/ticketsVehicle/SortingTablesTicketsVehiclesStore'; 

const initialFiltersStore= {
  "idFrom":"",
  "idTo":"",
  "documentIdFrom":"",
  "documentIdTo":"",
  "tradenameFrom":"",
  "tradenameTo":"",
  "nameFrom":"",
  "nameTo":"",
  "contacpersonFrom":"",
  "contacpersonTo":"",
  "vehicleStoreFrom":"",
  "vehicleStoreTo":""
};
 
const initialStateStore = [{
  "rel_trans_store_id":"",
  "rel_trans_documnettype_id":0,
  "rel_trans_documnettype_name":"",
  "rel_trans_documentid":"",
  "rel_trans_tradename":"",
  "rel_trans_name":"",
  "rel_trans_contacperson":"",
  "rel_trans_grnp":"",
  "rel_trans_email":"",
  "vehicles" : [
    {
      "id":"",
      "indexrowvehicle":0,
      "licenseplate":"",
      "defaultvehicle":"",
      "tara":0,
      "pma":"",
      "checkpma":true,
      "defauldriver":""
    }
  ]
}];

const TicketsSearchVehiclesStores = ({ showModalSearchStores, setShowModalSearchStores,setRowEditVehiclesVehicles}) => {
  const {t,i18n} = useTranslation(['listDataTickets']);
  const state = useSelector((state) => state);
  const {token} = state.loginUser
  const {ENDPOINT,LIMITPAGESIZE} = state.variablesUser;
  const [dataStore, setDataStore] = useState(initialStateStore);
  const [isLoadingErrorStore, setIsLoadingErrorStore] = useState(false);
  const [isLoadingStore, setIsLoadingStore] = useState(false);  
  const [inputTextStore, setInputTextStore] = useState("");
  const [applyFiltersStore, setApplyFiltersStore]= useState(initialFiltersStore);
  const [filtersStore, setFiltersStore]= useState(initialFiltersStore);
  const [openEditFilterStore,setOpenEditFilterStore]= useState(false);
  const [errorsFiltersStore, setErrorsFiltersStore] = useState({});
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegStore,setNumTotRegStore]= useState(0);
  const [activeOffsetStore, setActiveOffsetStore]= useState(false);
  const [currencyOffsetStore, setCurrencyOffsetStore] = useState(0);
  const [rangeOffsetStore, setRangeOffsetStore] = useState([{}]); 
  const dispatch = useDispatch(); 
   
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && setShowModalSearchStores) {
        setDataStore(initialStateStore);
        setIsLoadingErrorStore(false);
        setIsLoadingStore(false);
        setInputTextStore("");
        setApplyFiltersStore(initialFiltersStore);
        setFiltersStore(initialFiltersStore);
        setOpenEditFilterStore(false);
        setErrorsFiltersStore({});
        setNumTotRegFilter(0);
        setNumTotRegStore(0);
        setActiveOffsetStore(false);
        setCurrencyOffsetStore(0);
        setRangeOffsetStore([{}]);
        setShowModalSearchStores(false);
      }
    },
    [ setDataStore,
      setIsLoadingErrorStore,
      setIsLoadingStore,
      setInputTextStore,
      setApplyFiltersStore,
      setFiltersStore,
      setOpenEditFilterStore,
      setErrorsFiltersStore,
      setNumTotRegFilter,
      setNumTotRegStore,
      setActiveOffsetStore,
      setCurrencyOffsetStore,
      setRangeOffsetStore,
      setShowModalSearchStores
    ]
  );

  const handleCloseDataEditStore = () => {
    setDataStore(initialStateStore);
    setIsLoadingErrorStore(false);
    setIsLoadingStore(false);
    setInputTextStore("");
    setApplyFiltersStore(initialFiltersStore);
    setFiltersStore(initialFiltersStore);
    setOpenEditFilterStore(false);
    setErrorsFiltersStore({});
    setNumTotRegFilter(0);
    setNumTotRegStore(0);
    setActiveOffsetStore(false);
    setCurrencyOffsetStore(0);
    setRangeOffsetStore([{}]);
    setShowModalSearchStores(false)
	};

  const toggleDrawerSearchFormStores  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditStore();
    }
  };

  let inputHandlerStore = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextStore(lowerCase);
  };

  const openEditFiltersStore = () => {
    setFiltersStore(applyFiltersStore);
    setOpenEditFilterStore(!openEditFilterStore);
  };

  const toggleDrawerFilterEditStore = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditStore();
    }
  };

  const handleCancelFiltersStore = () => {
    setErrorsFiltersStore({});
    setFiltersStore(initialFiltersStore);
    setOpenEditFilterStore(!openEditFilterStore);
  };

  const handleChangeFilterEditStore = (e) => {
    const { name, value } = e.target;
    setFiltersStore(filtersStore => ({
      ...filtersStore,
      [name]: value
    }));
  };

  const handleBlurFilterEditStore = (e) => {
    setErrorsFiltersStore(validateFormFilterStore());
    handleChangeFilterEditStore(e);
  };

  const handleSubmitFilterEditStore = () => {
    setErrorsFiltersStore(validateFormFilterStore());
    if (Object.keys(errorsFiltersStore).length === 0) {
      setApplyFiltersStore(filtersStore);
      setFiltersStore(initialFiltersStore);
      setErrorsFiltersStore({});
      setOpenEditFilterStore(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  const handleDeleteFiltersStore = () => {
    setErrorsFiltersStore({});
    setFiltersStore(initialFiltersStore);
    setApplyFiltersStore(initialFiltersStore);
    setOpenEditFilterStore(!openEditFilterStore);
  };

  const validateFormFilterStore = () => {

    let errorsFiltersStore = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (filtersStore.idFrom!=="" & !regexNumber.test(filtersStore.idFrom)) {
      errorsFiltersStore.idFrom = 'Text_TextNoNumber';
    }
    if (filtersStore.idTo!=="" & !regexNumber.test(filtersStore.idTo)) {
      errorsFiltersStore.idTo = 'Text_TextNoNumber';
    }
    if (filtersStore.idFrom!=="" & !errorsFiltersStore.idFrom!==undefined){
      if (filtersStore.idTo!=="" & !errorsFiltersStore.idTo!==undefined){
        if (parseInt(filtersStore.idTo)<parseInt(filtersStore.idFrom)){
          errorsFiltersStore.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersStore.documentIdFrom)) {
      errorsFiltersStore.documentIdFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.documentIdTo)) {
      errorsFiltersStore.documentIdTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.tradenameFrom)) {
      errorsFiltersStore.tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.tradenameTo)) {
      errorsFiltersStore.tradenameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.nameFrom)) {
      errorsFiltersStore.nameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.nameTo)) {
      errorsFiltersStore.nameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.contacpersonFrom)) {
      errorsFiltersStore.contacpersonFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.contacpersonTo)) {
      errorsFiltersStore.contacpersonTo = 'Text_TextNoValid';
    } 
     
    if (regexInvalidCharacters.test(filtersStore.vehicleStoreFrom)) {
      errorsFiltersStore.vehicleStoreFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.vehicleStoreTo)) {
      errorsFiltersStore.vehicleStoreTo = 'Text_TextNoValid';
    } 
     
    if (filtersStore.documentIdTo!=="" & filtersStore.documentIdTo<filtersStore.documentIdFrom){
      errorsFiltersStore.documentIdTo = 'Text_TextGreater_documentid';
    }
    if (filtersStore.tradenameTo!=="" & filtersStore.tradenameTo<filtersStore.tradenameFrom){
      errorsFiltersStore.tradenameTo = 'Text_TextGreater_tradename';
    }
    if (filtersStore.nameTo!=="" & filtersStore.nameTo<filtersStore.nameFrom){
      errorsFiltersStore.nameTo = 'Text_TextGreater_name';
    }
    if (filtersStore.contacpersonTo!=="" & filtersStore.contacpersonTo<filtersStore.contacpersonFrom){
      errorsFiltersStore.contacpersonTo = 'Text_TextGreater_contactName';
    }
    if (filtersStore.vehicleStoreTo!=="" & filtersStore.vehicleStoreTo<filtersStore.vehicleStoreFrom){
      errorsFiltersStore.vehicleStoreTo = 'Text_TextGreater_vehicles';
    }
    return errorsFiltersStore;
  }  

  const filteredDataStore = dataStore.filter((el) => {
    if (inputTextStore === '') {
        return el;
    }
    else {
        let dataTranslate=el.rel_trans_store_id+el.rel_trans_documentid+el.rel_trans_tradename+el.rel_trans_name+el.rel_trans_contacperson
        +(el.vehicles!==undefined && el.vehicles!==null && el.vehicles.length>0?el.vehicles.map(current => {return (current.licenseplate )}):"");
        return dataTranslate.toLowerCase().includes(inputTextStore);
    }
  })
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegStore = async () => {
      try {
        setIsLoadingStore(true);
        const getData = await helpHttp().get(ENDPOINT, `ticketsgetdata/getinformationtablestore`, token);
        if (getData.err) {
          setDataStore(initialStateStore);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegStore(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingStore(false);
      }finally {
        setIsLoadingStore(false);
      }
    };
 
    const fetchnumTotRegFilter = async () => {
      setIsLoadingStore(true);
      try {
        const getData = await helpHttp().post(ENDPOINT,`ticketsgetdata/getcountfilterstore`,token,applyFiltersStore);
        if (getData.err || getData.message === "Load failed") {
          setDataStore(initialStateStore);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingStore(false);
      } finally {
        setIsLoadingStore(false);
      }
    };

    const fetchDataStore = async () => {
      setIsLoadingStore(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT,`ticketsgetdata/getstores`,token,currencyOffsetStore,LIMITPAGESIZE,applyFiltersStore);
        if (getData.err || getData.message === "Load failed") {
          setDataStore(initialStateStore);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataStore(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingStore(false);
      } finally {
        setIsLoadingStore(false);
      }
    };
    
    if (showModalSearchStores){
      fetchnumTotRegStore();
      fetchnumTotRegFilter();
      fetchDataStore();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,i18n,token,dispatch,LIMITPAGESIZE,applyFiltersStore,currencyOffsetStore,setIsLoadingStore,showModalSearchStores]);
 

  return(
    <>
      {showModalSearchStores && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormStores(false)}
            onOpen={toggleDrawerSearchFormStores(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff', width: '900px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  {isLoadingErrorStore?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingStore?<CircularProgress color={"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_TICKETS_Stores_Search')}</h1>
                    <div>
                      <button type="button" className="buttonCreate" onClick={handleCloseDataEditStore}>{t('Button_Exit')}</button><> </>
                    </div> 
                </div>  
                <br/>
                <div className="searchStyle">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={inputTextStore}
                    placeholder={t('Text_Store_Search')} 
                    onChange={inputHandlerStore}
                    fullWidth
                    label={t('Text_Store_Search')}>
                  </TextField>
                </div>
                <br/>
                <div id="myDIV">
                  <h1 className="h6NewStyle mb-2">
                    {t('Text_Filter')}&nbsp;
                      <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.idFrom!=="" || applyFiltersStore.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.documentIdFrom!=="" || applyFiltersStore.documentIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_documentid')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.tradenameFrom!=="" || applyFiltersStore.tradenameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_tradename')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.nameFrom!=="" || applyFiltersStore.nameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_name')}</button><> </> 
                      <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.contacpersonFrom!=="" || applyFiltersStore.contacpersonTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_contacperson')}</button><> </> 
                      <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.vehicleStoreFrom!=="" || applyFiltersStore.vehicleStoreTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_vehicles')}</button><> </> 
                  </h1>
                </div>
                <br/>
                {!isLoadingStore ?
                  <>
                    <div className="card shadow mb-4">    
                      <div className="card-body">
                        <TableSortingTicketsVehicleStore
                          dataStore={filteredDataStore}
                          numTotRegFilter={ numTotRegFilter}
                          titleTable={t('Text_Stores_Table')}
                          numTotRegStore={numTotRegStore}
                          activeOffsetStore={activeOffsetStore}
                          rangeOffsetStore={rangeOffsetStore}
                          currencyOffsetStore={currencyOffsetStore}
                          setCurrencyOffsetStore={setCurrencyOffsetStore}
                          setRowEditVehiclesVehicles={setRowEditVehiclesVehicles}
                          handleCloseDataEditStore={handleCloseDataEditStore}
                        >
                        </TableSortingTicketsVehicleStore>
                      </div>             
                    </div>
                    {openEditFilterStore &&
                      <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEditStore(false)}
                        onOpen={toggleDrawerFilterEditStore(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                            <br/>
                              <div id="myDIV">
                                <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <form className="newfilterformstore" onSubmit={handleCancelFiltersStore}>  
                                <div className="form-floating mb-3 px-4 primary">
                                  <div className="form-group">
                                    <TextField
                                      id="idFrom" 
                                      name="idFrom"
                                      value={filtersStore.idFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersStore.idFrom!=null?t(errorsFiltersStore.idFrom):t('Text_Filter_Code_From')}
                                      placeholder={t('PlaceHolder_Filter_Code_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="idTo" 
                                      name="idTo"
                                      value={filtersStore.idTo}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersStore.idTo!=null?t(errorsFiltersStore.idTo):t('Text_Filter_Code_To')}
                                      placeholder={t('PlaceHolder_Filter_Code_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="documentIdFrom" 
                                      name="documentIdFrom"
                                      value={filtersStore.documentIdFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.documentIdFrom!=null?t(errorsFiltersStore.documentIdFrom):t('Text_Filter_documentId_From')}
                                      placeholder={t('PlaceHolder_Filter_documentid_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                      id="documentIdTo" 
                                      name="documentIdTo"
                                      value={filtersStore.documentIdTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.documentIdTo!=null?t(errorsFiltersStore.documentIdTo):t('Text_Filter_documentId_To')}
                                      placeholder={t('PlaceHolder_Filter_documentid_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="tradenameFrom" 
                                      name="tradenameFrom"
                                      value={filtersStore.tradenameFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.tradenameFrom!=null?t(errorsFiltersStore.tradenameFrom):t('Text_Filter_tradename_From')}
                                      placeholder={t('PlaceHolder_Filter_tradename_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="tradenameTo" 
                                      name="tradenameTo"
                                      value={filtersStore.tradenameTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.tradenameTo!=null?t(errorsFiltersStore.tradenameTo):t('Text_Filter_tradename_To')}
                                      placeholder={t('PlaceHolder_Filter_tradename_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="nameFrom" 
                                      name="nameFrom"
                                      value={filtersStore.nameFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.nameFrom!=null?t(errorsFiltersStore.nameFrom):t('Text_Filter_name_From')}
                                      placeholder={t('PlaceHolder_Filter_name_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="nameTo" 
                                      name="nameTo"
                                      value={filtersStore.nameTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.nameTo!=null?t(errorsFiltersStore.nameTo):t('Text_Filter_name_To')}
                                      placeholder={t('PlaceHolder_Filter_name_To')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="contacpersonFrom" 
                                      name="contacpersonFrom"
                                      value={filtersStore.contacpersonFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.contacpersonFrom!=null?t(errorsFiltersStore.contacpersonFrom):t('Text_Filter_contactName_From')}
                                      placeholder={t('PlaceHolder_Filter_contactName_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                      id="contacpersonTo" 
                                      name="contacpersonTo"
                                      value={filtersStore.contacpersonTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersStore.contacpersonTo!=null?t(errorsFiltersStore.contacpersonTo):t('Text_Filter_contactName_To')}
                                      placeholder={t('PlaceHolder_Filter_contactName_To')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="vehicleStoreFrom" 
                                      name="vehicleStoreFrom"
                                      value={filtersStore.vehicleStoreFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 50 }}
                                      label={errorsFiltersStore.vehicleStoreFrom!=null?t(errorsFiltersStore.vehicleStoreFrom):t('PlaceHolder_Filter_vehicles_From')}
                                      placeholder={t('PlaceHolder_Filter_vehicles_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                      id="vehicleStoreTo" 
                                      name="vehicleStoreTo"
                                      value={filtersStore.vehicleStoreTo}
                                      inputProps={{ style: {width: 400}, maxLength: 50 }}
                                      label={errorsFiltersStore.vehicleStoreTo!=null?t(errorsFiltersStore.vehicleStoreTo):t('PlaceHolder_Filter_vehicles_to')}
                                      placeholder={t('PlaceHolder_Filter_vehicles_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditStore}
                                      onChange={handleChangeFilterEditStore}/>
                                  </div>
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditStore}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersStore}>{t('Button_Cancel_Filters')}</button>
                                </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  
                  {/* End Content Row */}
                  </>:
                    <Loading isLoadingData={isLoadingStore}/>
                }
                                
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

TicketsSearchVehiclesStores.propTypes = {
  showModalSearchStores: PropTypes.bool.isRequired,
  setShowModalSearchStores: PropTypes.func.isRequired, 
  setRowEditVehiclesVehicles: PropTypes.func.isRequired
};

export default TicketsSearchVehiclesStores;
