import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box } from '@mui/material'; 
import { Typography} from '@mui/material';
import { TextField } from '@mui/material';   
import { Button } from '@mui/material';
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";  
import { Grid } from '@mui/material'; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css"; 
import { experimentalStyled as styled } from '@mui/material/styles'; 
import CircularProgress from '@mui/material/CircularProgress';   
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton' 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'; 
import TicketsSearchStoresTickets from "pages/tickets/ticketsEdit/editTickets/TicketsSearchStoresTickets";
import TicketsSearchStoresAddressesEdit from "pages/tickets/ticketsEdit/editTickets/TicketsSearchStoresAddressEdit";
import TicketsSearchVehiclesTickets from "pages/tickets/ticketsEdit/editTickets/TicketsSearchVehiclesTickets"; 
import TableSortingTicketsArticleList from "pages/tickets/ticketsEdit/editTickets/SortingTablesTicketsArticlesList";
import TicketsSearchArticlesEdit from "pages/tickets/ticketsEdit/editTickets/TicketsSearchArticlesEdit";
import TicketsSearchDIEdit from "pages/tickets/ticketsEdit/editTickets/TicketsSearchDIEdit";
import TableSortingTicketsArticleNoWeightList from "pages/tickets/ticketsEdit/editTickets/SortingTablesTicketsArticlesNoWeightList";
import PropTypes from 'prop-types';

const initialStateTickesDelivery = {
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "numberticket":0, 
  "purchasenote":0, 
  "ownnumbertickes":0, 
  "start_date_ticket": null, 
  "end_date_ticket": null, 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_tara": 0,
  "vehiclestore_pma": 0,
  "vehiclestore_checkpma": false,
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,

  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "rel_trans_grnp": "",
  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "ori_tras_email": "",
  
  "observations": "",
  "totalstartweight": 0,
  "totalendweight": 0,
  "newweightreading":0,
  "enabled":true, 
  "linestickets":[],
  "linesticketsWithOutWeight":[],
};

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":30
}];

const initialArticlesToSelect = {
  "id": 0,
  "indexrowarticle":0, 
  "tickets_id":0,
  "article_id":0,
  "article_code": "",
  "article_namees": "",
  "article_nameen": "",
  "start_date_ticket": null,
  "end_date_ticket": null,
  "firstweight": 0,
  "secondweight": 0,
  "estimatedprice":0,
  "discount":0,
  "tax":0,
  "taxincluded":false,  
  "ticketweight":true,
  "formdi_id":0,
  "ownformdi_id":0
  
}; 
 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const TicketsEditOrCreate = ({id, showModalTicketsSettings}) => {
  const {t,i18n} = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token);  
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);   
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITSARTICLESPERWEIGHT = useSelector((state) => state.loginUser.defaultticketsformat.limitsarticleperweight);
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const articlesButtonsList = useSelector((state) => state.loginUser.defaultticketsformat.articlesButtonsList);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [formTickets, setFormTickets] = useState(initialStateTickesDelivery);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});
  const [tetxtSearch, setTetxtSearch] = useState("Rel_Trans");  
  const [idCodeDeleteTickets, setIdCodeDeleteTickets] = useState(DELETE_CODE);
  const [idCodeDeleteTicketsNoWeight, setIdCodeDeleteTicketsNoWeight] = useState(DELETE_CODE);
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false);  
  const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
  const [showModalSearchArticles, setShowModalSearchArticles] = useState(false); 
  const [openDeleteRowArticles,setOpenDeleteRowArticles]= useState(false); 
  const [openDeleteRowArticlesNoWeight,setOpenDeleteRowArticlesNoWeight]= useState(false);
  const [editRowArticles, setEditRowArticles] = useState(initialArticlesToSelect); 
  const [editRowArticlesNoWeight, setEditRowArticlesNoWeight] = useState(initialArticlesToSelect); 
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect); 
  const [showModalSearchDI, setShowModalSearchDI] = useState(false); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalTicketsSettings) {
        setFormTickets(formTickets => ({
          ...formTickets,
          linestickets: [],
          linesticketsWithOutWeight: []
        }));
        setFormTickets(initialStateTickesDelivery);
        setIsLoadingData(false);
        setIsLoadingErrorData(false);
        setErrorsForm({});
        setTetxtSearch("Rel_Trans ");   
        setIdCodeDeleteTickets(DELETE_CODE);
        setIdCodeDeleteTicketsNoWeight(DELETE_CODE);
        setShowModalSearchStores(false);
        setShowModalSearchVehicles(false);
        setShowModalSearchStoresAddress(false);
        setShowModalSearchArticles(false);  
        setOpenDeleteRowArticles(false); 
        setOpenDeleteRowArticlesNoWeight(false);
        setShowModalSearchDI(false); 
        setEditRowArticles(initialArticlesToSelect); 
        setEditRowArticlesNoWeight(initialArticlesToSelect); 
        setProvinceToSelect(initialProvincesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect); 
        navigate("/ticketsdetails");
      }
    },
    [
      setFormTickets,
      setIsLoadingData,
      setIsLoadingErrorData,
      setErrorsForm,
      setTetxtSearch,  
      setIdCodeDeleteTickets,
      setIdCodeDeleteTicketsNoWeight,
      setShowModalSearchStores,
      setShowModalSearchVehicles,
      setShowModalSearchStoresAddress,
      setShowModalSearchArticles, 
      setOpenDeleteRowArticles,
      setOpenDeleteRowArticlesNoWeight,
      setEditRowArticles,   
      setEditRowArticlesNoWeight,
      setShowModalSearchDI,
      setProvinceToSelect,
      setPostalCodeToSelect,  
      showModalTicketsSettings,
      navigate,
      DELETE_CODE]
  );
  
  const handleCloseDataEditTickets = () => {
    setFormTickets(formTickets => ({
      ...formTickets,
      linestickets: [],
      linesticketsWithOutWeight: []
    }));
    setFormTickets(initialStateTickesDelivery);
    setIsLoadingData(false);
    setIsLoadingErrorData(false);
    setErrorsForm({});
    setTetxtSearch("Rel_Trans ");   
    setIdCodeDeleteTickets(DELETE_CODE);
    setIdCodeDeleteTicketsNoWeight(DELETE_CODE);
    setShowModalSearchStores(false);
    setShowModalSearchVehicles(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchArticles(false);  
    setOpenDeleteRowArticles(false);
    setOpenDeleteRowArticlesNoWeight(false);
    setShowModalSearchDI(false); 
    setEditRowArticles(initialArticlesToSelect); 
    setEditRowArticlesNoWeight(initialArticlesToSelect); 
    setProvinceToSelect(initialProvincesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    navigate("/ticketsdetails");
	};
      
  const handleBlurTickets = (e) => {
    handleChangeTickets(e);
    setErrorsForm(validateTickets());
  };

  const handleChangeTickets = (e) => {
    const { name, value } = e.target;
    setFormTickets(formTickets => ({
      ...formTickets,
      [name]: value,
    }));
  };

  const handleBlurSelectDate = (e) => {
    handleChangeSelectDate(e);
    setErrorsForm(validateTickets());
  };

  const handleChangeSelectDate = (event) => {
    setFormTickets(formTickets => ({
      ...formTickets,
      start_date_ticket: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectEndDate = (e) => {
    handleChangeSelectEndDate(e);
    setErrorsForm(validateTickets());
  };

  const handleChangeSelectEndDate = (event) => {
    setFormTickets(formTickets => ({
      ...formTickets,
      end_date_ticket: event!==null?new Date(event):null
    }));
  };

  const searchVehicles = (event) => { 
    setShowModalSearchVehicles(true);
  };

  const deleteDataVehicles = (event) => {
    setFormTickets(formTickets => ({
      ...formTickets,
      vehiclestore_id:0,
      vehiclestore_licenseplate: "",
      vehiclestore_tara:0,
      vehiclestore_pma: 0,
      vehiclestore_checkpma: false,
      vehiclestore_defaultdriver: "",
      vehiclestore_store_id: 0
    })); 
    
    setErrorsForm(validateTickets());
  }

  const searchStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStores(true);
  };

  const searchStoreOriTras = (event) => {
    setTetxtSearch("Ori_Tras")
    setShowModalSearchStores(true);
  };

  const deleteDataStoreRelTrans = (event) => {
    setFormTickets(formTickets => ({
      ...formTickets,
      rel_trans_store_id: 0,
      rel_trans_documnettype_id: 0,
      rel_trans_documentid: "",
      rel_trans_documnettype_name: "",
      rel_trans_tradename: "",
      rel_trans_name: "",
      rel_trans_contacperson: "",
      rel_trans_roadtype_id: 0,
      rel_trans_street: "",
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
      rel_trans_grnp: "",
    }));  
    setErrorsForm(validateTickets());
  };

  const deleteDataStoreOriTras = (event) => {
    setFormTickets(formTickets => ({
      ...formTickets,
      ori_tras_store_id: 0,
      ori_tras_documnettype_id: 0,
      ori_tras_documnettype_name: "",
      ori_tras_documentid: "",
      ori_tras_tradename: "",
      ori_tras_name: "",
      ori_tras_contacperson: "",
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
      ori_tras_email: ""
    }));  
    setErrorsForm(validateTickets());
  };

  const deleteDataStoreAddressOriTras = (event) => {
    setFormTickets(formTickets => ({
      ...formTickets,
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validateTickets());
  };

  const searchStoreAddress = (event) => {
    setShowModalSearchStoresAddress(true);
  };

  const selectArticle = (post) => {
    
    if (post.button_article_ticketweight){
      let articleToAdd={
        "id":"",
        "indexrowarticle":formTickets.linestickets.length>0?formTickets.linestickets[formTickets.linestickets.length-1].indexrowarticle+1:1,
        "tickets_id":formTickets.id,
        "article_id":post.button_article_id,
        "article_code":post.button_article_code,
        "article_namees":post.button_article_namees,
        "article_nameen":post.button_article_nameen,
        "start_date_ticket":formTickets.start_date_ticket,
        "end_date_ticket":formTickets.end_date_ticket,
        "firstweight":0,
        "secondweight":0,
        "estimatedprice":post.button_article_estimatedprice,
        "discount":0, 
        "tax": post.button_article_tax, 
        "taxincluded": post.button_article_taxincluded, 
        "ticketweight":post.button_article_ticketweight,
        "formdi_id":0,
        "ownformdi_id":0 
      }
      formTickets.linestickets.push(articleToAdd);
      setFormTickets(formTickets => ({
        ...formTickets
      }));

    }else{
      let articleToAdd={
        "id":"",
        "indexrowarticle":formTickets.linesticketsWithOutWeight.length>0?formTickets.linesticketsWithOutWeight[formTickets.linesticketsWithOutWeight.length-1].indexrowarticle+1:1,
        "tickets_id":formTickets.id,
        "article_id":post.button_article_id,
        "article_code":post.button_article_code,
        "article_namees":post.button_article_namees,
        "article_nameen":post.button_article_nameen,
        "start_date_ticket":formTickets.start_date_ticket,
        "end_date_ticket":formTickets.end_date_ticket,
        "firstweight":1,
        "secondweight":0,
        "estimatedprice":post.button_article_estimatedprice,
        "discount":0, 
        "tax": post.button_article_tax, 
        "taxincluded": post.button_article_taxincluded, 
        "ticketweight":post.button_article_ticketweight,
        "formdi_id":0,
        "ownformdi_id":0 
      }
      if(formTickets.linesticketsWithOutWeight.length>=LIMITSARTICLESPERWEIGHT){ 
        dispatch(warningErrorAction(t('Text_LIMITARTICESPERWEIGHT')));
      }else { 
        formTickets.linesticketsWithOutWeight.push(articleToAdd);
        setFormTickets(formTickets => ({
          ...formTickets
        }))
      }
    }
   
  };

  const handleCancelDeleteRow = () => { 
    setEditRowArticles(initialArticlesToSelect); 
    setIsLoadingData(false);    
    setIsLoadingErrorData(false);
    setIdCodeDeleteTickets(DELETE_CODE);
    setOpenDeleteRowArticles(false);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteTickets(value);
  };

  const handleCloseDeleteRow = (e) => {
    e.preventDefault();
    if (idCodeDeleteTickets!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formTickets.linestickets.findIndex(((item) => item.indexrowarticle === editRowArticles.indexrowarticle));
      if ( indexDelete !== -1 ) {
        formTickets.linestickets.splice( indexDelete, 1 );
      }
      setFormTickets(formTickets => ({
        ...formTickets,
      }))  
      setIdCodeDeleteTickets(DELETE_CODE);
      setOpenDeleteRowArticles(false);
    }
  };
  
  const handleCancelDeleteRowNoWeight = () => { 
    setEditRowArticlesNoWeight(initialArticlesToSelect); 
    setIsLoadingData(false);    
    setIsLoadingErrorData(false);
    setIdCodeDeleteTicketsNoWeight(DELETE_CODE);
    setOpenDeleteRowArticlesNoWeight(false);
  };

  const handleChangeDeleteRowNoWeight = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteTicketsNoWeight(value);
  };

  const handleCloseDeleteRowNoWeight = (e) => {
    e.preventDefault();
    if (idCodeDeleteTicketsNoWeight!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      let indexDelete=formTickets.linesticketsWithOutWeight.findIndex(((item) => item.indexrowarticle === editRowArticlesNoWeight.indexrowarticle));
      if ( indexDelete !== -1 ) {
        formTickets.linesticketsWithOutWeight.splice( indexDelete, 1 );
      }
      setFormTickets(formTickets => ({
        ...formTickets,
      }))  
      setIdCodeDeleteTicketsNoWeight(DELETE_CODE);
      setOpenDeleteRowArticlesNoWeight(false);
    }
  };

  const validateTickets = () => {
    let errorsForm = {}; 
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (regexInvalidCharacters.test(formTickets.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    
    if (regexInvalidCharacters.test(formTickets.ori_tras_documentid)) {
      errorsForm.ori_tras_documentid = 'Text_TextNoValid';
    } 
    if ( !formTickets.ori_tras_street ||  formTickets.ori_tras_street==="") {
      errorsForm.ori_tras_street = 'Text_FieldVehicleStoreStreetRequired';
    } else {
      if (regexInvalidCharacters.test(formTickets.ori_tras_street)) {
        errorsForm.ori_tras_street = 'Text_TextNoValid';
      }  
    }

    if (regexInvalidCharacters.test(formTickets.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    } 

    if ( !formTickets.vehiclestore_licenseplate ||  formTickets.vehiclestore_licenseplate==="") {
      errorsForm.vehiclestore_licenseplate = 'Text_FieldVehicleStoreLicensePlateRequired';
    } else {
      if (regexInvalidCharacters.test(formTickets.vehiclestore_licenseplate)) {
        errorsForm.vehiclestore_licenseplate = 'Text_TextNoValid';
      }  
    }

    if (!formTickets.rel_trans_tradename ||  formTickets.rel_trans_tradename==="") {
      errorsForm.rel_trans_tradename = 'Text_FieldRelTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(formTickets.rel_trans_tradename)) {
        errorsForm.rel_trans_tradename = 'Text_TextNoValid';
      }  
    }

    if (regexInvalidCharacters.test(formTickets.ori_tras_email)) {
      errorsForm.ori_tras_email = 'Text_TextNoValid';
    } 

    if (regexInvalidCharacters.test(formTickets.rel_trans_grnp)) {
      errorsForm.rel_trans_grnp = 'Text_TextNoValid';
    }  

    if (formTickets.linestickets && formTickets.linestickets.length===0) {
      errorsForm.linestickets = 'Text_FieldArticlesRequired';
    }  

    if (!formTickets.ori_tras_tradename || formTickets.ori_tras_tradename==="") {
      errorsForm.ori_tras_tradename = 'Text_FieldOriTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(formTickets.ori_tras_tradename)) {
        errorsForm.ori_tras_tradename = 'Text_TextNoValid';
      }  
    } 
    return errorsForm;
  };

  const handleSubmitDataEditTickets = () => {
    const errors = validateTickets();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) 
        && formTickets.vehiclestore_licenseplate!=='' 
        && formTickets.rel_trans_tradename!==''  
        && formTickets.ori_tras_street!==""
        && formTickets.linestickets.length>0
        && formTickets.ori_tras_tradename!==''   ) {

      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData = await helpHttp().put(ENDPOINT, `tickets/updateticketsedit`, token,formTickets);
          if (getData.status === "OK") { 
            dispatch(successErrorAction(t('Text_SaveData'))); 
            handleCloseDataEditTickets();
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      fetchData(); 
    } else {
      if (errors.vehiclestore_licenseplate){
        dispatch(warningErrorAction(t(errors.vehiclestore_licenseplate)));
      }else{
        if (errors.rel_trans_tradename){
          dispatch(warningErrorAction(t(errors.rel_trans_tradename)));
        }else{
          if (errors.ori_tras_tradename){
            dispatch(warningErrorAction(t(errors.ori_tras_tradename)));
          }else{
            if(errors.ori_tras_street){
              dispatch(warningErrorAction(t(errors.ori_tras_street)));
            } else{
              if (errors.linestickets){
                dispatch(warningErrorAction(t(errors.linestickets)));
              }else{
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
              }
            } 
          }
        }   
      }
    }
  };
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getstorebynumberdocument/`,token,formTickets.rel_trans_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formTickets.rel_trans_store_id){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchStoreNumberDocumentRelTrans = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`ticketsgetdata/getstorebynumberdocumentpriority`,token,formTickets.rel_trans_documentid,6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                  setFormTickets(formTickets => ({
                      ...formTickets,
                      rel_trans_store_id: getData.rel_trans_store_id,
                      rel_trans_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      rel_trans_documentid: getData.rel_trans_documentid,
                      rel_trans_documnettype_name: getData.rel_trans_documnettype_name,
                      rel_trans_tradename: getData.rel_trans_tradename,
                      rel_trans_name: getData.rel_trans_name,
                      rel_trans_grnp: getData.rel_trans_grnp,
                      rel_trans_contacperson: getData.rel_trans_contacperson,
                      rel_trans_roadtype_id: (getData.rel_trans_roadtype_id===undefined 
                                            || getData.rel_trans_roadtype_id===null  
                                            || getData.rel_trans_roadtype_id===""
                                            || getData.rel_trans_roadtype_id==="0"
                                            || getData.rel_trans_roadtype_id===0)?
                                            0:getData.rel_trans_roadtype_id,
                      rel_trans_street: getData.rel_trans_street,
                      rel_trans_country_id: (getData.rel_trans_country_id===undefined 
                                            || getData.rel_trans_country_id===null  
                                            || getData.rel_trans_country_id===""
                                            || getData.rel_trans_country_id==="0"
                                            || getData.rel_trans_country_id===0)?
                                            Number(DEFAULTCOUNTRY):getData.rel_trans_country_id,
                      rel_trans_province_id: (getData.rel_trans_province_id===undefined 
                                            || getData.rel_trans_province_id===null  
                                            || getData.rel_trans_province_id===""
                                            || getData.rel_trans_province_id==="0"
                                            || getData.rel_trans_province_id===0)?
                                            Number(DEFAULTPROVINCE):getData.rel_trans_province_id,
                      rel_trans_postalcode_id: (getData.rel_trans_postalcode_id===undefined 
                                                || getData.rel_trans_postalcode_id===null  
                                                || getData.rel_trans_postalcode_id===""
                                                || getData.rel_trans_postalcode_id==="0"
                                                || getData.rel_trans_postalcode_id===0)?
                                                "":getData.rel_trans_postalcode_id 
                  }));
              }  
          }         
        } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
    };
    if (formTickets.rel_trans_documentid!==""){
      fetchExistNumberDocumentRelTrans();
    } 
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,formTickets.rel_trans_store_id,formTickets.rel_trans_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormTickets]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentOriTras = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getstorebynumberdocument/`,token,formTickets.ori_tras_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formTickets.ori_tras_documentid){
            fetchStoreNumberDocumentOriTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentOriTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`ticketsgetdata/getstorebynumberdocumentpriority`,token,formTickets.ori_tras_documentid,2);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setFormTickets(formTickets => ({
                  ...formTickets,
                  ori_tras_store_id: getData.rel_trans_store_id,
                  ori_tras_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                          || getData.rel_trans_documnettype_id===null  
                                          || getData.rel_trans_documnettype_id===""
                                          || getData.rel_trans_documnettype_id==="0"
                                          || getData.rel_trans_documnettype_id===0)?
                                          0:getData.rel_trans_documnettype_id,
                  ori_tras_documnettype_name: getData.rel_trans_documnettype_name,
                  ori_tras_documentid: getData.rel_trans_documentid,
                  ori_tras_tradename: getData.rel_trans_tradename,
                  ori_tras_name: getData.rel_trans_name,
                  ori_tras_contacperson: getData.rel_trans_contacperson,
                  ori_tras_email: (formTickets.ori_tras_email===null || formTickets.ori_tras_email==="")?getData.rel_trans_email:formTickets.ori_tras_email
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formTickets.ori_tras_documentid!==""){
      fetchExistNumberDocumentOriTras();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formTickets.ori_tras_documentid,formTickets.ori_tras_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormTickets]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlate = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getexistvehicleylicenseplate/`,token,formTickets.vehiclestore_licenseplate);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formTickets.vehiclestore_licenseplate){
            fetchLicensePlate();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getvehiclebylicenseplate/`,token,formTickets.vehiclestore_licenseplate);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setFormTickets(formTickets => ({
                  ...formTickets,
                  vehiclestore_id: getData.id,
                  vehiclestore_tara: getData.tara,
                  vehiclestore_pma:  getData.pma,
                  vehiclestore_checkpma: getData.checkpma,
                  vehiclestore_defaultdriver: getData.defaultdriver,
                  vehiclestore_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id
              }));
              
              if (formTickets.rel_trans_documentid===undefined || formTickets.rel_trans_documentid===null || formTickets.rel_trans_documentid==="" ){
                if (formTickets.rel_trans_tradename===undefined || formTickets.rel_trans_tradename===null || formTickets.rel_trans_tradename===""){
                  setFormTickets(formTickets => ({
                    ...formTickets,
                    rel_trans_documentid: getData.store_documentid 
                  }));
                }
              } 
              setFormTickets(formTickets => ({
                ...formTickets,
              }))
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (formTickets.vehiclestore_licenseplate!==""){
      fetchExistLicensePlate();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formTickets.vehiclestore_licenseplate,formTickets.rel_trans_documentid,formTickets.rel_trans_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormTickets,formTickets.vehiclestore_checkpma,formTickets.linestickets]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/simpleprovincesbycountrybyname/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalTicketsSettings){
      fetchDataProvince();
    }

    return () => { isMounted = false };     
  }, [ENDPOINT,t,token,dispatch,i18n,showModalTicketsSettings,DEFAULTCOUNTRY]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = formTickets.ori_tras_province_id!==""
            ? await helpHttp().get2(ENDPOINT,`ticketsgetdata/simplepostalcodesbyprovincebyname/`,token,formTickets.ori_tras_province_id)
            : await helpHttp().get2(ENDPOINT,`ticketsgetdata/simplepostalcodesbycountry/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalTicketsSettings){
      fetchDataPostalCode(); 
    }
    if (formTickets.id === "") { 
      setFormTickets(formTickets => ({
        ...formTickets,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        isfinished:true,
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,
        rel_trans_grnp:"",
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        ori_tras_email:"",

        linestickets: [] ,
        linesticketsWithOutWeight:[]
      }));
    }
    return () => { isMounted = false };  
  }, [ENDPOINT,t,token,dispatch,i18n,showModalTicketsSettings,formTickets.ori_tras_province_id,DEFAULTCOUNTRY,DEFAULTPROVINCE,formTickets.id,defaultStore.id,useraccount_id]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataInvoiceTickets = async () => { 
    
      try {
        const getData = await helpHttp().get2(ENDPOINT, `tickets/getticketbyid/`, token, id);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setFormTickets(initialStateTickesDelivery);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setFormTickets(getData);
        }
        if (isMounted){
          setIsLoadingData(false);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        setIsLoadingData(false);
      } 
    };
    if (showModalTicketsSettings && id !== undefined && id !== "" && id !== "0" && id !== 0) {
      if (formTickets.id === "") {
        setIsLoadingData(true);
        fetchDataInvoiceTickets(); 
      }
    } else {
      setFormTickets(initialStateTickesDelivery);
      setFormTickets(formTickets => ({
        ...formTickets, 
        id:"",
        linestickets:[] ,
        linesticketsWithOutWeight:[]
      })); 
    }
    return () => { isMounted = false }; 
  }, [ENDPOINT,dispatch,id,t,token,formTickets.id,showModalTicketsSettings]); 
 
     
 return (
      <>
        {showModalTicketsSettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff', width: '1520px'}}>
              <form className="createform" onSubmit={handleCloseDataEditTickets}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className="h9NewStyle mb-2">
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className="fas fa-regular fa-clipboard text-warning"></i>)} 
                                  &nbsp;&nbsp;{t('Text_TICKETS_EDIT_OR_CREATE')+': '+formTickets.numberticket}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitDataEditTickets}>
                                      {t('Button_Update')}
                                    </button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditTickets}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <label className="text-primary">{t('Text_Tickets_FirstWeight')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="start_date_ticket" 
                                    name="start_date_ticket"
                                    value={ formTickets.start_date_ticket!== null ? new Date(formTickets.start_date_ticket):null}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    inputProps={{ style: {width: 175}, maxLength: 175 }}
                                    label={errorsForm.start_date_ticket!=null?t(errorsForm.start_date_ticket):t('Text_Tickets_FirstWeight')}
                                    onBlur={handleBlurSelectDate}
                                    onChange={handleChangeSelectDate}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                          maxLength: 175,
                                          width: 175
                                        
                                        }
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <label className="text-primary">{t('Text_Tickets_ENDWeight')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="end_date_ticket" 
                                    name="end_date_ticket"
                                    value={ formTickets.end_date_ticket!== null ? new Date(formTickets.end_date_ticket):null}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    inputProps={{ style: {width: 175}, maxLength: 100 }}
                                    label={errorsForm.end_date_ticket!=null?t(errorsForm.end_date_ticket):t('Text_Tickets_ENDWeight')}
                                    onBlur={handleBlurSelectEndDate}
                                    onChange={handleChangeSelectEndDate}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                          maxLength: 175,
                                          width: 175
                                        
                                        }
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_TICKETS_LICENSEPLATE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="vehiclestore_licenseplate" 
                                  name="vehiclestore_licenseplate"
                                  value={formTickets.vehiclestore_licenseplate}
                                  inputProps={{ style: {width: i18n.language==='es'?250:250}, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.vehiclestore_licenseplate!=null?t(errorsForm.vehiclestore_licenseplate):t('Text_TICKETS_LICENSEPLATE')}
                                  placeholder={t('Text_TICKETS_LICENSEPLATE')}
                                  variant="outlined"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                /> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataVehicles}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchVehicles}>
                                    <SearchIcon/>
                                  </IconButton>
                                }
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group">    
                                <label className="text-primary">{t('Text_TICKETS_CIF_TRANS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_documentid" 
                                  name="rel_trans_documentid"
                                  value={formTickets.rel_trans_documentid}
                                  inputProps={{ style: {width: i18n.language==='es'?161:161}, maxLength: 100 }}
                                  label={errorsForm.rel_trans_documentid!=null?t(errorsForm.rel_trans_documentid):t('Text_TICKETS_CIF_TRANS')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Text_TICKETS_CIF_TRANS')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                /> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreRelTrans}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreRelTrans}>
                                      <SearchIcon/>
                                  </IconButton>
                                }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_TICKETS_TRADENAME_TRANS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_tradename" 
                                  name="rel_trans_tradename"
                                  value={formTickets.rel_trans_tradename}
                                  inputProps={{ style: {width: i18n.language==='es'?500:421}, maxLength: 100 }} 
                                  label={errorsForm.rel_trans_tradename!=null?t(errorsForm.rel_trans_tradename):t('Text_TICKETS_TRADENAME_TRANS')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Text_TICKETS_TRADENAME_TRANS')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                /> 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_TICKETS_GRNP_TRANS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_grnp" 
                                  name="rel_trans_grnp"
                                  value={formTickets.rel_trans_grnp}
                                  inputProps={{ style: {width: i18n.language==='es'?260:260}, maxLength: 100 }} 
                                  label={errorsForm.rel_trans_grnp!=null?t(errorsForm.rel_trans_grnp):t('Text_TICKETS_GRNP_TRANS')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Text_TICKETS_GRNP_TRANS')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                />
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <Typography style={{color:"#067330", fontSize: 16}} >  
                                  {t('Text_TICKETS_TRADENAME_STORE')} 
                                </Typography>
                                <label className="text-primary">{t('Text_TICKETS_CIF_STORE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_documentid" 
                                  name="ori_tras_documentid"
                                  value={formTickets.ori_tras_documentid}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_documentid!=null?t(errorsForm.ori_tras_documentid):t('Text_TICKETS_CIF_STORE')}
                                  placeholder={t('Text_TICKETS_CIF_STORE')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                /> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreOriTras}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreOriTras}>
                                      <SearchIcon/>
                                  </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <label className="text-primary">{t('Text_TICKETS_NAME_STORE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_tradename" 
                                  name="ori_tras_tradename"
                                  value={formTickets.ori_tras_tradename}
                                  inputProps={{ style: {width: i18n.language==='es'?865:844}, maxLength: 100 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_tradename!=null?t(errorsForm.ori_tras_tradename):t('Text_TICKETS_NAME_STORE')}
                                  placeholder={t('Text_TICKETS_NAME_STORE')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets} 
                                />  
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_ADDRESS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_street" 
                                  name="ori_tras_street"
                                  value={formTickets.ori_tras_street}
                                  inputProps={{ style: {width: i18n.language==='es'?556:517}, maxLength: 100 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_street!=null?t(errorsForm.ori_tras_street):t('Placeholder_TICKETS_TRADENAME_STORE_ADDRESS')}
                                  placeholder={t('Placeholder_TICKETS_TRADENAME_STORE_ADDRESS')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets} 
                                />  
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreAddressOriTras}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreAddress}>
                                    <SearchIcon/>
                                  </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_PROVINCE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_province_id"
                                  name="ori_tras_province_id"
                                  value={ (formTickets.ori_tras_province_id === undefined || formTickets.ori_tras_province_id ===null || formTickets.ori_tras_province_id ===""
                                      || formTickets.ori_tras_province_id ==="0" || formTickets.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE):formTickets.ori_tras_province_id}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                  variant="outlined"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                  helperText={errorsForm.ori_tras_province_id!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                                  select
                                  SelectProps={{
                                      native: true,
                                      value: (formTickets.ori_tras_province_id === undefined || formTickets.ori_tras_province_id ===null || formTickets.ori_tras_province_id ===""
                                          || formTickets.ori_tras_province_id ==="0" || formTickets.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE): formTickets.ori_tras_province_id
                                  }}>
                                  <option key={0} value=""/>
                                  {provinceToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {option.name}
                                  </option>
                                  ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_POSTALCODE')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="ori_tras_postalcode_id"
                                name="ori_tras_postalcode_id"
                                value={ (formTickets.ori_tras_postalcode_id === undefined || formTickets.ori_tras_postalcode_id ===null || formTickets.ori_tras_postalcode_id ===""
                                    || formTickets.ori_tras_postalcode_id ==="0" || formTickets.ori_tras_postalcode_id ===0)?"":formTickets.ori_tras_postalcode_id}
                                inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                variant="outlined"
                                onBlur={handleBlurTickets}
                                onChange={handleChangeTickets}
                                helperText={errorsForm.ori_tras_postalcode_id!=null && errorsForm.ori_tras_postalcode_id!==""?t(errorsForm.ori_tras_postalcode_id):""}
                                select
                                SelectProps={{
                                native: true,
                                value: (formTickets.ori_tras_postalcode_id === undefined || formTickets.ori_tras_postalcode_id ===null || formTickets.ori_tras_postalcode_id ===""
                                    || formTickets.ori_tras_postalcode_id ==="0" || formTickets.ori_tras_postalcode_id ===0)?"": formTickets.ori_tras_postalcode_id
                                }}>
                                <option key={0} value=""/>
                                {postalCodeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.postalcodename}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>

                          <div className="row p-2"> 
                            <div className="form-group">   
                              <label className="text-primary">{t('Text_email')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_email" 
                                  name="ori_tras_email"
                                  value={formTickets.ori_tras_email}
                                  inputProps={{ style: {width: i18n.language==='es'?250:250}, maxLength: 100 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_email!=null?t(errorsForm.ori_tras_email):t('Placeholder_TICKETS_EMAIL_STORE')}
                                  placeholder={t('Placeholder_TICKETS_EMAIL_STORE')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                />   
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_OTHERS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="observations" 
                                  name="observations"
                                  value={formTickets.observations}
                                  inputProps={{ style: {width: i18n.language==='es'?969:983}, maxLength: 200 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.observations!=null?t(errorsForm.observations):t('Placeholder_TICKETS_TRADENAME_STORE_OTHERS')}
                                  placeholder={t('Placeholder_TICKETS_TRADENAME_STORE_OTHERS')}
                                  variant="standard"
                                  onBlur={handleBlurTickets}
                                  onChange={handleChangeTickets}
                                />   
                            </div>                              
                          </div>  
                          <div className="row p-2"> 
                            <div className="form-group">   
                                <TableSortingTicketsArticleList
                                  dataArticles={formTickets.linestickets} 
                                  numTotRegArticles={formTickets.linestickets.length}
                                  setOpenDeleteRowArticles={setOpenDeleteRowArticles}
                                  setEditRowArticles={setEditRowArticles}
                                  formTickets={formTickets}                                  
                                  setFormTickets={setFormTickets}
                                  setShowModalSearchArticles={setShowModalSearchArticles}
                                  LIMITPAGESIZE={LIMITPAGESIZE}
                                  setShowModalSearchDI={setShowModalSearchDI}
                                /> 
                                <hr className="sidebar-divider d-none d-md-block"/>
                            </div>                              
                          </div>
                          <div className="row p-2"> 
                            <div className="form-group">   
                                <TableSortingTicketsArticleNoWeightList
                                  dataArticles={formTickets.linesticketsWithOutWeight} 
                                  numTotRegArticles={formTickets.linesticketsWithOutWeight.length}
                                  setOpenDeleteRowArticlesNoWeight={setOpenDeleteRowArticlesNoWeight}
                                  setEditRowArticlesNoWeight={setEditRowArticlesNoWeight}
                                  formTickets={formTickets}                                  
                                  setFormTickets={setFormTickets}
                                  LIMITPAGESIZE={LIMITPAGESIZE} 
                                /> 
                                <hr className="sidebar-divider d-none d-md-block"/>
                            </div>                              
                          </div>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 2, md: 12 }}>
                              {articlesButtonsList.map((post, index) => (
                                <Grid item xs={2} sm={2} md={2} key={index}>
                                  <Item>
                                    <Button variant="contained" style={{color:"#ffffff", background:post.button_color,fontSize: 14}} onClick={()=>selectArticle(post)}>
                                      {i18n.language==='es'?post.button_namees:post.button_nameen}
                                    </Button>
                                  </Item>
                                </Grid>
                              ))}
                            </Grid>
                          </Box> 
                          {/* END SELECT */}
                          </div>   
                        </div>
                      </div>
                    </div>
                  </div>
                </>:
                  <Loading isLoadingData={isLoadingData}/>
                }
              </form>
            </Box>
            <br/>
            <TicketsSearchStoresTickets
              showModalSearchStores={showModalSearchStores}
              setShowModalSearchStores={setShowModalSearchStores}
              selectStores={tetxtSearch}
              setFormTickets={setFormTickets}
            />
            <TicketsSearchStoresAddressesEdit
              showModalSearchStoresAddress={showModalSearchStoresAddress}
              setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
              setFormTickets={setFormTickets}
              titleTable={formTickets.ori_tras_tradename}
              store_id={formTickets.ori_tras_store_id}
              priority={2}
            />
            <TicketsSearchVehiclesTickets
              showModalSearchVehicles={showModalSearchVehicles}
              setShowModalSearchVehicles={setShowModalSearchVehicles}
              setFormTickets={setFormTickets}
              formTickets={formTickets}
            /> 
            <TicketsSearchArticlesEdit
              showModalSearchArticles={showModalSearchArticles}
              setShowModalSearchArticles={setShowModalSearchArticles}
              setEditRowArticles={setEditRowArticles}
              formTickets={formTickets}
              setFormTickets={setFormTickets}
              LIMITSARTICLESPERWEIGHT={LIMITSARTICLESPERWEIGHT}
            /> 
            <TicketsSearchDIEdit
              showModalSearchDI={showModalSearchDI}
              setShowModalSearchDI={setShowModalSearchDI}
              editRowArticles={editRowArticles} 
              setFormTickets={setFormTickets}
              formTickets={formTickets} 
            />  
            <Dialog open={openDeleteRowArticles} onClose={handleCancelDeleteRow}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {editRowArticles.indexrowarticle}  - {editRowArticles.article_code} - {i18n.language==='es'?editRowArticles.article_namees:editRowArticles.article_nameen} </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('Text_Line1')}<br/>
                    {t('Text_Line2')}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="idCode"
                    label={t('Text_Label')}
                    type="text"
                    fullWidth
                    variant="standard"
                    error
                    defaultValue={idCodeDeleteTickets}
                    onChange={handleChangeDeleteRow}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openDeleteRowArticlesNoWeight} onClose={handleCancelDeleteRowNoWeight}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {editRowArticlesNoWeight.indexrowarticle}  - {editRowArticlesNoWeight.article_code} - {i18n.language==='es'?editRowArticlesNoWeight.article_namees:editRowArticlesNoWeight.article_nameen} </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('Text_Line1')}<br/>
                    {t('Text_Line2')}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="idCode"
                    label={t('Text_Label')}
                    type="text"
                    fullWidth
                    variant="standard"
                    error
                    defaultValue={idCodeDeleteTicketsNoWeight}
                    onChange={handleChangeDeleteRowNoWeight}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowNoWeight}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowNoWeight}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>

          </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

TicketsEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalTicketsSettings: PropTypes.bool.isRequired 
};

export default TicketsEditOrCreate;