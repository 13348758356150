import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { warningErrorAction } from "actions/ErrorActions";
import { TextField } from '@mui/material'; 
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';  

const initialDangerousness = [{
  "id":0,  
  "dangerousness_namees": "",
  "dangerousness_nameen": ""
}];
  


const RegisterBookSelectDangeroussnessTypes = ({showModalSearchDangerousness,setShowModalSearchDangerousness,setFormRegisterBook}) => {
  const {t,i18n} = useTranslation(['listDataRegisterBook']);
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const [newDangerousness,setNewDangerousness]= useState(initialDangerousness[0]);
  const [dangerousnessToSelect,setDangerousnessToSelect]= useState(initialDangerousness);
  const [errorsDangerousness, setErrorsDangerousness] = useState({}); 
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalSearchDangerousness) {
        setNewDangerousness(initialDangerousness[0]);
        setErrorsDangerousness({});
        setShowModalSearchDangerousness(false); 
        setDangerousnessToSelect(initialDangerousness);
      }
    },[
      setNewDangerousness,
      setErrorsDangerousness, 
      setDangerousnessToSelect,
      showModalSearchDangerousness,
      setShowModalSearchDangerousness]
  );

  const handleCloseNewDangerousness = () => {
    setNewDangerousness(initialDangerousness[0]);
    setErrorsDangerousness({}); 
    setDangerousnessToSelect(initialDangerousness);
    setShowModalSearchDangerousness(false); 
  };

  const handleChangeNewDangerousness = (e) => {
    const { name, value } = e.target;
    setNewDangerousness(newDangerousness => ({
      ...newDangerousness,
      [name]: value,
    }));   
  };
  
  const handleBlurNewDangerousness = (e) => {
    handleChangeNewDangerousness(e);
    
  }; 
  const toggleDrawerNewDangerousness  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewDangerousness();
    }
  }; 

  const handleChangeSelectDangerousness = (event) => { 
   
    if (event.target.value!==""){
      let idx=dangerousnessToSelect.findIndex(((item) => item.id === Number(event.target.value)));
      setNewDangerousness(newDangerousness => ({
        ...newDangerousness,
        id:dangerousnessToSelect[idx].id,
        dangerousness_namees:dangerousnessToSelect[idx].dangerousness_namees,
        dangerousness_nameen:dangerousnessToSelect[idx].dangerousness_nameen
    }));
    } 
  };
 
  const handleBlurSelectDangerousness = (e) => {
    handleChangeSelectDangerousness(e);
  }; 

  const handleSubmitNewDangerousness = () => {  
    setFormRegisterBook(formRegisterBook => ({
      ...formRegisterBook,
      codedangerousness:newDangerousness.id,
      namedangerousness:newDangerousness.dangerousness_namees
    }))   
    handleCloseNewDangerousness();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    let isMounted = true;

    const fetchDataDangerousness = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `register/dangeroussnesstypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDangerousnessToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (showModalSearchDangerousness){
      fetchDataDangerousness(); 
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,showModalSearchDangerousness]);


  return(
    <>
      {showModalSearchDangerousness && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewDangerousness(false)}
            onOpen={toggleDrawerNewDangerousness(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_DANGEROUSNEESSTYPE_SELECT')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewDangerousness}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('PlaceHolder_DANGEROUSNEESSTYPE')} :</label>
                    <div className="form-group">
                      <TextField
                          id="id"
                          name="id"
                          value={ (newDangerousness.id === undefined || newDangerousness.id ===null || newDangerousness.id ==="")?0:newDangerousness.id}
                          inputProps={{ style: {width: 750}, maxLength: 100 }}
                          variant="outlined"
                          onBlur={handleBlurSelectDangerousness}
                          onChange={handleChangeSelectDangerousness}
                          select
                          SelectProps={{
                            native: true,
                            value: (newDangerousness.id === undefined || newDangerousness.id ===null || newDangerousness.id ==="")?0: newDangerousness.id
                          }}>
                          <option key={0} value=""/>
                          {dangerousnessToSelect.map((option) => (
                          <option key={option.id} value={option.id}>
                              {i18n.language==="es"?option.dangerousness_namees.substring(0,120):option.dangerousness_nameen.substring(0,120)}
                          </option>
                          ))}
                        </TextField>
                    </div>
                   
                    <label className="text-primary">{t('Text_DANGEROUSNEESSTYPE_TITLEES')} :</label>
                    <div className="form-group">
                      <TextField
                        id="dangerousness_namees" 
                        name="dangerousness_namees"
                        value={newDangerousness.dangerousness_namees}
                        inputProps={{ style: {width: 800}, maxLength: 255 }}
                        label={errorsDangerousness.dangerousness_namees!=null?t(errorsDangerousness.dangerousness_namees):t('Text_DANGEROUSNEESSTYPE_TITLEES')}
                        placeholder={t('Text_DANGEROUSNEESSTYPE_TITLEES')}
                        variant="standard"
                        onBlur={handleBlurNewDangerousness}
                        onChange={handleChangeNewDangerousness}
                        disabled/>
                    </div>

                    <label className="text-primary">{t('Text_DANGEROUSNEESSTYPE_TITLEEN')} :</label>
                    <div className="form-group">
                      <TextField
                        id="dangerousness_nameen" 
                        name="dangerousness_nameen"
                        value={newDangerousness.dangerousness_nameen}
                        inputProps={{ style: {width: 1000}, maxLength: 255 }}
                        label={errorsDangerousness.dangerousness_nameen!=null?t(errorsDangerousness.dangerousness_nameen):t('Text_DANGEROUSNEESSTYPE_TITLEEN')}
                        placeholder={t('Text_DANGEROUSNEESSTYPE_TITLEEN')}
                        variant="standard"
                        onBlur={handleBlurNewDangerousness}
                        onChange={handleChangeNewDangerousness}
                        disabled/>
                    </div> 
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewDangerousness}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewDangerousness}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

RegisterBookSelectDangeroussnessTypes.propTypes = {
  showModalSearchDangerousness: PropTypes.bool.isRequired,
  setShowModalSearchDangerousness: PropTypes.func.isRequired,   
  setFormRegisterBook: PropTypes.func.isRequired
};

export default RegisterBookSelectDangeroussnessTypes;