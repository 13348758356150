import * as XLSX from "xlsx";

const exportToExcel = (data, fileName) => {
  
  const formatDate = (dateValue) => {
    if (!dateValue) return "";
    const date = new Date(dateValue);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  

  // Filtra los datos para 'ENTRADAS' 
  
  // Filtra los datos para 'ENTRADAS'
const entradasData = data.flatMap(data => {
  // Datos generales del albarán
  const albaranDetails = [
    data.purchasenote || "",
    data.ownnumberpurchase || "",
    formatDate(data.datepurchase) || "",
    data.vehiclestore_licenseplate || "",
    data.rel_trans_documentid || "",
    data.rel_trans_tradename || "",
    data.vehiclestore_defaultdriver || "",
    data.rel_trans_nima || "",
    data.rel_trans_grnp || "",
    data.ori_tras_documentid || "",
    data.ori_tras_tradename || "",
    data.ori_tras_workplace || "",
    data.ori_tras_street || "",
    data.ori_tras_province_name || "",
    data.ori_tras_postalcode_name || "",
    data.ori_tras_email || "",
    data.totalpurchasedelivery || "",
    data.totaldiscountpurchasedelivery || "",
    data.totaltaxespurchasedelivery || "",
    data.totalNetoPurchasedelivery || "",
    data.payment_namees || ""
  ]; 
  // Generar filas por cada artículo en LinesPurchaseDeliveryListDTO
  const lineEntries = (data.linespurchasedelivery || []).map(line => [
    ...albaranDetails,
    line.article_code || "", // Código del artículo
    line.article_namees || "", // Descripción del artículo
    line.amount || "", // Cantidad
    line.estimatedprice || "", // PVP
    line.discount || "", // Descuento
    line.tax || "", // Impuesto
    line.taxincluded ? "SÍ" : "NO", // Incluido
    line.total || "" // Total
  ]); 
  return lineEntries;
});


    

   
  const entradasWorksheet = XLSX.utils.aoa_to_sheet([
    [
      "Nº ALBARÁN",
      "REFERENCIA ALBARÁN",
      "FECHA",

      "MATRÍCULA",
      "Nº DOCUMENTO",
      "TRANSPORTISTA",
      "CONDUCTOR",
      "NIMA",
      "GRNP",

      "Nº DOCUMENTO",
      "EMPRESA",
      "OBRA",
      "DIRECCIÓN",
      "PROVINCIA",
      "CÓDIGO POSTAL",
      "EMAIL",
      
      "IMPORTE ALBRARÁN",
      "DESCUENTOS",
      "IMPUESTOS",
      "TOTAL ALBARÁN",
      "FORMA DE PAGO",

      "CÓDIGO ARTÍCULO",
      "DESCRPCIÓN ARTÍCULO",
      "CANTIDAD",
      "PRECIO ESTIMADO",
      "DESCUENTOS",
      "IMPUESTO",
      "INCLUÍDO",
      "TOTAL"

    ],
    ...entradasData
  ]);


  // Crea un nuevo libro de trabajo
  const workbook = XLSX.utils.book_new();

  // Verifica si el nombre del archivo ya tiene la extensión
  const safeFileName = fileName.endsWith(".xlsx") ? fileName : `${fileName}.xlsx`;

  // Agrega las hojas de cálculo al libro
  XLSX.utils.book_append_sheet(workbook, entradasWorksheet, "RESUMEN");

  // Genera el archivo en formato binario
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Crea un blob con el contenido del archivo
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  // Crea un enlace temporal para la descarga
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = safeFileName;

  // Dispara el evento de clic en el enlace para descargar el archivo
  a.click();

  // Libera el objeto URL
  window.URL.revokeObjectURL(url);
};

export default exportToExcel;