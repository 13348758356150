import React, {useState, useEffect} from 'react' 
import {useTranslation} from "react-i18next"
import { useTheme } from '@mui/material/styles'; 
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search'; 
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { NumericFormat } from 'react-number-format';  
import { Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography } from '@mui/material';
import PropTypes from 'prop-types';

const headCellsArticle = [
  {
    id: 'indexrowarticle',
    numeric: false,
    disablePadding: false,
    label: 'Text_Row',
    canOrder:false,
  },{
    id: 'article_code',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_CODE',
    canOrder:false,
  },
  {
    id: 'article_name',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_ARTICLE_TITLE',
    canOrder:false,
  },
  {
    id: 'firstweight',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_PREV_WEIGHT',
    canOrder:false,
  },
  {
    id: 'secondweight',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_NEXT_WEIGHT',
    canOrder:false,
  }, 
  {
    id: 'formdi_id',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_ARTICLE_DI',
    canOrder:false,
  }
]; 

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsArticle.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 14}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { numTotRegArticles , t, setShowModalSearchArticles } = props;
  
    const handleSearchNewAddress  = () => {
      setShowModalSearchArticles(true);  
    };

    return (
      <Toolbar>
      <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
        {t('Text_TICKETS_SELECT_ARTICLES')} &nbsp;&nbsp;
        <IconButton size="small" style={{color:"#e74a3b", fontSize: 14}} onClick={handleSearchNewAddress }>
            <SearchIcon sx={{ color:"#067330"}}/> 
        </IconButton>
      </Typography>
      <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
        {t('Text_Num_Reg')} {(numTotRegArticles ).toLocaleString('de-DE')} {t('Text_Of')}  {(numTotRegArticles ).toLocaleString('de-DE')}
      </Typography> 
    </Toolbar>
   
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataTickets']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingTicketsArticleList  =(props)=>{
  const { dataArticles ,numTotRegArticles , setOpenDeleteRowArticles ,setEditRowArticles ,formTickets,setFormTickets,setShowModalSearchArticles, LIMITPAGESIZE,setShowModalSearchDI} = props;
  const {t,i18n} = useTranslation(['listDataTickets']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticles.length) : 0);
  const [countData,setCountData] = useState(dataArticles.length);
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);

  useEffect(() => {
    if (dataArticles.length !== countData || dataArticles.length <= rowsPerPage) {
      setPage(0);
    }  
    setCountData(dataArticles.length);
    setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticles.length) : 0);
  }, [dataArticles , rowsPerPage, page, countData,numTotRegArticles]);
   
  useEffect(() => {
    if (numTotRegArticles  > LIMITPAGESIZE) {
      setActiveOffset(true);
      const ranges = [
        {
          value: 1,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        },
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegArticles ; i += Number(LIMITPAGESIZE)) {
        ranges.push({
          value: (i / Number(LIMITPAGESIZE)) + 1,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(ranges);
    } else {
      setCurrencyOffset(1);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegArticles , LIMITPAGESIZE]);

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRowAArticle = (row) => {
    setEditRowArticles (row);
    setOpenDeleteRowArticles (true);
  }; 

  const openModalSearchFormDI = (row) => {
    setEditRowArticles (row);
    setShowModalSearchDI (true);
  }; 
  
  const openModalDeleteFromDI = (row) => {
    let idx=formTickets.linestickets.findIndex(((item) => item.indexrowarticle === row.indexrowarticle));
         
    let articleToAdd = {
      "id": row.id,
      "indexrowarticle":row.indexrowarticle, 
      "tickets_id":row.tickets_id, 
      "article_id":row.article_id, 
      "article_code":row.article_code, 
      "article_namees":row.article_namees, 
      "article_nameen":row.article_nameen, 
      "start_date_ticket":row.start_date_ticket, 
      "end_date_ticket":row.end_date_ticket,
      "firstweight": row.firstweight,
      "secondweight":row.secondweight,
      "estimatedprice":row.estimatedprice,
      "discount":row.discount,
      "tax":row.tax,
      "taxincluded":row.taxincluded,
      "ticketweight":row.ticketweight,
      "formdi_id":0,
      "ownformdi_id":0, 
    }; 
    formTickets.linestickets[idx]=articleToAdd
    setFormTickets(formTickets => ({
      ...formTickets,
    })); 
  };
    
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value < 100000) return true;
    return false;
  };
 

  const handleBlurArticlesFirstWeight = (value, row) => {
    handleChangeArticleFirstWeight(value, row);
  };

  const handleChangeArticleFirstWeight = (value,row) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll("Tn","").replaceAll("Kg","").replaceAll("€","").replaceAll("%","")):0;
    let idx=formTickets.linestickets.findIndex(((item) => item.indexrowarticle === row.indexrowarticle));
         
    let articleToAdd = {
      "id": row.id,
      "indexrowarticle":row.indexrowarticle, 
      "tickets_id":row.tickets_id, 
      "article_id":row.article_id, 
      "article_code":row.article_code, 
      "article_namees":row.article_namees, 
      "article_nameen":row.article_nameen, 
      "start_date_ticket":row.start_date_ticket, 
      "end_date_ticket":row.end_date_ticket,
      "firstweight": newValue,
      "secondweight":row.secondweight,
      "estimatedprice":row.estimatedprice,
      "discount":row.discount,
      "tax":row.tax,
      "taxincluded":row.taxincluded,
      "ticketweight":row.ticketweight,
      "formdi_id":row.formdi_id,
      "ownformdi_id":row.ownformdi_id
     
    }; 
    formTickets.linestickets[idx]=articleToAdd
    setFormTickets(formTickets => ({
      ...formTickets,
    }));
  };

  const handleBlurArticlesSecondWeight = (value, row) => {
    handleChangeArticleSecondWeight(value, row);
  };

  const handleChangeArticleSecondWeight = (value,row) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll("Tn","").replaceAll("Kg","").replaceAll("€","").replaceAll("%","")):0;
    let idx=formTickets.linestickets.findIndex(((item) => item.indexrowarticle === row.indexrowarticle));
         
    let articleToAdd = {
      "id": row.id,
      "indexrowarticle":row.indexrowarticle, 
      "tickets_id":row.tickets_id, 
      "article_id":row.article_id, 
      "article_code":row.article_code, 
      "article_namees":row.article_namees, 
      "article_nameen":row.article_nameen, 
      "start_date_ticket":row.start_date_ticket, 
      "end_date_ticket":row.end_date_ticket,
      "firstweight": row.firstweight,
      "secondweight":newValue,
      "estimatedprice":row.estimatedprice,
      "discount":row.discount,
      "tax":row.tax,
      "taxincluded":row.taxincluded,
      "ticketweight":row.ticketweight,
      "formdi_id":row.formdi_id,
      "ownformdi_id":row.ownformdi_id
     
    }; 
    formTickets.linestickets[idx]=articleToAdd
    setFormTickets(formTickets => ({
      ...formTickets,
    })); 
  };

   
  const listItems= (
  stableSort(dataArticles , getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keydataArticles ) => {
    return (
      <TableRow hover tabIndex={-1} key={keydataArticles }>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.indexrowarticle}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df",width: 100, fontSize: 14}} >
            {row.article_code}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df",width: i18n.language==='es'?320:349, fontSize: 14}} >
            {i18n.language==="es"?row.article_namees:row.article_nameen}
          </Typography> 
        </TableCell> 
        <TableCell align="left">
          <NumericFormat 
            thousandSeparator={','} 
            decimalSeparator={'.'} 
            decimalScale= {2}
            fixedDecimalScale= {true}
            allowNegative= {false}
            isAllowed={withValueCap}
            id="firstweight" 
            name="firstweight"
            suffix={" Kg"}
            value={row.firstweight}
            style={{width:'110%', color:"red", background:"black",fontSize: 18, textAlign:'right', paddingRight:5}} 
            onBlur={(e) => handleBlurArticlesFirstWeight(e.target.value,row)}
            onChange={(e) => handleChangeArticleFirstWeight(e.target.value,row)}
          /> 
        </TableCell> 
        <TableCell align="left">
          <NumericFormat 
            thousandSeparator={','} 
            decimalSeparator={'.'} 
            decimalScale= {2}
            fixedDecimalScale= {true}
            allowNegative= {false}
            isAllowed={withValueCap}
            id="secondweight" 
            name="secondweight"
            suffix={" Kg"}
            value={row.secondweight}
            style={{width:'110%', color:"red", background:"black",fontSize: 18, textAlign:'right',  paddingRight:5}} 
            onBlur={(e) => handleBlurArticlesSecondWeight(e.target.value,row)}
            onChange={(e) => handleChangeArticleSecondWeight(e.target.value,row)}
          /> 
        </TableCell> 
        <TableCell align="center">
          <Typography style={{color:"#4e73df",width: 100, fontSize: 18}} > 
          {(row.formdi_id!==undefined && row.formdi_id!=="" && row.formdi_id!=="0" && row.formdi_id!==0) ?
            <>
              {row.ownformdi_id}
              <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 18}} onClick={()=>openModalDeleteFromDI(row)}>
                <DeleteOutlineIcon/>
              </IconButton>
            </>:
            <> 
              <IconButton  size="large"  
              style={{color:(
                formTickets.rel_trans_store_id===undefined || formTickets.rel_trans_store_id===null || formTickets.rel_trans_store_id==="" || formTickets.rel_trans_store_id==="0" || formTickets.rel_trans_store_id===0 ||
                formTickets.vehiclestore_licenseplate===undefined || formTickets.vehiclestore_licenseplate===null || formTickets.vehiclestore_licenseplate==="")?"#858796":"#067330", fontSize: 12}}  
              onClick={
                (
                  formTickets.rel_trans_store_id===undefined || formTickets.rel_trans_store_id===null || formTickets.rel_trans_store_id==="" || formTickets.rel_trans_store_id==="0" || formTickets.rel_trans_store_id===0||
                  formTickets.vehiclestore_licenseplate===undefined || formTickets.vehiclestore_licenseplate===null || formTickets.vehiclestore_licenseplate==="")
                ?
                ()=>{}:
                ()=>openModalSearchFormDI(row)}>
                <SearchIcon/>
              </IconButton>
            </>
          }
          </Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalDeleteRowAArticle(row)}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  })); 
  
  return(
    <>
      <Box sx={{ width: '100%' }}>    
        <EnhancedTableToolbar  numTotRegArticles ={numTotRegArticles } t={t}  setShowModalSearchArticles={setShowModalSearchArticles} />
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={12} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={12}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    colSpan={11}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}
TableSortingTicketsArticleList.propTypes = { 
  dataArticles : PropTypes.array.isRequired,
  numTotRegArticles : PropTypes.number.isRequired,
  setOpenDeleteRowArticles : PropTypes.func.isRequired,
  setEditRowArticles : PropTypes.func.isRequired, 
  formTickets: PropTypes.object.isRequired,
  setFormTickets: PropTypes.func.isRequired,
  setShowModalSearchArticles: PropTypes.func.isRequired, 
  LIMITPAGESIZE: PropTypes.string.isRequired,
  setShowModalSearchDI : PropTypes.func.isRequired
};

export default TableSortingTicketsArticleList ;