import * as XLSX from "xlsx";

const exportToExcel = (data, fileName) => {
  
  const formatDate = (dateValue) => {
    if (!dateValue) return "";
    const date = new Date(dateValue);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  // Filtra los datos para 'ENTRADAS' y 'SALIDAS'
   const entradasData = data.filter(item => item.isinput === true).map(data => [
    (data.preiddoc_sheet) + (data.sufiddoc_sheet) || "",
    formatDate(data.date_sheet) || "",
    data.ori_tras_tradename  || "",
    data.ori_tras_nima || "",
    data.ori_tras_postalcode || "",
    data.opt_tras_tradename || "",
    data.opt_tras_nima || "",
    data.amount || "",
    data.codeler || "",
    data.namecodeler || "",
    data.rel_trans_tradename || "",
    data.rel_trans_nima || "",
    data.column1 || "",
    data.column1 || ""
  ]);

  
  const salidasData = data.filter(item => item.isoutput === true).map((data, index) => [
    index + 1, // lastRowValue
    formatDate(data.date_sheet) || "",
    data.namecodeler || "",
    data.codeler || "",
    data.dest_tras_tradename || "",
    data.dest_tras_nima || "",
    data.dest_tras_inscription || "",
    data.dest_tras_postalcode || "",
    data.nametreatment || data.namedangerousness || "",
    data.rel_trans_tradename || "",
    data.rel_trans_nima || "",
    data.amount || 0,
    data.column1|| "",
    data.column2|| "",
  ]);
   
  const entradasWorksheet = XLSX.utils.aoa_to_sheet([
    [
      "Nº D.I.",
      "FECHA",
      "RAZÓN SOCIAL ORIGEN DEL TRASLASO",
      "NIMA",
      "MUNICIPIO",
      "RAZÓN SOCIAL OPERADOR DEL TRASLADO",
      "NIMA",
      "CANTIDAD",
      "CÓDIGO LER",
      "DESCRIPCIÓN LER",
      "RAZÓN SOCIAL TRANSPORTISTA",
      "NIMA",
      "INFORMATION ADICIONAL",
      "INFORMATION ADICIONAL"
    ],
    ...entradasData
  ]);

  const salidasWorksheet = XLSX.utils.aoa_to_sheet([
    [
      "NÚMERO DE REGISTRO",
      "FECHA",
      "DESCRIPCIÓN LER",
      "CÓDIGO LER",
      "RAZÓN SOCIAL DESTINO DEL TRASLADO",
      "NIMA",
      "NÚMERO DE REGISTRO DE PyGR",
      "MUNICIPIO",
      "OPT. TRAT. DESTINO",
      "RAZÓN SOCIAL TRANSPORTISTA",
      "NIMA",
      "CANTIDAD",
      "INFORMATION ADICIONAL",
      "INFORMATION ADICIONAL"
    ],
    ...salidasData
  ]);

  // Crea un nuevo libro de trabajo
  const workbook = XLSX.utils.book_new();

  // Verifica si el nombre del archivo ya tiene la extensión
  const safeFileName = fileName.endsWith(".xlsx") ? fileName : `${fileName}.xlsx`;

  // Agrega las hojas de cálculo al libro
  XLSX.utils.book_append_sheet(workbook, entradasWorksheet, "ENTRADAS");
  XLSX.utils.book_append_sheet(workbook, salidasWorksheet, "SALIDAS");

  // Genera el archivo en formato binario
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Crea un blob con el contenido del archivo
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  // Crea un enlace temporal para la descarga
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = safeFileName;

  // Dispara el evento de clic en el enlace para descargar el archivo
  a.click();

  // Libera el objeto URL
  window.URL.revokeObjectURL(url);
};

export default exportToExcel;