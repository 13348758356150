import React, {  Fragment, useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Textarea from '@mui/joy/Textarea';
import { Box } from '@mui/material'; 
import { TextField } from '@mui/material';
import { Typography} from '@mui/material';
import { Button } from '@mui/material';
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import TableSortingAddress from "pages/stores/storeEdit/SortingTablesAddress";
import StoreCreateOrEditAddress from "pages/stores/storeEdit/StoreNewAddress";
import TableSortingBankAccount from "pages/stores/storeEdit/SortingTablesBankAcount";
import StoreCreateOrEditBankAccount from "pages/stores/storeEdit/StoreNewBankAccount";
import TableSortingUserAccounts from "pages/stores/storeEdit/SortingTablesUserAccount";
import StoreCreateOrEditVehicle from "pages/stores/storeEdit/StoreNewVehicle";
import TableSortingVehicle from "pages/stores/storeEdit/SortingTablesVehicles";
import PropTypes from 'prop-types';

const initialStateStore = {
  "id":"",
  "documentid":"",
  "tradename":"",
  "name":"",
  "contacperson":"",
  "cnae":"", 
  "accountingentry":"",
  "economicactivity":"",
  "createdate":"",
  "comments":"",
  "enabled":true,
  "color_id":0, 
  "color_codehex":"",
  "documnettype_id":0, 
  "documnettype_name":"",
  "extphone_id":"1",
  "phones" : [],
  "emails" : [],
  "addresses" : [],
  "bankaccounts" : [],
  "vehicles":[],
  "useraccounts":[] 
};

const initialDocumentTypesToSelect = [{
  "id": "",
  "name": ""
}];

const initialExtInternationalToSelect = [{
  "id": "",
  "extphone": ""
}];

const initialColorsToSelect = [{
  "id":0,
  "namees":"",
  "nameen":"",
  "codehex":""
}];

const initialAddresses = [{
  "id":"",
  "indexrowaddress":0,
  "street":"",
  "defaultaddress": true,
  "fiscaladdress": true,
  "defaulttypeaddress":0,
  "defaulttypeaddress_namees":"",
  "defaulttypeaddress_nameen":"",
  "otherdata":"",
  "roadtype_id":0,
  "roadtype_namees":"",
  "roadtype_nameen":"",
  "country_id":1,
  "country_namees":"",
  "country_nameen":"",
  "province_id":30,
  "province_name":"",
  "postalcode_id":"",
  "postalcode_postalcodename":"",
  "community_id":6,
  "community_namees":"",
  "community_nameen":"",

  "nima_opt_tras":"",
  "pygr_opt_tras":"",
  "grnp_opt_tras":"",
  "opt_id_opt_tras":0,
  "opt_code_opt_tras":"",
  "opt_namees_opt_tras":"",
  "opt_nameen_opt_tras":"",
    
  "nima_ori_tras":"",
  "pygr_ori_tras":"",
  "grnp_ori_tras":"",
  "opt_id_ori_tras":0,
  "opt_code_ori_tras":"",
  "opt_namees_ori_tras":"",
  "opt_nameen_ori_tras":"",
  
  "nima_aut_tras":"",
  "pygr_aut_tras":"",
  "grnp_aut_tras":"",
  "opt_id_aut_tras":0,
  "opt_code_aut_tras":"",
  "opt_namees_aut_tras":"",
  "opt_nameen_aut_tras":"",
  
  "nima_dest_tras":"",
  "pygr_dest_tras":"",
  "grnp_dest_tras":"",
  "opt_id_dest_tras":0,
  "opt_code_dest_tras":"",
  "opt_namees_dest_tras":"",
  "opt_nameen_dest_tras":"",
  
  "nima_aut_dest":"",
  "pygr_aut_dest":"",
  "grnp_aut_dest":"",
  "opt_id_aut_dest":0,
  "opt_code_aut_dest":"",
  "opt_namees_aut_dest":"",
  "opt_nameen_aut_dest":"",
    
  "nima_rel_trans":"",
  "pygr_rel_trans":"",
  "grnp_rel_trans":"",
  "opt_id_rel_trans":0,
  "opt_code_rel_trans":"",
  "opt_namees_rel_trans":"",
  "opt_nameen_rel_trans":"",
  
  "nima_resp_prod":"",
  "pygr_resp_prod":"",
  "grnp_resp_prod":"",
  "opt_id_resp_prod":0,
  "opt_code_resp_prod":"",
  "opt_namees_resp_prod":"",
  "opt_nameen_resp_prod":""
  
}];

const initialBankAccounts = [{
  "id":"",
  "indexrowbankaccount":0,
  "codbic":"",
  "namebank":"",
  "iban":"",
  "entity":"",
  "office":"",
  "cd":"",
  "accountbank":"",
  "defaultaccountbank": false
}];

const initialVehicles = [{
  "id":"",
  "indexrowvehicle":0,
  "licenseplate":"",
  "defaultvehicle":false,
  "tara":0,
  "pma":0,
  "checkpma":true,
  "defaultdriver":"",
  "enabled":true
}];
 
const initialFilterSearchUserAccount = {
  "idFrom" : "",
  "idTo" : "",
  "usernameFrom" : "",
  "usernameTo" : "",
  "firstnameFrom" : "",
  "firstnameTo" : "",
  "lastname1From" : "",
  "lastname1To" : "",
  "lastname2From" : "",
  "lastname2To" : "",
  "emailFrom" : "",
  "emailTo" : "",
  "aliasuserFrom" : "",
  "aliasuserTo" : "",
  "role_nameFrom" : "",
  "role_nameTo" : "",
  "defaulttypeFrom" : "",
  "defaulttypeTo" : "",
  "typesdi_nameesFrom" : "",
  "typesdi_nameesTo" : "",
  "typesdi_nameenFrom" : "",
  "typesdi_nameenTo" : "",
  "ticketsformat_nameesFrom" : "",
  "ticketsformat_nameesTo" : "",
  "ticketsformat_nameenFrom" : "",
  "ticketsformat_nameenTo" : "",
  "userAccountListStoreDTO":[]
};

const StoreEditOrCreate = ({id, showModalStoreSettings}) => {
  const {t,i18n} = useTranslation(['listDataStores']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
  const DEFAULTCOLOR = useSelector((state) => state.variablesUser.DEFAULTCOLOR); 
  const DEFAULTSTORETYPESDI = useSelector((state) => state.variablesUser.DEFAULTSTORETYPESDI); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DEFAULTEXTPHONE = useSelector((state) => state.formatDIUser.default_ext_phone); 
  const LIMITPHONEPERSTORE = useSelector((state) => state.formatDIUser.limitphoneperstore); 
  const LIMITEMAILPERSTORE = useSelector((state) => state.formatDIUser.limitemailperstore); 
  const LIMITADDRESSPERSTORE = useSelector((state) => state.formatDIUser.limitaddressperstore); 
  const LIMITBANKACCOUNTPERSTORE = useSelector((state) => state.formatDIUser.limitbankaccountperstore); 
  const [formStore, setFormStore] = useState(initialStateStore);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsStore, setErrorsStore] = useState({});
  const [foundNumberOfDocument, setFoundNumberOfDocument ]= useState(false);
  const [newPhone,setNewPhone]= useState("");
  const [newEmail,setNewEmail]= useState("");
  const [valueTabs, setValueTabs] = useState("first");
  const [openEditNewAddress,setOpenEditNewAddress]= useState(false);
  const [openDeleteRowAddress,setOpenDeleteRowAddress]= useState(false);
  const [openEditRowAddress,setOpenEditRowAddress]= useState(false);
  const [editRowAddress,setEditRowAddress]= useState(initialAddresses);
  const [idCodeDeleteAddress,setIdCodeDeleteAddress]=useState(DELETE_CODE);
  const [openEditNewBankAccount,setOpenEditNewBankAccount]= useState(false);
  const [openDeleteRowBankAccount,setOpenDeleteRowBankAccount]= useState(false);
  const [openEditRowBankAccount,setOpenEditRowBankAccount]= useState(false);
  const [editRowBankAccount,setEditRowBankAccount]= useState(initialBankAccounts);
  const [idCodeDeleteBankAccount,setIdCodeDeleteBankAccount]=useState(DELETE_CODE);
  const [openEditNewVehicle,setOpenEditNewVehicle]= useState(false);
  const [openDeleteRowVehicle,setOpenDeleteRowVehicle]= useState(false);
  const [openEditRowVehicle,setOpenEditRowVehicle]= useState(false);
  const [editRowVehicle,setEditRowVehicle]= useState(initialVehicles);
  const [idCodeDeleteVehicle,setIdCodeDeleteVehicle]=useState(DELETE_CODE);
  const [applyFiltersSearchUserAccount,setApplyFiltersSearchUserAccount]= useState(initialFilterSearchUserAccount);
  const [openFilterSearchUserAccount,setOpenFilterSearchUserAccount]= useState(false);
  const [inputTextUserAccount, setInputTextUserAccount] = useState("");   
  const [documentTypesToSelect,setDocumentTypesToSelect]= useState(initialDocumentTypesToSelect); 
  const [colorsToSelect,setColorsToSelect]= useState(initialColorsToSelect);
  const [extInternationalToSelect,setExtInternationalToSelect]= useState(initialExtInternationalToSelect);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalStoreSettings) {
        setFormStore(initialStateStore);
        setFormStore(formStore => ({
          ...formStore,
          phones:[],
          emails:[],
          addresses:[],
          bankaccounts:[],
          vehicles:[],
          useraccounts:[]
        }));
        setIsLoadingData(false);
        setIsLoadingErrorData(false);
        setErrorsStore({});
        setFoundNumberOfDocument(false);
        setNewPhone("");
        setNewEmail("");
        setValueTabs("first");
        setOpenEditNewAddress(false);
        setOpenDeleteRowAddress(false);
        setOpenEditRowAddress(false);
        setEditRowAddress(initialAddresses);
        setIdCodeDeleteAddress(DELETE_CODE);
        setOpenEditNewBankAccount(false);
        setOpenDeleteRowBankAccount(false);
        setOpenEditRowBankAccount(false);
        setEditRowBankAccount(initialBankAccounts);
        setIdCodeDeleteBankAccount(DELETE_CODE);
        setOpenEditNewVehicle(false);
        setOpenDeleteRowVehicle(false);
        setOpenEditRowVehicle(false);
        setEditRowVehicle(initialVehicles);
        setIdCodeDeleteVehicle(DELETE_CODE);
        setApplyFiltersSearchUserAccount(initialFilterSearchUserAccount);
        setOpenFilterSearchUserAccount(false); 
        setInputTextUserAccount("");    
        setDocumentTypesToSelect(initialDocumentTypesToSelect);
        setColorsToSelect(initialColorsToSelect);  
        setExtInternationalToSelect(initialExtInternationalToSelect);
        navigate("/store");
      }
    },
    [ setFormStore,
      setIsLoadingData,
      setIsLoadingErrorData,
      setErrorsStore,
      setFoundNumberOfDocument,
      setNewPhone,
      setNewEmail,
      setValueTabs,
      setOpenEditNewAddress,
      setOpenDeleteRowAddress,
      setOpenEditRowAddress,
      setEditRowAddress,
      setIdCodeDeleteAddress,
      setOpenEditNewBankAccount,
      setOpenDeleteRowBankAccount,
      setOpenEditRowBankAccount,
      setEditRowBankAccount,
      setIdCodeDeleteBankAccount,
      setOpenEditNewVehicle,
      setOpenDeleteRowVehicle,
      setOpenEditRowVehicle,
      setEditRowVehicle,
      setIdCodeDeleteVehicle,
      setApplyFiltersSearchUserAccount,
      setOpenFilterSearchUserAccount,
      setInputTextUserAccount,    
      setDocumentTypesToSelect,
      setColorsToSelect, 
      setExtInternationalToSelect,
      showModalStoreSettings,
      DELETE_CODE,
      navigate]
  );
  
  const handleCloseDataEditStore = () => {
    
    setFormStore(initialStateStore);
    setFormStore(formStore => ({
      ...formStore,
      phones:[],
      emails:[],
      addresses:[],
      bankaccounts:[],
      vehicles:[],
      useraccounts:[]
    }));
    setIsLoadingData(false);
    setIsLoadingErrorData(false);
    setErrorsStore({});
    setFoundNumberOfDocument(false);
    setNewPhone("");
    setNewEmail("");
    setValueTabs("first");
    setOpenEditNewAddress(false);
    setOpenDeleteRowAddress(false);
    setOpenEditRowAddress(false);
    setEditRowAddress(initialAddresses);
    setIdCodeDeleteAddress(DELETE_CODE);
    setOpenEditNewBankAccount(false);
    setOpenDeleteRowBankAccount(false);
    setOpenEditRowBankAccount(false);
    setEditRowBankAccount(initialBankAccounts);
    setIdCodeDeleteBankAccount(DELETE_CODE);
    setOpenEditNewVehicle(false);
    setOpenDeleteRowVehicle(false);
    setOpenEditRowVehicle(false);
    setEditRowVehicle(initialVehicles);
    setIdCodeDeleteVehicle(DELETE_CODE);
    setApplyFiltersSearchUserAccount(initialFilterSearchUserAccount);
    setOpenFilterSearchUserAccount(false); 
    setInputTextUserAccount("");    
    setDocumentTypesToSelect(initialDocumentTypesToSelect);
    setColorsToSelect(initialColorsToSelect);  
    setExtInternationalToSelect(initialExtInternationalToSelect);
    navigate("/store");
	};

  const handleChangeEnabledStore = (e) => {
    setFormStore(formStore => ({
        ...formStore,
        enabled: !formStore.enabled
    }));
  };

  const handleChangeDataEditStore = (e) => {
    const { name, value } = e.target;
    setFormStore(formStore => ({
      ...formStore,
      [name]: value,
    }));
    setFormStore(formStore => ({
      ...formStore
    }));  
  };

  const handleBlurDataEditStore = (e) => {
    handleChangeDataEditStore(e);
    setErrorsStore(validateFormStore());
  };
  
  const handleChangeSelectDocumentType = (event) => {
    setFormStore(formStore => ({
        ...formStore,
        documnettype_id:event.target.value
    }));
  };

  const handleBlurSelectDocumentType = (e) => {
    handleChangeSelectDocumentType(e);
  };

  const handleChangeSelectColor = (event) => {
    let colorHex="#FFFFFF";
    if (event.target.value!==""){
      colorHex=colorsToSelect.find((key=>key.id===Number(event.target.value))).codehex;
    }

    setFormStore(formStore => ({
        ...formStore,
        color_id:event.target.value,
        color_codehex:colorHex
    }));
  };

  const handleBlurSelectColor = (e) => {
    handleChangeSelectColor(e);
    setErrorsStore(validateFormStore());
  };
  
  const handleChangeExtInternational = (event) => {
    setFormStore(formStore => ({
      ...formStore,
      extphone_id:event.target.value
    }));
  };

  const handleBlurSelectExtInternational = (e) => {
    handleChangeExtInternational(e);
  };

  const handleChangeDefaultPhone = (item) => { 
    var i =  formStore.phones.indexOf( item );
    formStore.phones.map((item,index)=>{
      if ( index!==i ) {
        return item.defaultphone=false
      }else{
        return item.defaultphone=true
      }  
    })
    setFormStore(formStore => ({
      ...formStore,
    }))
    setNewPhone("");
  };

  const handleDeletePhone = (item) => {
    var i =  formStore.phones.indexOf( item );
    if ( i !== -1 ) {
      formStore.phones.splice( i, 1 );
    }
    setFormStore(formStore => ({
      ...formStore,
    }))
    if (item.defaultphone){
      if (formStore.phones.length>0){
        formStore.phones[0].defaultphone=true;
        setFormStore(formStore => ({
          ...formStore,
        }))
      }
    }
    setNewPhone("");
  };
    
  const handleAddPhone = (event) => {  
    
    setErrorsStore(validateFormStore());
    if (errorsStore.newPhone===undefined && newPhone!=="") {
      let defaultFound=false;
      if (formStore.phones.length<=0){
        defaultFound=true;
      }
      let phoneToAdd={
        "id":"",
        "phone":reformatPhone(newPhone),
        "defaultphone":defaultFound
      }
      formStore.phones.push(phoneToAdd);
      setNewPhone("");
    }
  };

  function reformatPhone(phone){
    phone=phone.replace(/[^0-9]+/g, "").replace(" ", "").toUpperCase();
    if (phone.length<9){
        for (let i = phone.length; i < 9; i++) {
            phone="0"+phone;
        }
    }
    let invertString = "";
    for (let indice = phone.length - 1; indice >= 0; indice--) {
        invertString += phone.charAt(indice);
    }
    phone = "";
    
    for (let indice = 8; indice >= 0; indice--) {
        phone += invertString.charAt(indice);
        if (indice===2 || indice===4) phone += ".";
        if (indice===6 ) phone += "-";
    }
    if (extInternationalToSelect!==undefined){
      let extPhone=extInternationalToSelect.find((key)=>(key.id===Number(formStore.extphone_id))).extphone;
      return extPhone+" "+phone;
    } else {
      return phone;
    }
  }

  const handleChangeNewPhone = (event) => {
    setNewPhone(event.target.value);
  };
  
  const handleBlurNewPhone = (e) => {
    handleChangeNewPhone(e);
    setErrorsStore(validateFormStore());
  };

  const handleChangeDefaultEmail = (item) => {
    var i =  formStore.emails.indexOf( item );
    formStore.emails.map((item,index)=>{
      if ( index!==i ) {
        return item.defaultemail=false
      }else{
        return item.defaultemail=true
      }  
    })
    setFormStore(formStore => ({
       ...formStore,
    }))
    setNewEmail("");
   };
 
  const handleDeleteEmail = (item) => {
    var i =  formStore.emails.indexOf( item );
    if ( i !== -1 ) {
      formStore.emails.splice( i, 1 );
    }
    setFormStore(formStore => ({
      ...formStore,
    }))
    if (item.defaultemail){
      if (formStore.emails.length>0){
        formStore.emails[0].defaultemail=true;
        setFormStore(formStore => ({
          ...formStore,
        }))
      }
    }
    setNewEmail("");
  };
 
  const handleAddEmail = () => {  
    if (errorsStore.newEmail===undefined  && newEmail!=="") {
      let defaultFound=false;
      if (formStore.emails.length===0){
        defaultFound=true;
      }
      const emailToAdd={
        "id":"",
        "email":newEmail,
        "defaultemail":defaultFound
      }
      formStore.emails.push(emailToAdd)
      setNewEmail("");
    }  
  };

  const handleChangeNewEmail = (event) => {
    setNewEmail(event.target.value);
  };
  
  const handleBlurNewEmail = (e) => {
    handleChangeNewEmail(e);
    setErrorsStore(validateFormStore());
  };

  const handleChangeDeleteRowAddress = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteAddress(value);
  };

  const handleCancelDeleteRowAddress = () => {
    setIdCodeDeleteAddress(DELETE_CODE);
    setOpenDeleteRowAddress(false);
  };

  const handleCloseDeleteRowAddress = (e) => {
    e.preventDefault();
    if (idCodeDeleteAddress!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      let indexDelete=formStore.addresses.findIndex(((item) => item.indexrowaddress === editRowAddress.indexrowaddress));
      if ( indexDelete !== -1 ) {
        formStore.addresses.splice( indexDelete, 1 );
      }
      setFormStore(formStore => ({
        ...formStore,
      }))
      if (editRowAddress.defaultaddress){
        if (formStore.addresses.length>0){
          formStore.addresses[0].defaultaddress=true;
          setFormStore(formStore => ({
            ...formStore,
          }))
        }
      }
      if (editRowAddress.fiscaladdress){
        if (formStore.addresses.length>0){
          formStore.addresses[0].fiscaladdress=true;
          setFormStore(formStore => ({
            ...formStore,
          }))
        }
      }
      setIdCodeDeleteAddress(DELETE_CODE);
      setOpenDeleteRowAddress(false);
    }
  };

  const handleChangeDeleteRowBankAccount = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteBankAccount(value);
  };

  const handleCancelDeleteRowBankAccount = () => {
    setIdCodeDeleteBankAccount(DELETE_CODE);
    setOpenDeleteRowBankAccount(false);
  };

  const handleCloseDeleteRowBankAccount = (e) => {
    e.preventDefault();
    if (idCodeDeleteBankAccount!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formStore.bankaccounts.findIndex(((item) => item.indexrowbankaccount === editRowBankAccount.indexrowbankaccount));
      if ( indexDelete !== -1 ) {
        formStore.bankaccounts.splice( indexDelete, 1 );
      }
      setFormStore(formStore => ({
        ...formStore,
      }))
      if (editRowBankAccount.defaultaccountbank){
        if (formStore.bankaccounts.length>0){
          formStore.bankaccounts[0].defaultaccountbank=true;
          setFormStore(formStore => ({
            ...formStore,
          }))
        }
      }
      setIdCodeDeleteBankAccount(DELETE_CODE);
      setOpenDeleteRowBankAccount(false);
    }
  };

  const handleChangeDeleteRowVehicles = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteVehicle(value);
  };

  const handleCancelDeleteRowVehicle = () => {
    setIdCodeDeleteVehicle(DELETE_CODE);
    setOpenDeleteRowVehicle(false);
  };

  const handleCloseDeleteRowVehicle = (e) => {
    e.preventDefault();
    if (idCodeDeleteVehicle!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      let indexDelete=formStore.vehicles.findIndex(((item) => item.indexrowvehicle === editRowVehicle.indexrowvehicle));
      if ( indexDelete !== -1 ) {
        formStore.vehicles.splice( indexDelete, 1 );
      }
      setFormStore(formStore => ({
        ...formStore,
      }))
      if (editRowVehicle.defaultvehicle){
        if (formStore.vehicles.length>0){
          formStore.vehicles[0].defaultvehicle=true;
          setFormStore(formStore => ({
            ...formStore,
          }))
        }
      }
      setIdCodeDeleteVehicle(DELETE_CODE);
      setOpenDeleteRowVehicle(false);
    }
  };
  
  const handleChangeValueTabs = (event,newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const filteredDataUserAccount = (formStore.useraccounts!==undefined && formStore.useraccounts!==null && formStore.useraccounts.length!==0) ? formStore.useraccounts.filter((el) => {
    if (inputTextUserAccount === '') {
        return el;
    }
    else {
        let dataTranslate=el.useraccount_username+el.useraccount_firstName+el.useraccount_lastName1+el.useraccount_lastName2+el.useraccount_email+el.useraccount_aliasuser+el.useraccount_role;
        return dataTranslate.toLowerCase().includes(inputTextUserAccount);
    }
  }):formStore.useraccounts;

  const validateFormStore = () => {
    let errorsStore = {};
    //let regexNumber = /^[0-9]+$/;
    //let regexEmail = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!formStore.tradename || formStore.tradename==="") {
      errorsStore.tradename = 'Text_FieldNameRequired'; 
    } else {
      if (regexInvalidCharacters.test(formStore.tradename)) {
        errorsStore.tradename = 'Text_TextNoValid';
      }
    } 
    
    if (formStore.documentid!== undefined && formStore.documentid!==""){
      if (regexInvalidCharacters.test(formStore.documentid)) {
        errorsStore.documentid = 'Text_TextNoValid';
      }else{
        if (foundNumberOfDocument){
          errorsStore.documentid='Text_FieldnumberdocumentExist';
        }
      }
    }
   
    if (regexInvalidCharacters.test(formStore.name)) {
      errorsStore.name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formStore.contacperson)) {
      errorsStore.contacperson = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formStore.cnae)) {
      errorsStore.cnae = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formStore.nima_opt_tras)) {
      errorsStore.nima_opt_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formStore.pygr_opt_tras)) {
      errorsStore.pygr_opt_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formStore.opt_opt_tras)) {
      errorsStore.opt_opt_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formStore.comments)) {
      errorsStore.comments = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formStore.economicactivity)) {
      errorsStore.economicactivity = 'Text_TextNoValid';
    }
    
    if (regexInvalidCharacters.test(formStore.accountingentry)) {
      errorsStore.accountingentry = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formStore.comments)) {
      errorsStore.comments = 'Text_TextNoValid';
    }
    
    if (newPhone!=="") {
      if (formStore.phones.find(obj => {return obj.phone ===reformatPhone(newPhone)})){
        errorsStore.newPhone = 'Text_Store_Phone';
      } else {
        if (formStore.phones.length>=Number(LIMITPHONEPERSTORE)){
          errorsStore.newPhone = 'Text_Store_Phones_Length';
        } else {
          if (newPhone.length<=8){
            errorsStore.newPhone = 'Text_Store_Phone_Length';
          } 
        }
      }
    }
    if (newEmail!=="") {
      if (formStore.emails.find(obj => {return obj.email ===newEmail})){
        errorsStore.newEmail = 'Text_Store_Email';
      } else {
        if (formStore.emails.length>=Number(LIMITEMAILPERSTORE)){
          errorsStore.newEmail = 'Text_Store_Emails_Length';
        } else {
          if (newEmail.length<=8){
            errorsStore.newEmail = 'Text_Store_Email_Length';
          } else {
            if (newEmail!=="" && regexInvalidCharacters.test(newEmail)) {
              errorsStore.newEmail = 'Text_Store_Email_Invalid';
            }  
          }
        }
      }
    }
    return errorsStore;
  };

  const handleSubmitDataEditStore = () => {
    const errors = validateFormStore(); 
    setErrorsStore(errors);
    if (Object.keys(errors).length === 0 && formStore.tradename !== '') {
      const fetchData = async () => {
        setIsLoadingData(true); 
        try {
          const getData = id !== "0"
            ? await helpHttp().put(ENDPOINT, `store/updatecreatestore`, token, formStore)
            : await helpHttp().post2(ENDPOINT, `store/savescreatetore`, token, useraccount_id, formStore);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEditStore();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingData(false);
        }  
      }; 
      fetchData();
      setIsLoadingData(false);
    } else {
      if (errors.tradename) {
        dispatch(warningErrorAction(t(errors.tradename)));
      } else if (errors.documentid) {
        dispatch(warningErrorAction(t(errors.documentid)));
      } else if (errors.name) {
        dispatch(warningErrorAction(t(errors.name)));
      } else if (errors.contacperson) {
        dispatch(warningErrorAction(t(errors.contacperson)));
      } else if (errors.cnae) {
        dispatch(warningErrorAction(t(errors.cnae)));
      } else if (errors.economicactivity) {
        dispatch(warningErrorAction(t(errors.economicactivity)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocument = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `store/getstorebynumberdocument/`, token,formStore.documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundNumberOfDocument(getData!==formStore.id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formStore.documentid!==""){
      fetchExistNumberDocument();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formStore.id,formStore.documentid]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataDocumentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `documenttype/simpledocumenttypesbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDocumentTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    const fetchDataExtInternational = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `country/simplephonesextinternational`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setExtInternationalToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    const fetchDataColor = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `color/colorsorderbynamees`, token)
        : await  helpHttp().get(ENDPOINT,`color/colorsorderbynameen`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setColorsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    if (formStore.id!==null ){
      fetchDataDocumentType();
      fetchDataColor();
      if (roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Phone'))){
        fetchDataExtInternational();
      }
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,formStore.id,applyFiltersSearchUserAccount,openFilterSearchUserAccount,formStore.useraccounts]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataStore = async () => {
      setIsLoadingData(true);
      try {
        const getData = await helpHttp().get2(ENDPOINT, `store/getcreatestorebyid/`, token, id);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setFormStore(initialStateStore);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setFormStore(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingData(false);
      } finally {
        if (isMounted) {
          setIsLoadingData(false);
        }
      }
    };
    if (id !== "0") {
      if (formStore.id === "") {
        fetchDataStore();
      }
    } else {
      setFormStore(initialStateStore);
      setFormStore(formStore => ({
        ...formStore,
        color_id: Number(DEFAULTCOLOR),
        documnettype_id: Number(DEFAULTTYPEOFDOCUMENT),
        phones: [],
        emails: [],
        addresses: [],
        vehicles: [],
        useraccounts: []
      }));
      setIsLoadingData(false);
    }
    return () => { isMounted = false };

  }, [ENDPOINT, dispatch, id, t, token, formStore.id, DEFAULTCOLOR, DEFAULTSTORETYPESDI, DEFAULTTYPEOFDOCUMENT, setFormStore, setIsLoadingData]);

  return (
      <>
        {showModalStoreSettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff',height:'1300px', width: '1600px'}}>
              <form className="createstore" onSubmit={handleCloseDataEditStore}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className={formStore.enabled?"card border-info shadow":"card border-dark shadow"}>
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className={formStore.enabled?"h2NewStyle mb-2":"h2NewStyleDark"}>
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className={formStore.enabled?"fas fa-regular fa-home fa text-warning":"fas fa-regular fa-home fa text-dark"}></i>)} 
                                  &nbsp;&nbsp;{formStore.tradename}
                              </h1>
                              <div>
                              {isLoadingData?
                              <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <>
                                  <button type="button" className="buttonCreate" onClick={handleSubmitDataEditStore}>
                                    {id==="0"?t('Button_Create'):t('Button_Update')}
                                  </button><> </>
                                  <button type="button" className="buttonBack" onClick={handleCloseDataEditStore}>{t('Button_Cancel')}</button>
                                </>
                              }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
                {!isLoadingData ?
                <>
                  <div className="row">    
                    <div className="col-xl-2 mb-2"> 
                      <div  className={formStore.enabled?"card border-info shadow":"card border-dark shadow"}>
                        <div className="card-body"> 
                          <div className="row align-items-center">
                            <div className="col mr-2">
                              <div>
                                <Typography style={{color:formStore.enabled?"#4e73df":"#858796", fontSize: 16, fontWeight: 'bold',textDecorationLine:formStore.enabled?'none':'line-through'}}>{t('Text_Id')}</Typography>
                                <Typography  style={{color:formStore.enabled?"#616161":"#858796", fontSize: 26, fontWeight: 'bold', textDecorationLine:formStore.enabled?'none':'line-through'}}> 
                                  {id==="0"?t('Text_New'): formStore.id}
                                </Typography>
                              </div>    
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Hability'))?
                    <div className="col-xl-2 mb-2">
                        <div  className={formStore.enabled?"card border-info shadow":"card border-info shadow"}>
                            <div className="card-body"> 
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        {formStore.enabled?
                                            <div>
                                            <Typography style={{color:"#4e73df", fontSize: 16, fontWeight: 'bold'}}>{t('Text_enabled_Upper')}</Typography>
                                            <button type="button" className="buttonLopdAcepted" onClick={handleChangeEnabledStore}>{t('Button_DISABLED')}</button>
                                            </div>:
                                            <div>
                                            <Typography style={{color:"#4e73df", fontSize: 16, fontWeight: 'bold'}}>{t('Text_disabled')}</Typography>
                                            <button type="button" className="buttonLopdSend"  onClick={handleChangeEnabledStore}>{t('Button_ENABLED')}</button>
                                            </div>
                                        }   
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>:<></>}
                  </div>
                  <br/>
                  
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                    <div  className={formStore.enabled?"card border-info shadow":"card border-dark shadow"}>
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                             
                            <div className="row p-2"> 
                              <div className="form-group">  
                                <h6 className={formStore.enabled?"h3NewStyle mb-2":"h3NewStyleDark"}>{t('Text_Personal_Data_Store')}</h6>
                                {/* Divider */}
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_tradename')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <TextField
                                  id="tradename" 
                                  name="tradename"
                                  value={ formStore.tradename!== null ? formStore.tradename:""}
                                  inputProps={{ style: {width: 620, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsStore.tradename!=null?t(errorsStore.tradename):t('PlaceHolder_Store_tradename')}
                                  placeholder={t('PlaceHolder_Store_tradename')}
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}>
                                </TextField>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_name')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <TextField
                                  id="name" 
                                  name="name"
                                  value={ formStore.name!== null ? formStore.name:""}
                                  inputProps={{ style: {width: 620, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsStore.name!=null?t(errorsStore.name):t('PlaceHolder_Store_name')}
                                  placeholder={t('PlaceHolder_Store_name')}
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}>
                                </TextField>
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group">
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_documenttype')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="documnettype_id"
                                  name="documnettype_id"
                                  value={ (formStore.documnettype_id === undefined || formStore.documnettype_id ===null || formStore.documnettype_id ===""
                                        || formStore.documnettype_id ==="0" || formStore.documnettype_id ===0)?"":formStore.documnettype_id}
                                  inputProps={{ style: {width: 200, textDecorationLine:formStore.enabled?'none':'line-through' }, maxLength: 100, }}
                                  variant="outlined"
                                  onBlur={formStore.enabled?handleBlurSelectDocumentType:() => {}}
                                  onChange={formStore.enabled?handleChangeSelectDocumentType:() => {}}
                                  select
                                  SelectProps={{
                                    native: true,
                                    value: (formStore.documnettype_id === undefined || formStore.documnettype_id ===null || formStore.documnettype_id ===""
                                        || formStore.documnettype_id ==="0" || formStore.documnettype_id ===0)?"": formStore.documnettype_id
                                  }}>
                                  <option key={0} value=""/>
                                  {documentTypesToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {option.name}
                                  </option>
                                  ))}
                                </TextField>  
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_documentid')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="documentid" 
                                  name="documentid"
                                  value={ formStore.documentid!== null ? formStore.documentid:""}
                                  inputProps={{ style: {width: 320, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsStore.documentid!=null?t(errorsStore.documentid):t('PlaceHolder_DocumenType')}
                                  placeholder={t('PlaceHolder_DocumenType')}
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}>
                                </TextField>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_contacperson')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="contacperson" 
                                  name="contacperson"
                                  value={ formStore.contacperson!== null ? formStore.contacperson:""}
                                  inputProps={{ style: {width: 540, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsStore.contacperson!=null?t(errorsStore.contacperson):t('PlaceHolder_Store_contacperson')}
                                  placeholder={t('PlaceHolder_Store_contacperson')}
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}>
                                </TextField>
                               </div>
                            </div>

                            <div className="row p-2"> 
                              <div className="form-group">
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_CNAE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <TextField
                                  id="cnae" 
                                  name="cnae"
                                  value={ formStore.cnae!== null ? formStore.cnae:""}
                                  inputProps={{ style: {width: 200, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsStore.cnae!=null?t(errorsStore.cnae):t('PlaceHolder_Store_CNAE')}
                                  placeholder={t('PlaceHolder_Store_CNAE')}
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}>
                                </TextField>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_economicactivity')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <TextField
                                  id="economicactivity" 
                                  name="economicactivity"
                                  value={ formStore.economicactivity!== null ? formStore.economicactivity:""}
                                  inputProps={{ style: {width: 200, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 100 }}
                                  label={errorsStore.economicactivity!=null?t(errorsStore.economicactivity):t('Text_economicactivity')}
                                  placeholder={t('Text_economicactivity')}
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}>
                                </TextField>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_AccountingEntry')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <TextField
                                  id="accountingentry" 
                                  name="accountingentry"
                                  value={ formStore.accountingentry!== null ? formStore.accountingentry:""}
                                  inputProps={{ style: {width: 200, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 30 }}
                                  label={errorsStore.accountingentry!=null?t(errorsStore.accountingentry):t('PlaceHolder_Store_AccountingEntry')}
                                  placeholder={t('PlaceHolder_Store_AccountingEntry')}
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}>
                                </TextField>
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_color')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="color_id"
                                  name="color_id"
                                  value={ (formStore.color_id === undefined || formStore.color_id ===null || formStore.color_id ===""
                                        || formStore.color_id ==="0" || formStore.color_id ===0)?"":formStore.color_id}
                                  inputProps={{ style: {color:formStore.enabled?formStore.color_codehex:"#858796", width: 200, textDecorationLine:formStore.enabled?'none':'line-through' }, maxLength: 100, }}
                                  variant="outlined"
                                  onBlur={formStore.enabled?handleBlurSelectColor:() => {}}
                                  onChange={formStore.enabled?handleChangeSelectColor:() => {}}
                                  select
                                  SelectProps={{
                                    native: true,
                                    value: (formStore.color_id === undefined || formStore.color_id ===null || formStore.color_id ===""
                                        || formStore.color_id ==="0" || formStore.color_id ===0)?Number(DEFAULTCOLOR): formStore.color_id
                                  }}>
                                  <option key={0} value=""/>
                                  {colorsToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                                </TextField>
                              
                              </div>
                            </div>  
                            <div className="row p-2"> 
                              <div className="form-group">  
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_Comments')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <Textarea 
                                  id="comments" 
                                  name="comments"
                                  value={formStore.comments}
                                  label={errorsStore.comments!=null?t(errorsStore.comments):t('PlaceHolder_Comments')}
                                  placeholder={t('PlaceHolder_Comments')}
                                  sx={{width: i18n.language==="es"?1560:1560} }
                                  variant="standard"
                                  onBlur={formStore.enabled?handleBlurDataEditStore:() => {}}
                                  onChange={formStore.enabled?handleChangeDataEditStore:() => {}}
                                  maxRows={8}
                                />  
                              </div>
                            </div>
                            {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Phone'))&&
                              <div className="row p-2"> 
                                <div className="form-group">
                                <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_Ext_Default')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="extphone_id"
                                  name="extphone_id"
                                  value={ (formStore.extphone_id === undefined || formStore.extphone_id ===null || formStore.extphone_id ===""
                                        || formStore.extphone_id ==="0" || formStore.extphone_id ===0)?Number(DEFAULTEXTPHONE):formStore.extphone_id}
                                  inputProps={{ style: {width: 100, textDecorationLine:formStore.enabled?'none':'line-through' }, maxLength: 10, }}
                                  variant="outlined"
                                  onBlur={formStore.enabled?handleBlurSelectExtInternational:() => {}}
                                  onChange={formStore.enabled?handleChangeExtInternational:() => {}}
                                  select
                                  SelectProps={{
                                    native: true,
                                    value: (formStore.extphone_id === undefined || formStore.extphone_id ===null || formStore.extphone_id ===""
                                          || formStore.extphone_id ==="0" || formStore.extphone_id ===0)?Number(DEFAULTEXTPHONE): formStore.extphone_id
                                  }}>
                                  {extInternationalToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {option.extphone}
                                  </option>
                                  ))}
                                </TextField>
                              </div>
                            </div>
                            }
                            <Box sx={{ bgcolor: '#ffffff',height:(formStore.phones!=null && formStore.phones.length>0)?((Math.floor((formStore.phones.length-1)/4)+1)===0?60:(Math.floor((formStore.phones.length-1)/4)+1)*60):60, width: '1550px'}}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_phones')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Phone'))?
                                  <TextField
                                    id="newPhone" 
                                    name="newPhone"
                                    value={newPhone}
                                    inputProps={{style: {width: 250, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 20}}
                                    InputProps={{ 
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PhoneIcon/>
                                      </InputAdornment>
                                      ),
                                    }}
                                    label={errorsStore.newPhone!=null?t(errorsStore.newPhone):t('PlaceHolder_NewPhone')}
                                    placeholder={t('PlaceHolder_NewPhone')}
                                    variant="standard"
                                    onBlur={formStore.enabled?handleBlurNewPhone:() => {}}
                                    onChange={formStore.enabled?handleChangeNewPhone:() => {}}>
                                  </TextField>:<></>}

                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Phone'))?
                                  <IconButton
                                    size="small"  
                                    style={{color:formStore.enabled?"#e74a3b":"#858796", fontSize: 14}}
                                    onClick={formStore.enabled?handleAddPhone:() => {}}>
                                    <Icon
                                      baseClassName="fas"
                                      className="fa-plus-circle"
                                      sx={{ color:formStore.enabled?"#4e73df":"#858796" }}
                                    />
                                  </IconButton>:<></>}
                                </Grid>
                              
                                {formStore.phones.length>0 && formStore.phones.map((element,index) => (  
                                  <Fragment key={index} >
                                    <Grid item xs={2} key={index}>
                                      <Chip
                                        value={index}
                                        icon={<PhoneIcon style={{color:formStore.enabled?(element.defaultphone?"white":"#4e73df"):"white"}}/>}
                                        label={element.phone}
                                        clickable
                                        style={{color:formStore.enabled?(element.defaultphone?"white":"#4e73df"):"white", width:"250px", justifyContent: "space-between", textDecorationLine:formStore.enabled?'none':'line-through', fontSize: 14, backgroundColor:formStore.enabled?(element.defaultphone?"#4e73df":"white"):"#858796",borderColor:formStore.enabled?"#4e73df":"#858796", borderRadius: "10px", borderWidth:"1px",borderStyle:"solid"}}
                                        onClick={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Default_Phone')) && formStore.enabled?()=>handleChangeDefaultPhone(element):() => {}}
                                        onDelete={formStore.enabled?()=>handleDeletePhone(element):() => {}}
                                        deleteIcon={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Delete_Phone'))?<DeleteIcon style={{color:formStore.enabled?(element.defaultphone?"#e74a3b":"white"):"#858796",backgroundColor:formStore.enabled?(element.defaultphone?"white":"#e74a3b"):"white", borderRadius: "10px"}}/>:<></>}
                                      />
                                    </Grid>
                                    {(index+1)%4===0 && <Grid item xs={4}/>}
                                  </Fragment>
                                ))}
                              </Grid>  
                            </Box>
                            <Box sx={{ bgcolor: '#ffffff',height:(formStore.emails!=null && formStore.emails.length>0)?((Math.floor((formStore.emails.length-1)/4)+1)===0?60:(Math.floor((formStore.emails.length-1)/4)+1)*60):60, width: '1550px'}}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <label className={formStore.enabled?"text-primary":"text-secondary"}>{t('Text_emails')} :</label>
                                  {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Email'))?
                                  <TextField
                                    id="newEmail" 
                                    name="newEmail"
                                    type="email"
                                    value={newEmail}
                                    inputProps={{style: {width: 250, textDecorationLine:formStore.enabled?'none':'line-through'}, maxLength: 75}}
                                    InputProps={{ 
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <EmailIcon/>
                                      </InputAdornment>
                                      ),
                                    }}
                                    label={errorsStore.newEmail!=null?t(errorsStore.newEmail):t('PlaceHolder_NewEmail')}
                                    placeholder={t('PlaceHolder_NewEmail')}
                                    variant="standard"
                                    onBlur={formStore.enabled?handleBlurNewEmail:() => {}}
                                    onChange={formStore.enabled?handleChangeNewEmail:() => {}}>
                                  </TextField>:<></>}
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Email'))?
                                  <IconButton
                                    size="small"  
                                    style={{color:formStore.enabled?"#e74a3b":"#858796", fontSize: 14}}
                                    onClick={formStore.enabled?handleAddEmail:() => {}}>
                                    <Icon
                                      baseClassName="fas"
                                      className="fa-plus-circle"
                                      sx={{ color:formStore.enabled?"#4e73df":"#858796" }}
                                    />
                                  </IconButton>:<></>}      
                                </Grid>
                                {formStore.emails.length>0 && formStore.emails.map((element,index) => { return (   
                                  <Fragment key={index} >
                                    <Grid item xs={2}>
                                      <Chip
                                        value={index}
                                        icon={<EmailIcon style={{color:formStore.enabled?(element.defaultemail?"white":"#4e73df"):"white"}}/>}
                                        label={element.email}
                                        clickable
                                        style={{color:formStore.enabled?(element.defaultemail?"white":"#4e73df"):"white",width:"250px", justifyContent: "space-between" ,textDecorationLine:formStore.enabled?'none':'line-through', fontSize: 14, backgroundColor:formStore.enabled?(element.defaultemail?"#4e73df":"white"):"#858796",borderColor:formStore.enabled?"#4e73df":"#858796", borderRadius: "10px", borderWidth:"1px",borderStyle:"solid"}}
                                        onClick={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Default_Email')) && formStore.enabled?()=>handleChangeDefaultEmail(element):() => {}}
                                        onDelete={formStore.enabled?()=>handleDeleteEmail(element):() => {}}
                                        deleteIcon={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Delete_Email'))?<DeleteIcon style={{color:formStore.enabled?(element.defaultemail?"#e74a3b":"white"):"#858796",backgroundColor:formStore.enabled?(element.defaultemail?"white":"#e74a3b"):"white", borderRadius: "10px"}}/>:<></>}
                                      />
                                    </Grid>  
                                    {(index+1)%4===0 && <Grid item xs={4}/>}
                                  </Fragment>
                                )})}
                              </Grid>
                            </Box>
                            
                            <div className="row p-2"> 
                              <div className="form-group"> 
                                <Box sx={{ width: '100%' }}>
                                  <Tabs
                                    value={valueTabs}
                                    onChange={handleChangeValueTabs}
                                    style={{color:formStore.enabled?"#4e73df":"#858796",width:"1530px"}}
                                    indicatorColor="primary"
                                    variant="fullWidth"
                                    aria-label="secondary tabs example">
                                    <Tab value="first" label={t('Text_Address_Habitual')}/>
                                    {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_View_BankAccount')) && <Tab value="second" label={t('Text_Bank_Data')} />} 
                                    {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_View_Vehicle')) && <Tab value="third" label={t('Text_Vehicles_Store')} />}  
                                    {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_View_Users')) && <Tab value="four" label={t('Text_Users_Store')} />}  
                                    </Tabs>  
                                </Box>
                              </div>
                            </div>
                            { valueTabs==="first"?
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <TableSortingAddress 
                                    dataAddress={formStore.addresses} 
                                    setFormStore={setFormStore}
                                    checkedRow={formStore.enabled}
                                    numTotRegAddress={formStore.addresses.length}
                                    setOpenDeleteRowAddress={setOpenDeleteRowAddress}
                                    setOpenEditNewAddress={setOpenEditNewAddress}
                                    setOpenEditRowAddress={setOpenEditRowAddress}
                                    setEditRowAddress={setEditRowAddress}
                                    roles={roles}
                                    LIMITPAGESIZE={LIMITPAGESIZE}
                                    LIMITADDRESSPERSTORE={LIMITADDRESSPERSTORE.toString()}
                                  />
                                  <StoreCreateOrEditAddress 
                                    openEditRowAddress={openEditRowAddress}
                                    setOpenEditRowAddress={setOpenEditRowAddress} 
                                    openEditNewAddress={openEditNewAddress} 
                                    setOpenEditNewAddress={setOpenEditNewAddress}
                                    formStore={formStore}
                                    setFormStore={setFormStore}
                                    editRowAddress={editRowAddress}
                                    setEditRowAddress={setEditRowAddress}
                                  />
                                </div>
                              </div>
                            :valueTabs==="second"?
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <TableSortingBankAccount 
                                    dataBankAccount={formStore.bankaccounts} 
                                    setFormStore={setFormStore}
                                    checkedRow={formStore.enabled}
                                    numTotRegBankAccount={formStore.bankaccounts.length}
                                    setOpenDeleteRowBankAccount={setOpenDeleteRowBankAccount}
                                    setOpenEditNewBankAccount={setOpenEditNewBankAccount}
                                    setOpenEditRowBankAccount={setOpenEditRowBankAccount}
                                    setEditRowBankAccount={setEditRowBankAccount}
                                    roles={roles}
                                    LIMITPAGESIZE={LIMITPAGESIZE}
                                    LIMITBANKACCOUNTPERSTORE={LIMITBANKACCOUNTPERSTORE.toString()}
                                  />
                                  <StoreCreateOrEditBankAccount 
                                    openEditRowBankAccount={openEditRowBankAccount}
                                    setOpenEditRowBankAccount={setOpenEditRowBankAccount} 
                                    openEditNewBankAccount={openEditNewBankAccount} 
                                    setOpenEditNewBankAccount={setOpenEditNewBankAccount}
                                    formStore={formStore}
                                    setFormStore={setFormStore}
                                    editRowBankAccount={editRowBankAccount}
                                    setEditRowBankAccount={setEditRowBankAccount}
                                  />
                                </div>
                              </div>
                            :valueTabs==="third" ?
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <TableSortingVehicle 
                                    dataVehicle={formStore.vehicles}
                                    setFormStore={setFormStore}
                                    checkedRow={formStore.enabled}
                                    numTotRegVehicle={formStore.vehicles.length}
                                    setOpenDeleteRowVehicle={setOpenDeleteRowVehicle}
                                    setOpenEditNewVehicle={setOpenEditNewVehicle}
                                    setOpenEditRowVehicle={setOpenEditRowVehicle}
                                    setEditRowVehicle={setEditRowVehicle}
                                    roles={roles}
                                    LIMITPAGESIZE={LIMITPAGESIZE} 
                                  />
                                   <StoreCreateOrEditVehicle 
                                    openEditRowVehicle={openEditRowVehicle}
                                    setOpenEditRowVehicle={setOpenEditRowVehicle} 
                                    openEditNewVehicle={openEditNewVehicle} 
                                    setOpenEditNewVehicle={setOpenEditNewVehicle}
                                    formStore={formStore}
                                    setFormStore={setFormStore}
                                    editRowVehicle={editRowVehicle}
                                    setEditRowVehicle={setEditRowVehicle}
                                  />
                                </div>
                              </div>
                            :valueTabs==="four" ?
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <TableSortingUserAccounts 
                                    dataUserAccounts={filteredDataUserAccount}
                                    checkedRow={formStore.enabled}
                                    numTotRegUserAccount={formStore.useraccounts!==null?formStore.useraccounts.length:0}
                                    setInputTextUserAccount={setInputTextUserAccount}
                                    LIMITPAGESIZE={LIMITPAGESIZE}
                                  />
                                  
                                </div>
                              </div>

                            :valueTabs==="five" &&
                                <div className="row p-2"> 
                                   
                                </div> 
                            }
                          </div>
                        </div>   
                      </div>
                    </div>
                </div>
              </>:
              <Loading isLoadingData={isLoadingData}/>
            }
            </form>
          </Box>
          <br/>

          <div>
            <Dialog open={openDeleteRowAddress} onClose={handleCancelDeleteRowAddress}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowAddress.indexrowaddress} - {i18n.language==="es"?editRowAddress.roadtype_namees:editRowAddress.roadtype_nameen} {editRowAddress.street} {editRowAddress.number} {editRowAddress.floor}  {editRowAddress.door}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteAddress}
                  onChange={handleChangeDeleteRowAddress}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowAddress}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowAddress}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
          
          <div>
            <Dialog open={openDeleteRowBankAccount} onClose={handleCancelDeleteRowBankAccount}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowBankAccount.indexrowbankaccount} - {editRowBankAccount.codbic} {editRowBankAccount.namebank} {editRowBankAccount.iban}  {editRowBankAccount.entity} {editRowBankAccount.office} {editRowBankAccount.cd} {editRowBankAccount.accountbank}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteBankAccount}
                  onChange={handleChangeDeleteRowBankAccount}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowBankAccount}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowBankAccount}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>

          <div>
            <Dialog open={openDeleteRowVehicle} onClose={handleCancelDeleteRowVehicle}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowVehicle.indexrowvehicle} - {editRowVehicle.licenseplate} </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteVehicle}
                  onChange={handleChangeDeleteRowVehicles}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowVehicle}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowVehicle}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      //* End Page Content *//
    ) :  null }
  </>
  )    
}

StoreEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalStoreSettings: PropTypes.bool.isRequired
};

export default StoreEditOrCreate;