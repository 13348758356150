import React from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { Typography } from '@mui/material';
import { Box } from '@mui/material'; 
import { NumericFormat } from 'react-number-format';
import { helpHttp } from "service/HelpHttp";
import { warningErrorAction } from "actions/ErrorActions"; 
import LockIcon from '@mui/icons-material/Lock';  
import LockOpenIcon from '@mui/icons-material/LockOpen'; 
import IconButton from '@mui/material/IconButton'; 
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScaleIcon from '@mui/icons-material/Scale';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types'; 

const headCellsArticles = [
  { id: 'code',label: 'Text_TICKETS_CODE' },
  { id: 'name',label: 'Text_TICKETS_ARTICLE_TITLE' },
  { id: 'firstweight', label: 'Text_TICKETS_ARTICLE_WEIGHT_FIRST' },
  { id: 'weight', label: 'Button_Weight'},
  { id: 'secondweight',label: 'Text_TICKETS_ARTICLE_WEIGHT_SECOND'},
  { id: 'formdi_id',label: 'Text_TICKETS_ARTICLE_DI'}
];
 
const TicketsSelectArticle = ({
  editRowArticles,
  setEditRowArticles,
  form,
  setForm,
  setErrorsForm,
  validateTickets,
  unlockData,
  unlockDataChangeState,
  setUnlockDataChangeState,
  setShowModalSearchArticles,
  configPrinter,
  setShowModalSearchDI}) => {
  const {t,i18n} = useTranslation(['listDataTickets']); 
  const ENDPOINTWEIGHT = useSelector((state) => state.loginUser.defaultticketsformat.endpointweight);
  const dispatch = useDispatch();

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 1000000) return true;
    return false;
  };

  const handleBlurArticlesWeight = (value, row) => {
    handleChangeArticleWeight(value, row);
  };

  const handleAddNewArticle = () => {
    setShowModalSearchArticles(true); 
  }; 

  const handleSelectDI = () => {
    setShowModalSearchDI(true); 
  }; 

  const changeLocked = () => {
    setUnlockDataChangeState(!unlockDataChangeState); 
  };

  const openExternalWeigh = () => {
    // CÓDIGO LECTURA EXTERNA PESA
    const fetchData = async () => {
      try {
        const getData = await helpHttp().postWeigt(ENDPOINTWEIGHT,`api/config`,configPrinter);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));   
        } else {
          if (getData.portData!==undefined && getData.portData!==null & getData.portData!==""){
            handleChangeArticleWeight(getData.portData); 
          }
        }       
      } catch (error) {
        dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));  
      }
    };
    fetchData();
  };

  const handleChangeArticleWeight = (value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      firstweight: newValue
    })); 
    setForm(form => ({
     ...form,
     totalstartweight:newValue
    }));
  }; 

  const openModalDeleteRowArticle = () => {
    setForm(form => ({
      ...form,
      totalstartweight:0,
      totalendweight:unlockData?form.vehiclestore_tara:0
    })); 
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      article_id: 0,
      article_code: "",
      article_namees: "",
      article_nameen: "",
      start_date_ticket: "",
      end_date_ticket: "",
      firstweight: 0,
      secondweight:unlockData?form.vehiclestore_tara:0,
      estimatedprice: 0,
      discount: 0,
      tax: 0,
      taxincluded:false,
      ticketweight:true,
      formdi_id:0,
      ownformdi_id:0
    })); 
    setErrorsForm(validateTickets());
  };

  return(
     <> 
      <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
        {t('Text_TICKETS_SELECT_ARTICLES')} &nbsp;&nbsp;
        <IconButton size="small"style={{color:"#e74a3b", fontSize: 14}} onClick={handleAddNewArticle}>
          <SearchIcon sx={{ color:"#067330"}}/>
        </IconButton>  
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <IconButton size="small"style={{color:(
          form.vehiclestore_licenseplate===undefined || form.vehiclestore_licenseplate==="" ||
          editRowArticles.article_code===undefined || editRowArticles.article_code==="" || editRowArticles.article_code==="0" || editRowArticles.article_code===0 )?"#858796":"#067330", fontSize: 14}} disabled={(
            form.vehiclestore_licenseplate===undefined || form.vehiclestore_licenseplate==="" ||
            editRowArticles.article_code===undefined || editRowArticles.article_code==="" || editRowArticles.article_code==="0" || editRowArticles.article_code===0 )} onClick={handleSelectDI}>
          {t('Button_Join')}&nbsp;&nbsp;<JoinInnerIcon sx={{ color:(
            form.vehiclestore_licenseplate===undefined || form.vehiclestore_licenseplate==="" ||
            editRowArticles.article_code===undefined || editRowArticles.article_code==="" || editRowArticles.article_code==="0" || editRowArticles.article_code===0 )?"#858796":"#067330"}}/>
        </IconButton>  
      </Typography>

      <Box sx={{ width: '100%' }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
            <TableHead>
              <TableRow>
                {headCellsArticles.map((headCell, indexhead) => (
                  <TableCell  key={indexhead}  align={'left'}  padding={'normal'}  sortDirection={false}>
                    <Typography style={{color:"#4e73df", fontSize: 14}} >  
                      {t(headCell.label)}
                    </Typography>
                  </TableCell>
                ))}
                {unlockData &&
                  <TableCell  key='unlock'  align='center' padding='normal' sortDirection={false} >
                    <Typography style={{color:"#4e73df", fontSize: 14}} > 
                      {unlockDataChangeState?t('Button_Lock'):t('Button_Unlock')} 
                    </Typography>
                  </TableCell>
                }  
                <TableCell  key='delete'  align='center' padding='normal' sortDirection={false} >
                  <Typography style={{color:"#e74a3b", fontSize: 14}} > 
                    {t('Button_Delete')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover key={editRowArticles.id}>
                <TableCell align="left">
                  <Typography style={{color:"#4e73df", fontSize: 14}} >
                    {editRowArticles.article_code}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography style={{color:"#4e73df", fontSize: 14}} >
                      {i18n.language==="es"?editRowArticles.article_namees:editRowArticles.article_nameen}
                  </Typography>  
                </TableCell>
                <TableCell align="left">
                  <NumericFormat 
                    thousandSeparator={','} 
                    decimalSeparator={'.'} 
                    decimalScale= {0}
                    fixedDecimalScale= {true}
                    allowNegative= {false}
                    isAllowed={withValueCap}
                    id="firstweight" 
                    name="firstweight"
                    value={editRowArticles.firstweight}
                    style={{width:118, color:"red", background:"black",fontSize: 20,  paddingLeft:5}} 
                    onBlur={(e) => handleBlurArticlesWeight(e.target.value)}
                    onChange={(e) => handleChangeArticleWeight(e.target.value)}
                  /> 
                </TableCell>
                <TableCell align="center">
                  <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}} onClick={openExternalWeigh}>
                    <ScaleIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="left">
                  <NumericFormat 
                    thousandSeparator={','} 
                    decimalSeparator={'.'} 
                    decimalScale= {0}
                    fixedDecimalScale= {true}
                    allowNegative= {false}
                    isAllowed={withValueCap}
                    id="secondweight" 
                    name="secondweight"
                    value={editRowArticles.secondweight}
                    style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 20, paddingLeft:5}} 
                    disabled
                  />  
                </TableCell>
                <TableCell align="center">
                  <Typography style={{color:"#067330", fontSize: 14}} >
                    {editRowArticles.formdi_id===undefined || editRowArticles.formdi_id==="" || editRowArticles.formdi_id==="0" || editRowArticles.formdi_id===0 ?t('Text_NotJoined'):editRowArticles.ownformdi_id}
                  </Typography>
                </TableCell>
                {unlockData &&
                  <TableCell  key='delete'  align='center' padding='normal' sortDirection={false} >
                    <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}} onClick={changeLocked}>
                      {unlockDataChangeState?<LockIcon/>:<LockOpenIcon/>}
                    </IconButton>
                  </TableCell> 
                } 
                <TableCell align="center">
                  <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={openModalDeleteRowArticle}>
                    <DeleteIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

TicketsSelectArticle.propTypes = {
  setEditRowArticles: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  setErrorsForm: PropTypes.func.isRequired,
  validateTickets: PropTypes.func.isRequired,
  unlockData: PropTypes.bool.isRequired,
  unlockDataChangeState: PropTypes.bool.isRequired,
  setUnlockDataChangeState: PropTypes.func.isRequired,
  setShowModalSearchArticles: PropTypes.func.isRequired,
  configPrinter: PropTypes.object.isRequired,
  setShowModalSearchDI: PropTypes.func.isRequired
};

export default TicketsSelectArticle;