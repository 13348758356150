import React, {useState, useEffect} from 'react' 
import { useDispatch } from "react-redux"; 
import { useTheme } from '@mui/material/styles';
import {useTranslation} from "react-i18next"
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt'; 
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { warningErrorAction } from "actions/ErrorActions"; 
import { TextField } from '@mui/material';
import {Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';

const headCellsArticle = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Text_ARTICLE_Code',
    canOrder:true,
  },
  {
    id: 'namees',
    numeric: false,
    disablePadding: false,
    label: 'Text_ARTICLE_NameEs',
    canOrder:true,
  },
  {
    id: 'nameen',
    numeric: false,
    disablePadding: false,
    label: 'Text_ARTICLE_NameEn',
    canOrder:true,
  }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {t('Button_Select')}
          </Typography>
        </TableCell>
        {headCellsArticle.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
       
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegArticle, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegArticle).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataTickets']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingArticleEdit =(props)=>{
  const { 
    dataArticle,
    numTotRegFilter,
    titleTable, 
    numTotRegArticle,
    activeOffsetArticle,
    rangeOffsetArticle,
    currencyOffsetArticle,
    setCurrencyOffsetArticle,  
    handleCloseDataEditArticle,
    formTickets,
    setFormTickets,
    LIMITSARTICLESPERWEIGHT

  } = props;
  const {t} = useTranslation(['listDataTickets']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticle.length) : 0);
  const [countData,setCountData] = useState(dataArticle.length);
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (dataArticle.length!==countData || dataArticle.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataArticle.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticle.length) : 0);  
    },
    [dataArticle,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetArticle(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const selectDefaultArticle = (row) => {
    if (row.ticketweight){
      let articleToAdd={
        id:"",
        indexrowarticle:formTickets.linestickets.length>0?formTickets.linestickets[formTickets.linestickets.length-1].indexrowarticle+1:1,  
        tickets_id:0,
        article_id: row.id,
        article_code: row.code,
        article_namees: row.namees,
        article_nameen: row.nameen,
        start_date_ticket: formTickets.start_date_ticket,
        end_date_ticket: formTickets.end_date_ticket,
        firstweight: 0,
        secondweight:0,
        estimatedprice: row.estimatedprice ,
        discount: row.discount,
        tax: row.tax ,
        taxincluded: row.taxincluded,
        ticketweight:row.ticketweight,
        formdi_id:0,
        ownformdi_id:0
      }
      formTickets.linestickets.push(articleToAdd);
      setFormTickets(formTickets => ({
        ...formTickets
      }))
    }else{
      let articleToAdd={
        id:"",
        indexrowarticle:formTickets.linesticketsWithOutWeight.length>0?formTickets.linesticketsWithOutWeight[formTickets.linesticketsWithOutWeight.length-1].indexrowarticle+1:1,  
        tickets_id:0,
        article_id: row.id,
        article_code: row.code,
        article_namees: row.namees,
        article_nameen: row.nameen,
        start_date_ticket: formTickets.start_date_ticket,
        end_date_ticket: formTickets.end_date_ticket,
        firstweight: 1,
        secondweight:0,
        estimatedprice: row.estimatedprice ,
        discount: row.discount,
        tax: row.tax ,
        taxincluded: row.taxincluded,
        ticketweight:row.ticketweight,
        formdi_id:0,
        ownformdi_id:0
      }
      if(formTickets.linesticketsWithOutWeight.length>=LIMITSARTICLESPERWEIGHT){ 
        dispatch(warningErrorAction(t('Text_LIMITARTICESPERWEIGHT')));
      }else { 
        formTickets.linesticketsWithOutWeight.push(articleToAdd);
        setFormTickets(formTickets => ({
          ...formTickets
        }))
      }
    }
    handleCloseDataEditArticle();
  };
  const listItems= (
  stableSort(dataArticle, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        {(row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}}  onClick={()=>selectDefaultArticle(row)}>
              <PanToolAltIcon/>
            </IconButton>
          </TableCell>
        }
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.id}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.code}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.namees}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >  
            {row.nameen}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegArticle={numTotRegArticle} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} />
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={4} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetArticle && (
                    <TableCell colSpan={4}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetArticle"
                            name="currencyOffsetArticle"
                            value={currencyOffsetArticle}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetArticle
                            }}>
                            {rangeOffsetArticle.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={14}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingArticleEdit.propTypes = {
  dataArticle: PropTypes.array.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  numTotRegArticle: PropTypes.number.isRequired,
  activeOffsetArticle: PropTypes.bool.isRequired,
  rangeOffsetArticle: PropTypes.array.isRequired,
  currencyOffsetArticle: PropTypes.number.isRequired,
  setCurrencyOffsetArticle: PropTypes.func.isRequired, 
  handleCloseDataEditArticle: PropTypes.func.isRequired, 
  setFormTickets: PropTypes.func.isRequired
};
  
export default TableSortingArticleEdit;
