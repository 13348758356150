import React, {  useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import TableSortingArticleEdit from 'pages/tickets/ticketsEdit/editTickets/SortingTablesTicketsArticlesEdit'; 

const initialFiltersArticle= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":""
};
 
const initialStateArticle = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": "" ,
  "estimatedprice": 0,
  "tax": 0,
  "taxincluded":false,
  "ticketweight":true 
}];
        

const TicketsSearchArticlesEdit = ({ showModalSearchArticles, setShowModalSearchArticles,setEditRowArticles,formTickets,setFormTickets,LIMITSARTICLESPERWEIGHT}) => {
  const {t,i18n} = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const [dataArticle, setDataArticle] = useState(initialStateArticle);
  const [isLoadingErrorArticle, setIsLoadingErrorArticle] = useState(false);
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);  
  const [inputTextArticle, setInputTextArticle] = useState("");
  const [applyFiltersArticle, setApplyFiltersArticle]= useState(initialFiltersArticle);
  const [filtersArticle, setFiltersArticle]= useState(initialFiltersArticle);
  const [openEditFilterArticle,setOpenEditFilterArticle]= useState(false);
  const [errorsFiltersArticle, setErrorsFiltersArticle] = useState({});
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegArticle,setNumTotRegArticle]= useState(0);
  const [activeOffsetArticle, setActiveOffsetArticle]= useState(false);
  const [currencyOffsetArticle, setCurrencyOffsetArticle] = useState(0);
  const [rangeOffsetArticle, setRangeOffsetArticle] = useState([{}]); 
  const dispatch = useDispatch(); 
   
  const handleCloseDataEditArticle = () => {
    setDataArticle(initialStateArticle);
    setIsLoadingErrorArticle(false);
    setIsLoadingArticle(false);
    setInputTextArticle("");
    setApplyFiltersArticle(initialFiltersArticle);
    setFiltersArticle(initialFiltersArticle);
    setOpenEditFilterArticle(false);
    setErrorsFiltersArticle({});
    setNumTotRegFilter(0);
    setNumTotRegArticle(0);
    setActiveOffsetArticle(false);
    setCurrencyOffsetArticle(0);
    setRangeOffsetArticle([{}]); 
    setShowModalSearchArticles(false)
	};

  const toggleDrawerSearchArticles  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditArticle();
    }
  };

  let inputHandlerArticle = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextArticle(lowerCase);
  };

  const openEditFiltersArticle = () => {
    setFiltersArticle(applyFiltersArticle);
    setOpenEditFilterArticle(!openEditFilterArticle);
  };

  const toggleDrawerFilterEditArticle = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditArticle();
    }
  };

  const handleCancelFiltersArticle = () => {
    setErrorsFiltersArticle({});
    setFiltersArticle(initialFiltersArticle);
    setOpenEditFilterArticle(!openEditFilterArticle);
  };

  const handleChangeFilterEditArticle = (e) => {
    const { name, value } = e.target;
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      [name]: value
    }));
  };

  const handleBlurFilterEditArticle = (e) => {
    setErrorsFiltersArticle(validateFormFilterArticle());
    handleChangeFilterEditArticle(e);
  };
 
  const handleSubmitFilterEditArticle = () => {
    setErrorsFiltersArticle(validateFormFilterArticle());
    if (Object.keys(errorsFiltersArticle).length === 0) {
      setApplyFiltersArticle(filtersArticle);
      setFiltersArticle(initialFiltersArticle);
      setErrorsFiltersArticle({});
      setOpenEditFilterArticle(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  const handleDeleteFiltersArticle = () => {
    setErrorsFiltersArticle({});
    setFiltersArticle(initialFiltersArticle);
    setApplyFiltersArticle(initialFiltersArticle);
    setOpenEditFilterArticle(!openEditFilterArticle);
  };

  const validateFormFilterArticle = () => {
    let errorsFiltersArticle = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (filtersArticle.idFrom!=="" & !regexNumber.test(filtersArticle.idFrom)) {
      errorsFiltersArticle.idFrom = 'Text_TextNoNumber';
    }
    if (filtersArticle.idTo!=="" & !regexNumber.test(filtersArticle.idTo)) {
      errorsFiltersArticle.idTo = 'Text_TextNoNumber';
    }
    if (filtersArticle.idFrom!=="" & !errorsFiltersArticle.idFrom!==undefined){
      if (filtersArticle.idTo!=="" & !errorsFiltersArticle.idTo!==undefined){
        if (parseInt(filtersArticle.idTo)<parseInt(filtersArticle.idFrom)){
          errorsFiltersArticle.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filtersArticle.nameesFrom)) {
      errorsFiltersArticle.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.nameesTo)) {
      errorsFiltersArticle.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.nameenFrom)) {
      errorsFiltersArticle.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.nameenTo)) {
      errorsFiltersArticle.nameenTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.codeFrom)) {
      errorsFiltersArticle.codeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.codeTo)) {
      errorsFiltersArticle.codeTo = 'Text_TextNoValid';
    } 
    if (filtersArticle.nameesTo!=="" & filtersArticle.nameesTo<filtersArticle.nameesFrom){
      errorsFiltersArticle.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filtersArticle.nameenTo!=="" & filtersArticle.nameenTo<filtersArticle.nameenFrom){
      errorsFiltersArticle.nameenTo = 'Text_TextGreater_Nameen';
    }
    if (filtersArticle.codeTo!=="" & filtersArticle.codeTo<filtersArticle.codeFrom){
      errorsFiltersArticle.codeTo = 'Text_TextGreater_Article';
    }
    return errorsFiltersArticle;
  }  

  const filteredDataArticle = dataArticle.filter((el) => {
    if (inputTextArticle === '') {
        return el;
    }
    else {
      let dataTranslate=t(el.id+el.namees+el.nameen+el.code) ;
      return dataTranslate.toLowerCase().includes(inputTextArticle);
    }
  })

  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegArticle = async () => {
      try {
        setIsLoadingArticle(true);
        const getData = await helpHttp().get(ENDPOINT, `ticketsgetdata/getinformationtablearticles`, token);
        if (getData.err) {
          setDataArticle(initialStateArticle);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegArticle(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingArticle(false);
      }finally {
        setIsLoadingArticle(false);
      }
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingArticle(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `ticketsgetdata/getcountfilterarticles`, token, applyFiltersArticle);
        if (getData.err || getData.message === "Load failed") {
          setDataArticle(initialStateArticle);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingArticle(false);
      } 
      if (isMounted) {
        setIsLoadingArticle(false);
      }
    };

    const fetchDataArticle = async () => {
      setIsLoadingArticle(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT,`ticketsgetdata/getarticles`,token,currencyOffsetArticle,LIMITPAGESIZE,applyFiltersArticle);
        if (getData.err || getData.message === "Load failed") {
          setDataArticle(initialStateArticle);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataArticle(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingArticle(false);
      } finally {
        setIsLoadingArticle(false);
      }
    };
    
    if (showModalSearchArticles){
      fetchnumTotRegArticle();
      fetchnumTotRegFilter();
      fetchDataArticle();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,i18n,token,dispatch,LIMITPAGESIZE,applyFiltersArticle,currencyOffsetArticle,setIsLoadingArticle,showModalSearchArticles]);

  return(
    <>
      {showModalSearchArticles && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchArticles(false)}
            onOpen={toggleDrawerSearchArticles(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff', width: '900px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  {isLoadingErrorArticle?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingArticle?<CircularProgress color={"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_ARTICLEs_Search')}</h1>
                    <div>
                      <button type="button" className="buttonCreate" onClick={handleCloseDataEditArticle}>{t('Button_Exit')}</button><> </>
                    </div> 
                </div>  
                <br/>
                <div className="searchStyle">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={inputTextArticle}
                    placeholder={t('Text_ARTICLE_Search')} 
                    onChange={inputHandlerArticle}
                    fullWidth
                    label={t('Text_ARTICLE_Search')}>
                  </TextField>
                </div>
                <br/>
                <div id="myDIV">
                  <h1 className="h6NewStyle mb-2">
                    {t('Text_Filter')}&nbsp;
                      <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.idFrom!=="" || applyFiltersArticle.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.codeFrom!=="" || applyFiltersArticle.codeTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_ARTICLE_Code')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.nameesFrom!=="" || applyFiltersArticle.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_ARTICLE_NameEs')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.nameenFrom!=="" || applyFiltersArticle.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_ARTICLE_NameEn')}</button><> </> 
                      
                  </h1>
                </div>
                <br/>
                {!isLoadingArticle ?
                  <>
                    <div className="card shadow mb-4">    
                      <div className="card-body">
                        <TableSortingArticleEdit
                          dataArticle={filteredDataArticle}
                          numTotRegFilter={ numTotRegFilter}
                          titleTable={t('Text_ARTICLE_Table')}
                          numTotRegArticle={numTotRegArticle}
                          activeOffsetArticle={activeOffsetArticle}
                          rangeOffsetArticle={rangeOffsetArticle}
                          currencyOffsetArticle={currencyOffsetArticle}
                          setCurrencyOffsetArticle={setCurrencyOffsetArticle}
                          setEditRowArticles={setEditRowArticles}
                          handleCloseDataEditArticle={handleCloseDataEditArticle}
                          formTickets={formTickets}
                          setFormTickets={setFormTickets}
                          LIMITSARTICLESPERWEIGHT={LIMITSARTICLESPERWEIGHT}
                        >
                        </TableSortingArticleEdit>
                      </div>             
                    </div>
                    {openEditFilterArticle &&
                      <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEditArticle(false)}
                        onOpen={toggleDrawerFilterEditArticle(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100' }}>
                            <br/>
                              <div id="myDIV">
                                <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <form className="newfilterformstore" onSubmit={handleCancelFiltersArticle}>  
                                <div className="form-floating mb-3 px-4 primary">
                                  <div className="form-group">
                                    <TextField
                                      id="idFrom" 
                                      name="idFrom"
                                      value={filtersArticle.idFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersArticle.idFrom!=null?t(errorsFiltersArticle.idFrom):t('Text_Filter_Code_From')}
                                      placeholder={t('PlaceHolder_Filter_Code_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="idTo" 
                                      name="idTo"
                                      value={filtersArticle.idTo}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersArticle.idTo!=null?t(errorsFiltersArticle.idTo):t('Text_Filter_Code_To')}
                                      placeholder={t('PlaceHolder_Filter_Code_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="codeFrom" 
                                      name="codeFrom"
                                      value={filtersArticle.codeFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 10 }}
                                      label={errorsFiltersArticle.codeFrom!=null?t(errorsFiltersArticle.codeFrom):t('Text_Filter_CodeArticle_From')}
                                      placeholder={t('PlaceHolder_Filter_CodeArticle_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                      id="codeTo" 
                                      name="codeTo"
                                      value={filtersArticle.codeTo}
                                      inputProps={{ style: {width: 400}, maxLength: 10}}
                                      label={errorsFiltersArticle.codeTo!=null?t(errorsFiltersArticle.codeTo):t('Text_Filter_CodeArticle_To')}
                                      placeholder={t('PlaceHolder_Filter_CodeArticle_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="nameesFrom" 
                                      name="nameesFrom"
                                      value={filtersArticle.nameesFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersArticle.nameesFrom!=null?t(errorsFiltersArticle.nameesFrom):t('Text_Filter_NameEs_From')}
                                      placeholder={t('PlaceHolder_Filter_NameEs_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="nameesTo" 
                                      name="nameesTo"
                                      value={filtersArticle.nameesTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersArticle.nameesTo!=null?t(errorsFiltersArticle.nameesTo):t('Text_Filter_NameEs_To')}
                                      placeholder={t('PlaceHolder_Filter_NameEs_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="nameenFrom" 
                                      name="nameenFrom"
                                      value={filtersArticle.nameenFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersArticle.nameenFrom!=null?t(errorsFiltersArticle.nameenFrom):t('Text_Filter_NameEn_From')}
                                      placeholder={t('PlaceHolder_Filter_NameEn_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="nameenTo" 
                                      name="nameenTo"
                                      value={filtersArticle.nameenTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersArticle.nameenTo!=null?t(errorsFiltersArticle.nameenTo):t('Text_Filter_NameEn_To')}
                                      placeholder={t('PlaceHolder_Filter_NameEn_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditArticle}
                                      onChange={handleChangeFilterEditArticle}/>
                                  </div>  
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditArticle}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersArticle}>{t('Button_Cancel_Filters')}</button>
                                </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  
                  {/* End Content Row */}
                  </>:
                    <Loading isLoadingData={isLoadingArticle}/>
                }
                
                
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

TicketsSearchArticlesEdit.propTypes = {
  showModalSearchArticles: PropTypes.bool.isRequired,
  setShowModalSearchArticles: PropTypes.func.isRequired,
  setEditRowArticles: PropTypes.func.isRequired, 
  setFormTickets: PropTypes.func.isRequired
};

export default TicketsSearchArticlesEdit;

