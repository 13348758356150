import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box } from '@mui/material'; 
import { Typography} from '@mui/material';
import { TextField } from '@mui/material';  
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading"; 
import { NumericFormat } from 'react-number-format'; 
import { Grid } from '@mui/material';
import { CardActions } from '@mui/material'; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css"; 
import { experimentalStyled as styled } from '@mui/material/styles'; 
import CircularProgress from '@mui/material/CircularProgress';   
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton' 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card'; 
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SignatureCanvas from 'react-signature-canvas';
import PurchaseDeliverySearchStores from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchStores";
import PurchaseDeliverySearchVehicles from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchVehicles";
import PurchaseDeliverySearchStoresAddresses from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchStoresAddress";
import PurchaseDeliverySearchArticles from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchArticles";
import TableSortingPurchaseDeliveryArticleList from "pages/purchaseDelivery/editOrNewPurchaseDelivery/SortingTablesPurchaseDeliveryArticlesList";
import PurchaseDeliverySearchDI from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchDI";
import image1 from "components/photos/formdi/tosign.png"; 
import signFrancisco from "components/photos/signs/Francisco.png"; 
import signGonzalo from "components/photos/signs/Gonzalo.png"; 
import signGuillermo from "components/photos/signs/Guillermo.png"; 
import signJorge from "components/photos/signs/Jorge.png"; 

import PropTypes from 'prop-types';  

const initialStatePurchaseDelivery = {
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "purchasenote":0, 
  "numberticket":0, 
  "ownnumberpurchase":"", 
  "datepurchase": null, 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_nima": "",
  "rel_trans_pygr": "",
  "rel_trans_grnp":"",
  "rel_trans_opt": 0,
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_nima": "",
  "ori_tras_pygr": "",
  "ori_tras_grnp":"",
  "ori_tras_opt": 0,
  "ori_tras_workplace": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "ori_tras_email": "",

  "observations": "",
  "totalpurchasedelivery": 0,
  "totaldiscountpurchasedelivery": 0,
  "totaltaxespurchasedelivery":0,
  "totalNetoPurchasedelivery":0,
  "enabled":true,
  "subownnumberpurchase":0,
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": "",
  "totalWeigthNeto":0,
  "signature_dispatched_by":"",
  "signature_client":"",

  "linespurchasedelivery":[]
};

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPaymentToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": "",
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":30
}];

const initialArticlesToSelect = {
  "id": 0,
  "indexrowarticle":0, 
  "purchase_id":0,
  "article_id":0,
  "article_code": "",
  "article_namees": "",
  "article_nameen": "",
  "datepurchase": null,
  "estimatedprice": 0,
  "amount": 0,
  "discount":0,
  "tax":0,
  "taxincluded":false, 
  "total":0,
  "ticketweight":true,
  "formdi_id":0,
  "ownformdi_id":0
  
}; 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const PurchaseDeliveryEditOrCreate = ({id, showModalPurchasesDeliverySettings,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token);  
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const articlesButtonsList = useSelector((state) => state.loginUser.defaultticketsformat.articlesButtonsList);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [formPurchaseDelivery, setFormPurchaseDelivery] = useState(initialStatePurchaseDelivery);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});
  const [tetxtSearch, setTetxtSearch] = useState("Rel_Trans");  
  const [idCodeDeletePurchaseDelivery, setIdCodeDeletePurchaseDelivery] = useState(DELETE_CODE);
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false);  
  const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
  const [showModalSearchArticles, setShowModalSearchArticles] = useState(false); 
  const [openDeleteRowArticles,setOpenDeleteRowArticles]= useState(false);
  const [editRowArticles, setEditRowArticles] = useState(initialArticlesToSelect); 
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect);
  const [paymenetTypeSelect,setPaymenetTypeSelect]= useState(initialPaymentToSelect); 
  const [showModalSearchDI, setShowModalSearchDI] = useState(false);  
  const [openSignDispatched,setOpenSignDispatched]= useState(false); 
  const [openSignClient,setOpenSignClient]= useState(false);
  const [signDispatchedCanvas, setSignDispatchedCanvas]= useState(); 
  const [signClientCanvas, setSignClientCanvas]= useState(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesDeliverySettings) {
        setFormPurchaseDelivery(formPurchaseDelivery => ({
          ...formPurchaseDelivery,
          linespurchasedelivery: [],
        }));
        setFormPurchaseDelivery(initialStatePurchaseDelivery);
        setIsLoadingData(false);
        setIsLoadingErrorData(false);
        setErrorsForm({});
        setTetxtSearch("Rel_Trans ");   
        setIdCodeDeletePurchaseDelivery(DELETE_CODE);
        setShowModalSearchStores(false);
        setShowModalSearchVehicles(false);
        setShowModalSearchStoresAddress(false);
        setShowModalSearchArticles(false); 
        setOpenSignDispatched(false);
        setOpenSignClient(false);
        setOpenDeleteRowArticles(false); 
        setShowModalSearchDI(false);
        setSignDispatchedCanvas();
        setSignClientCanvas();
        setEditRowArticles(initialArticlesToSelect); 
        setProvinceToSelect(initialProvincesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect);
        setPaymenetTypeSelect(initialPaymentToSelect);
        navigate(typeOfList==="1"?"/purchasedelivery":typeOfList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin");
      }
    },
    [
      setFormPurchaseDelivery,
      setIsLoadingData,
      setIsLoadingErrorData,
      setErrorsForm,
      setTetxtSearch,  
      setIdCodeDeletePurchaseDelivery,
      setShowModalSearchStores,
      setShowModalSearchVehicles,
      setShowModalSearchStoresAddress,
      setShowModalSearchArticles, 
      setOpenDeleteRowArticles,
      setEditRowArticles,   
      setShowModalSearchDI,
      setProvinceToSelect,
      setPostalCodeToSelect,
      setPaymenetTypeSelect,
      setOpenSignDispatched,
      setOpenSignClient,
      setSignDispatchedCanvas,
      setSignClientCanvas,
      showModalPurchasesDeliverySettings,
      navigate,
      typeOfList,
      DELETE_CODE]
  );
  
  const handleCloseDataEditPurchaseDelivery = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      linespurchasedelivery: [],
    }));
    setFormPurchaseDelivery(initialStatePurchaseDelivery);
    setIsLoadingData(false);
    setIsLoadingErrorData(false);
    setErrorsForm({});
    setTetxtSearch("Rel_Trans ");   
    setIdCodeDeletePurchaseDelivery(DELETE_CODE);
    setShowModalSearchStores(false);
    setShowModalSearchVehicles(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchArticles(false); 
    setOpenDeleteRowArticles(false); 
    setShowModalSearchDI(false);
    setOpenSignDispatched(false);
    setOpenSignClient(false);
    setSignDispatchedCanvas();
    setSignClientCanvas();
    setEditRowArticles(initialArticlesToSelect); 
    setProvinceToSelect(initialProvincesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setPaymenetTypeSelect(initialPaymentToSelect);
    navigate(typeOfList==="1"?"/purchasedelivery":typeOfList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin");
	};

  const selectModalSignature = (id) => {
    switch (id) {
      case 1:   
        setOpenSignDispatched(true);  
        break;
      case 2:    
        setOpenSignClient(true);
        break;
      default:
        break;
    } 
  };

  const deleteSignature = (id) => {
    switch (id) {
      case 1:   
      setFormPurchaseDelivery(formPurchaseDelivery => ({
          ...formPurchaseDelivery,
          signature_dispatched_by: "",
        }));
        break;
      case 2:    
      setFormPurchaseDelivery(formPurchaseDelivery => ({
          ...formPurchaseDelivery,
          signature_client: "",
        }));
        break;
      default:
        break;
    } 
  };
  
  const handleCancelSignDispatched = () => {
    signDispatchedCanvas.clear();
    setOpenSignDispatched(false);
  };

  const handleDeleteSignDispatched = () => {
    signDispatchedCanvas.clear();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: "",
    }));
    setOpenSignDispatched(false);
  };
  const handleSubmitSignDispatched = () => {
    const dataURL = signDispatchedCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by:dataURL,
    }));
    setOpenSignDispatched(false);
  };
  
  const handleSetSignatureFrancisco = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signFrancisco,
    }));
  };

  const handleSetSignatureGonzalo = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signGonzalo,
    }));
  };
  
  const handleSetSignatureGuillermo = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signGuillermo,
    }));
  };
  
  const handleSetSignatureJorge = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signJorge,
    }));
  };

  const handleCancelSignClient = () => {
    signClientCanvas.clear();
    setOpenSignClient(false);
  };

  const handleDeleteSignClient = () => {
    signClientCanvas.clear();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_client: "",
    }));
    setOpenSignClient(false);
  };
  const handleSubmitSignClient = () => {
    const dataURL = signClientCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_client:dataURL,
    }));
    setOpenSignClient(false);
  };

  const handleChangeDataEditPurchaseDelivery = (e) => {
    const { name, value } = e.target;
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      [name]: value,
    }));
  };

  const handleBlurDataEditPurchaseDelivery = (e) => {
    handleChangeDataEditPurchaseDelivery(e);
    setErrorsForm(validatePurchaseDelivery());
  }; 

  const handleBlurSelectDate = (e) => {
    handleChangeSelectDate(e);
    setErrorsForm(validatePurchaseDelivery());
  };

  const handleChangeSelectDate = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      datepurchase: event!==null?new Date(event):null
    }));
  };

  const handleBlurTotalWeightNeto = (value) => {
    handleChangeTotalWeightNeto(value);
  };

  const handleChangeTotalWeightNeto = (value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" Tn","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      totalWeigthNeto: newValue
    }));
  };

  const searchStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStores(true);
  };

  const deleteDataStoreAddressOriTras = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validatePurchaseDelivery());
  };

  const searchStoreAddress = (event) => {
    setShowModalSearchStoresAddress(true);
  };

  const deleteDataStoreRelTrans = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      rel_trans_store_id: 0,
      rel_trans_documnettype_id: 0,
      rel_trans_documnettype_name: "",
      rel_trans_documentid: "",
      rel_trans_tradename: "",
      rel_trans_name: "",
      rel_trans_contacperson: "",
      rel_trans_nima: "",
      rel_trans_pygr: "",
      rel_trans_grnp:"",
      rel_trans_opt: 0,
      rel_trans_roadtype_id: 0,
      rel_trans_street: "",
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
    }));  
    setErrorsForm(validatePurchaseDelivery());
  };

  const searchVehicles = (event) => { 
    setShowModalSearchVehicles(true);
  };
 
  const deleteDataVehicles = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      vehiclestore_id:0,
      vehiclestore_licenseplate: "",
      vehiclestore_defaultdriver:""
    })); 
    setErrorsForm(validatePurchaseDelivery());
  };

  const deleteDataStoreOriTras = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      ori_tras_store_id: 0,
      ori_tras_documnettype_id: 0,
      ori_tras_documnettype_name: "",
      ori_tras_documentid: "",
      ori_tras_tradename: "",
      ori_tras_name: "",
      ori_tras_contacperson: "",
      ori_tras_nima: "",
      ori_tras_pygr: "",
      ori_tras_grnp:"",
      ori_tras_opt: 0,
      ori_tras_workplace: "",
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
      ori_tras_email: ""
    }));  
    setErrorsForm(validatePurchaseDelivery());
  };

  const searchStoreOriTras = (event) => {
    setTetxtSearch("Ori_Tras")
    setShowModalSearchStores(true);
  };

  const handleCancelDeleteRow = () => { 
    setEditRowArticles(initialArticlesToSelect); 
    setIsLoadingData(false);    
    setIsLoadingErrorData(false);
    setIdCodeDeletePurchaseDelivery(DELETE_CODE);
    setOpenDeleteRowArticles(false);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDeletePurchaseDelivery(value);
  };

  const handleCloseDeleteRow = (e) => {
    e.preventDefault();
    if (idCodeDeletePurchaseDelivery!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formPurchaseDelivery.linespurchasedelivery.findIndex(((item) => item.indexrowarticle === editRowArticles.indexrowarticle));
      if ( indexDelete !== -1 ) {
        formPurchaseDelivery.linespurchasedelivery.splice( indexDelete, 1 );
      }
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
      })) 
      let totalNeto=0; 
      formPurchaseDelivery.linespurchasedelivery.map((item)=>{
        return totalNeto+=item.total;
      });
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        totalNetoPurchasedelivery:totalNeto
       }));
      setIdCodeDeletePurchaseDelivery(DELETE_CODE);
      setOpenDeleteRowArticles(false);
    }
  };
   
  const selectArticle = (post) => {
    
    let articleToAdd={
      "id":"",
      "indexrowarticle":formPurchaseDelivery.linespurchasedelivery.length>0?formPurchaseDelivery.linespurchasedelivery[formPurchaseDelivery.linespurchasedelivery.length-1].indexrowarticle+1:1,
      "purchase_id":formPurchaseDelivery.id,
      "article_id":post.button_article_id,
      "article_code":post.button_article_code,
      "article_namees":post.button_article_namees,
      "article_nameen":post.button_article_nameen,
      "datepurchase":formPurchaseDelivery.datepurchase,
      "estimatedprice":post.button_article_estimatedprice,
      "amount":0,
      "discount":0,
      "tax":post.button_article_tax,
      "taxincluded": post.button_article_taxincluded, 
      "total": 0 ,
      "ticketweight":post.button_article_ticketweight,
      "formdi_id":0,
      "ownformdi_id":0
      
    }
    formPurchaseDelivery.linespurchasedelivery.push(articleToAdd);
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      totalpurchasedelivery:0,
      totaldiscountpurchasedelivery:0,
      totaltaxespurchasedelivery:0 
    })); 
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery
    }));
  };

  const withValueCap3 = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000000) return true;
    return false;
  };
 
  const handleSubmitDataEditPurchaseEdit = () => {
    const errors = validatePurchaseDelivery();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) 
        && formPurchaseDelivery.linespurchasedelivery!==undefined  
        && formPurchaseDelivery.linespurchasedelivery.length>0 ) {
      const fetchData = async () => {
        setIsLoadingData(true);
        let isMounted = true;
        try {
          const getData = id !== "0"
          ? await helpHttp().put(ENDPOINT, `purchasedelivery/updatepurchasedelivery`, token, formPurchaseDelivery)
          : await helpHttp().post(ENDPOINT, `purchasedelivery/savepurchasedelivery`, token, formPurchaseDelivery);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData'))); 
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
          handleCloseDataEditPurchaseDelivery();
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      fetchData();
      setIsLoadingData(false); 
    } else {
      if (formPurchaseDelivery.linespurchasedelivery===undefined  
        || formPurchaseDelivery.linespurchasedelivery.length===0 ){
          dispatch(warningErrorAction(t('Text_FieldArticlesRequired')));
      }else{
        dispatch(warningErrorAction(t('Text_IncorrectData')));      
      }
    }
  };

  const validatePurchaseDelivery = () => {
    let errorsForm = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (regexInvalidCharacters.test(formPurchaseDelivery.ownnumberpurchase)) {
      errorsForm.ownnumberpurchase = 'Text_TextNoValid';
    }

    if (formPurchaseDelivery.subownnumberpurchase!=="" & !regexNumber.test(formPurchaseDelivery.subownnumberpurchase)) {
      formPurchaseDelivery.subownnumberpurchase = 'Text_TextNoNumber';
    }

    if (regexInvalidCharacters.test(formPurchaseDelivery.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formPurchaseDelivery.vehiclestore_licenseplate)) {
      errorsForm.vehiclestore_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formPurchaseDelivery.rel_trans_tradename)) {
      errorsForm.rel_trans_tradename = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_nima)) {
      errorsForm.ori_tras_nima = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_pygr)) {
      errorsForm.ori_tras_pygr = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_grnp)) {
      errorsForm.ori_tras_grnp = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_workplace)) {
      errorsForm.ori_tras_workplace = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_street)) {
      errorsForm.ori_tras_street = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_email)) {
      errorsForm.ori_tras_email = 'Text_TextNoValid';
    } 

    if (regexInvalidCharacters.test(formPurchaseDelivery.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    } 
    return errorsForm;
  };
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getstorebynumberdocument/`,token,formPurchaseDelivery.rel_trans_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formPurchaseDelivery.rel_trans_documentid){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchStoreNumberDocumentRelTrans = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`purchasedelivery/getstorebynumberdocumentpriority`,token,formPurchaseDelivery.rel_trans_documentid,6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setFormPurchaseDelivery(formPurchaseDelivery => ({
                      ...formPurchaseDelivery,
                      rel_trans_store_id: getData.rel_trans_store_id,
                      rel_trans_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      rel_trans_documentid: getData.rel_trans_documentid,
                      rel_trans_documnettype_name: getData.rel_trans_documnettype_name,
                      rel_trans_tradename: getData.rel_trans_tradename,
                      rel_trans_name: getData.rel_trans_name,
                      rel_trans_contacperson: getData.rel_trans_contacperson,
                      rel_trans_nima: (formPurchaseDelivery.rel_trans_nima===undefined 
                        ||formPurchaseDelivery.rel_trans_nima===null
                        ||formPurchaseDelivery.rel_trans_nima==="")
                        ?getData.rel_trans_nima:formPurchaseDelivery.rel_trans_nima,
                      rel_trans_pygr: (formPurchaseDelivery.rel_trans_pygr===undefined 
                        ||formPurchaseDelivery.rel_trans_pygr===null
                        ||formPurchaseDelivery.rel_trans_pygr==="")
                        ?getData.rel_trans_pygr:formPurchaseDelivery.rel_trans_pygr,
                      rel_trans_grnp: (formPurchaseDelivery.rel_trans_grnp===undefined 
                          ||formPurchaseDelivery.rel_trans_grnp===null
                          ||formPurchaseDelivery.rel_trans_grnp==="")
                          ?getData.rel_trans_grnp:formPurchaseDelivery.rel_trans_grnp,
                      rel_trans_opt: (getData.rel_trans_opt===undefined 
                            || getData.rel_trans_opt===null  
                            || getData.rel_trans_opt===""
                            || getData.rel_trans_opt==="0"
                            || getData.rel_trans_opt===0)?
                            0:getData.rel_trans_opt, 
                      rel_trans_roadtype_id: (getData.rel_trans_roadtype_id===undefined 
                                            || getData.rel_trans_roadtype_id===null  
                                            || getData.rel_trans_roadtype_id===""
                                            || getData.rel_trans_roadtype_id==="0"
                                            || getData.rel_trans_roadtype_id===0)?
                                            0:getData.rel_trans_roadtype_id,
                      rel_trans_street: getData.rel_trans_street,
                      rel_trans_country_id: (getData.rel_trans_country_id===undefined 
                                            || getData.rel_trans_country_id===null  
                                            || getData.rel_trans_country_id===""
                                            || getData.rel_trans_country_id==="0"
                                            || getData.rel_trans_country_id===0)?
                                            Number(DEFAULTCOUNTRY):getData.rel_trans_country_id,
                      rel_trans_province_id: (getData.rel_trans_province_id===undefined 
                                            || getData.rel_trans_province_id===null  
                                            || getData.rel_trans_province_id===""
                                            || getData.rel_trans_province_id==="0"
                                            || getData.rel_trans_province_id===0)?
                                            Number(DEFAULTPROVINCE):getData.rel_trans_province_id,
                      rel_trans_postalcode_id: (getData.rel_trans_postalcode_id===undefined 
                                                || getData.rel_trans_postalcode_id===null  
                                                || getData.rel_trans_postalcode_id===""
                                                || getData.rel_trans_postalcode_id==="0"
                                                || getData.rel_trans_postalcode_id===0)?
                                                "":getData.rel_trans_postalcode_id 
                  }));
              }  
          }         
        } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
    };
    if (formPurchaseDelivery.rel_trans_documentid!==""){
      let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
      if (!regexInvalidCharacters.test(formPurchaseDelivery.rel_trans_documentid)) {
        fetchExistNumberDocumentRelTrans();
      } 
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formPurchaseDelivery.rel_trans_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormPurchaseDelivery]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlate = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getexistvehicleylicenseplate/`,token,formPurchaseDelivery.vehiclestore_licenseplate);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formPurchaseDelivery.vehiclestore_licenseplate){
            fetchLicensePlate();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getvehiclebylicenseplate/`,token,formPurchaseDelivery.vehiclestore_licenseplate);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setFormPurchaseDelivery(formPurchaseDelivery => ({
                  ...formPurchaseDelivery,
                  vehiclestore_id: getData.id,
                  vehiclestore_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id 
              }));
              if (formPurchaseDelivery.rel_trans_documentid===undefined || formPurchaseDelivery.rel_trans_documentid===null || formPurchaseDelivery.rel_trans_documentid==="" ){
                if (formPurchaseDelivery.rel_trans_tradename===undefined || formPurchaseDelivery.rel_trans_tradename===null || formPurchaseDelivery.rel_trans_tradename===""){
                  setFormPurchaseDelivery(formPurchaseDelivery => ({
                    ...formPurchaseDelivery,
                    rel_trans_documentid: getData.store_documentid 
                  }));
                }
              }  
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    
    if (formPurchaseDelivery.vehiclestore_licenseplate!==""){
      let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
      if (!regexInvalidCharacters.test(formPurchaseDelivery.vehiclestore_licenseplate)) {
        fetchExistLicensePlate();
      } 
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formPurchaseDelivery.vehiclestore_licenseplate,formPurchaseDelivery.rel_trans_documentid,formPurchaseDelivery.rel_trans_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormPurchaseDelivery]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentOriTras = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getstorebynumberdocument/`,token,formPurchaseDelivery.ori_tras_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formPurchaseDelivery.ori_tras_documentid){
            fetchStoreNumberDocumentOriTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentOriTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`purchasedelivery/getstorebynumberdocumentpriority`,token,formPurchaseDelivery.ori_tras_documentid,2);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!=="" && getData!=="0" && getData!==0  && getData.rel_trans_store_id!==formPurchaseDelivery.ori_tras_store_id){
                setFormPurchaseDelivery(formPurchaseDelivery => ({
                      ...formPurchaseDelivery,
                      ori_tras_store_id: getData.rel_trans_store_id,
                      ori_tras_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      ori_tras_documnettype_name: getData.rel_trans_documnettype_name,
                      ori_tras_documentid: getData.rel_trans_documentid,
                      ori_tras_tradename: getData.rel_trans_tradename,
                      ori_tras_name: getData.rel_trans_name,
                      ori_tras_contacperson: getData.rel_trans_contacperson,
                      ori_tras_nima: getData.ori_tras_nima,
                      ori_tras_pygr: getData.ori_tras_pygr,
                      ori_tras_grnp: getData.ori_tras_grnp, 
                      ori_tras_opt: getData.ori_tras_opt,
                      ori_tras_email: getData.rel_trans_email      
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (formPurchaseDelivery.ori_tras_documentid!==""){
      let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
      if (!regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_documentid)) {
        fetchExistNumberDocumentOriTras();
      }  
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formPurchaseDelivery.ori_tras_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormPurchaseDelivery,formPurchaseDelivery.ori_tras_store_id]);


  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/simpleprovincesbycountrybyname/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalPurchasesDeliverySettings){
      fetchDataProvince();
    }

    return () => { isMounted = false };     
  }, [ENDPOINT,t,token,dispatch,i18n,showModalPurchasesDeliverySettings,DEFAULTCOUNTRY]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = formPurchaseDelivery.ori_tras_province_id!==""
            ? await helpHttp().get2(ENDPOINT,`purchasedelivery/simplepostalcodesbyprovincebyname/`,token,formPurchaseDelivery.ori_tras_province_id)
            : await helpHttp().get2(ENDPOINT,`purchasedelivery/simplepostalcodesbycountry/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalPurchasesDeliverySettings){
      fetchDataPostalCode(); 
    }
    if (formPurchaseDelivery.id === "" && formPurchaseDelivery.defaultStore_id===0) { 
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        defaultformatnumber:defaulttypesdi,
        isfinished:false,
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        ori_tras_email:"",
        linespurchasedelivery: [] 
      }));
    }
    return () => { isMounted = false };  
  }, [ENDPOINT,t,token,dispatch,i18n,showModalPurchasesDeliverySettings,formPurchaseDelivery.ori_tras_province_id,DEFAULTCOUNTRY,DEFAULTPROVINCE,formPurchaseDelivery.id,defaultStore.id,useraccount_id,defaulttypesdi,formPurchaseDelivery.defaultStore_id]);
 
  useEffect(() => {
    let isMounted = true;
  
    const fetchDataPayment = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPaymenetTypeSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
 
    if (showModalPurchasesDeliverySettings ) {  
      fetchDataPayment(); 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,showModalPurchasesDeliverySettings]);
   
  useEffect(() => {
    let isMounted = true;
    const fetchDataPurchaseDelivery = async () => {
      setIsLoadingData(true);
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`purchasedelivery/getpurchasedeliverybyid/`,token,id);
        if (getData.err) {
          setFormPurchaseDelivery(initialStatePurchaseDelivery);
          setIsLoadingErrorData(true);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setFormPurchaseDelivery(getData);
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingData(false);
      }  
    };

    if (id!=="0"){
      if (formPurchaseDelivery.id===""){
        fetchDataPurchaseDelivery(); 
        setIsLoadingData(false);
      }
    }else{
      setFormPurchaseDelivery(initialStatePurchaseDelivery);
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        defaultformatnumber:defaulttypesdi,
        isfinished:false,
        purchasenote:0,
        numberticket:"",
        ownnumberpurchase:"",
        datepurchase:null,
        vehiclestore_id: 0,
        vehiclestore_licenseplate: "",
        vehiclestore_defaultdriver: "",
        vehiclestore_store_id: 0,

        rel_trans_store_id:0,
        rel_trans_documnettype_id:0,
        rel_trans_documentid:"",
        rel_trans_documnettype_name:"",
        rel_trans_tradename:"",
        rel_trans_name:"",
        rel_trans_contacperson:"",
        rel_trans_nima: "",
        rel_trans_pygr: "",
        rel_trans_grnp:"",
        rel_trans_opt: 0,
        rel_trans_roadtype_id:0,
        rel_trans_street:"",
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,

        ori_tras_store_id: 0,
        ori_tras_documnettype_id: 0,
        ori_tras_documnettype_name:"",
        ori_tras_documentid:"",
        ori_tras_tradename:"",
        ori_tras_name:"",
        ori_tras_contacperson:"",
        ori_tras_nima: "",
        ori_tras_pygr: "",
        ori_tras_opt: 0,
        ori_tras_workplace: "",
        ori_tras_roadtype_id: 0,
        ori_tras_street:"",
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        ori_tras_email:"",

        observations:"", 
        totalpurchasedelivery: 0,
        totaldiscountpurchasedelivery: 0,
        totaltaxespurchasedelivery: 0,
        totalNetoPurchasedelivery:0,
        enabled:true,
        subownnumberpurchase:0,
        payment_id:0, 
        payment_code: "",
        payment_namees: "",
        payment_nameen: "", 
        totalWeigthNeto:0,
        signature_dispatched_by:"",
        signature_client:"",
        linespurchasedelivery: [] 

      }));
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        linespurchasedelivery:[]
      }));

      setIsLoadingData(false);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,dispatch,id,t,token,formPurchaseDelivery.id,DEFAULTCOUNTRY,DEFAULTPROVINCE,defaultStore.id,defaulttypesdi,useraccount_id]);  

 return (
      <>
        {showModalPurchasesDeliverySettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff', width: '1520px'}}>
              <form className="createform" onSubmit={handleCloseDataEditPurchaseDelivery}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className="h9NewStyle mb-2">
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className="fas fa-regular fa-clipboard text-warning"></i>)} 
                                  &nbsp;&nbsp;{id==="0"?t('TextPurchaseDeliver_New')+': '+formPurchaseDelivery.purchasenote:t('Text_PurchaseDeliver_Edit')+': '+formPurchaseDelivery.purchasenote}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitDataEditPurchaseEdit}>
                                      {id==="0"?t('Button_Create'):t('Button_Update')}
                                    </button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditPurchaseDelivery}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_OWNNUMPURCHASE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ownnumberpurchase" 
                                  name="ownnumberpurchase"
                                  value={formPurchaseDelivery.ownnumberpurchase}
                                  inputProps={{ style: {width: i18n.language==='es'?411:394}, maxLength: 20 }}
                                  label={errorsForm.ownnumberpurchase!=null?t(errorsForm.ownnumberpurchase):t('Text_PURCHASEDELIVERY_OWNNUMPURCHASE')}
                                  placeholder={t('Text_PURCHASEDELIVERY_OWNNUMPURCHASE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <label className="text-primary">{t('Text_PURCHASEDELIVERY_DATEPURCHAE')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="datepurchase" 
                                    name="datepurchase"
                                    value={ formPurchaseDelivery.datepurchase!== null ? new Date(formPurchaseDelivery.datepurchase):null}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    inputProps={{ style: {width: 200}, maxLength: 100 }}
                                    label={errorsForm.datepurchase!=null?t(errorsForm.datepurchase):t('Text_PURCHASEDELIVERY_DATEPURCHAE')}
                                    onBlur={handleBlurSelectDate}
                                    onChange={handleChangeSelectDate}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                          maxLength: 100,
                                          width: 200
                                        
                                        }
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PurchaseDeliver_TOTAL')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {2}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap3}
                                  id="total" 
                                  name="total"
                                  suffix={" €"}
                                  value={formPurchaseDelivery.totalNetoPurchasedelivery}
                                  style={{width:350, color:"#f5f5f5", background:"#bdbdbd",fontSize: 36, textAlign:'right', paddingRight:5}} 
                                  disabled
                                />  
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_CIF_TRANS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_documentid" 
                                  name="rel_trans_documentid"
                                  value={formPurchaseDelivery.rel_trans_documentid}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                  label={errorsForm.rel_trans_documentid!=null?t(errorsForm.rel_trans_documentid):t('Text_PURCHASEDELIVERY_CIF_TRANS')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Text_PURCHASEDELIVERY_CIF_TRANS')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                /> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreRelTrans}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreRelTrans}>
                                      <SearchIcon/>
                                  </IconButton>
                                }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_LICENSEPLATE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="vehiclestore_licenseplate" 
                                  name="vehiclestore_licenseplate"
                                  value={formPurchaseDelivery.vehiclestore_licenseplate}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 50 }}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.vehiclestore_licenseplate!=null?t(errorsForm.vehiclestore_licenseplate):t('Placeholder_PURCHASEDELIVERY_LICENSEPLATE')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_LICENSEPLATE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                /> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataVehicles}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))&&
                                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchVehicles}>
                                        <SearchIcon/>
                                    </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_TRANS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_tradename" 
                                  name="rel_trans_tradename"
                                  value={formPurchaseDelivery.rel_trans_tradename}
                                  inputProps={{ style: {width: i18n.language==='es'?412:385}, maxLength: 100 }} 
                                  label={errorsForm.rel_trans_tradename!=null?t(errorsForm.rel_trans_tradename):t('Placeholder_PURCHASEDELIVERY_NAME_TRANS')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_NAME_TRANS')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_NIMA')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_nima" 
                                  name="rel_trans_nima"
                                  value={formPurchaseDelivery.rel_trans_nima}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }} 
                                  label={errorsForm.rel_trans_nima!=null?t(errorsForm.rel_trans_nima):t('Placeholder_PURCHASEDELIVERY_TRADENAME_NIMA')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_NIMA')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_PYGR')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_grnp" 
                                  name="rel_trans_grnp"
                                  value={formPurchaseDelivery.rel_trans_grnp}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }} 
                                  label={errorsForm.rel_trans_grnp!=null?t(errorsForm.rel_trans_grnp):t('Placeholder_PURCHASEDELIVERY_TRADENAME_PYGR')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_PYGR')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_DRIVER')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="vehiclestore_defaultdriver" 
                                  name="vehiclestore_defaultdriver"
                                  value={formPurchaseDelivery.vehiclestore_defaultdriver}
                                  inputProps={{ style: {width: i18n.language==='es'?744:789}, maxLength: 100 }} 
                                  label={errorsForm.vehiclestore_defaultdriver!=null?t(errorsForm.vehiclestore_defaultdriver):t('Placeholder_PURCHASEDELIVERY_TRADENAME_DRIVER')}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_DRIVER')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_CIF_STORE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_documentid" 
                                  name="ori_tras_documentid"
                                  value={formPurchaseDelivery.ori_tras_documentid}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_documentid!=null?t(errorsForm.ori_tras_documentid):t('Text_PURCHASEDELIVERY_CIF_STORE')}
                                  placeholder={t('Text_PURCHASEDELIVERY_CIF_STORE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                /> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreOriTras}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreOriTras}>
                                      <SearchIcon/>
                                  </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_STORE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_tradename" 
                                  name="ori_tras_tradename"
                                  value={formPurchaseDelivery.ori_tras_tradename}
                                  inputProps={{ style: {width: i18n.language==='es'?546:530}, maxLength: 100 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_tradename!=null?t(errorsForm.ori_tras_tradename):t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery} 
                                />  
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_EMAIL_STORE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_email" 
                                  name="ori_tras_email"
                                  value={formPurchaseDelivery.ori_tras_email}
                                  inputProps={{ style: {width: i18n.language==='es'?250:250}, maxLength: 100 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_email!=null?t(errorsForm.ori_tras_email):t('Placeholder_PURCHASEDELIVERY_EMAIL_STORE')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_EMAIL_STORE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery} 
                                />  


                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_WORKPLACE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_workplace" 
                                  name="ori_tras_workplace"
                                  value={formPurchaseDelivery.ori_tras_workplace}
                                  inputProps={{ style: {width: i18n.language==='es'?1414:1357}, maxLength: 255 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_workplace!=null?t(errorsForm.ori_tras_workplace):t('Placeholder_PURCHASEDELIVERY_TRADENAME_WORKPLACE')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_WORKPLACE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery} 
                                />
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_ADDRESS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_street" 
                                  name="ori_tras_street"
                                  value={formPurchaseDelivery.ori_tras_street}
                                  inputProps={{ style: {width: i18n.language==='es'?474:498}, maxLength: 255 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  label={errorsForm.ori_tras_street!=null?t(errorsForm.ori_tras_street):t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE_ADDRESS')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE_ADDRESS')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery} 
                                />  
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreAddressOriTras}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreAddress}>
                                      <SearchIcon/>
                                    </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_PROVINCE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_province_id"
                                  name="ori_tras_province_id"
                                  value={ (formPurchaseDelivery.ori_tras_province_id === undefined || formPurchaseDelivery.ori_tras_province_id ===null || formPurchaseDelivery.ori_tras_province_id ===""
                                      || formPurchaseDelivery.ori_tras_province_id ==="0" || formPurchaseDelivery.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE):formPurchaseDelivery.ori_tras_province_id}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                  variant="outlined"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                  helperText={errorsForm.ori_tras_province_id!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                                  select
                                  SelectProps={{
                                      native: true,
                                      value: (formPurchaseDelivery.ori_tras_province_id === undefined || formPurchaseDelivery.ori_tras_province_id ===null || formPurchaseDelivery.ori_tras_province_id ===""
                                          || formPurchaseDelivery.ori_tras_province_id ==="0" || formPurchaseDelivery.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE): formPurchaseDelivery.ori_tras_province_id
                                  }}>
                                  <option key={0} value=""/>
                                  {provinceToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {option.name}
                                  </option>
                                  ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_POSTALCODE')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="ori_tras_postalcode_id"
                                name="ori_tras_postalcode_id"
                                value={ (formPurchaseDelivery.ori_tras_postalcode_id === undefined || formPurchaseDelivery.ori_tras_postalcode_id ===null || formPurchaseDelivery.ori_tras_postalcode_id ===""
                                    || formPurchaseDelivery.ori_tras_postalcode_id ==="0" || formPurchaseDelivery.ori_tras_postalcode_id ===0)?"":formPurchaseDelivery.ori_tras_postalcode_id}
                                inputProps={{ style: {width: i18n.language==='es'?250:250}, maxLength: 100 }}
                                variant="outlined"
                                onBlur={handleBlurDataEditPurchaseDelivery}
                                onChange={handleChangeDataEditPurchaseDelivery}
                                helperText={errorsForm.ori_tras_postalcode_id!=null && errorsForm.ori_tras_postalcode_id!==""?t(errorsForm.ori_tras_postalcode_id):""}
                                select
                                SelectProps={{
                                native: true,
                                value: (formPurchaseDelivery.ori_tras_postalcode_id === undefined || formPurchaseDelivery.ori_tras_postalcode_id ===null || formPurchaseDelivery.ori_tras_postalcode_id ===""
                                    || formPurchaseDelivery.ori_tras_postalcode_id ==="0" || formPurchaseDelivery.ori_tras_postalcode_id ===0)?"": formPurchaseDelivery.ori_tras_postalcode_id
                                }}>
                                <option key={0} value=""/>
                                {postalCodeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.postalcodename}
                                </option>
                                ))}
                              </TextField>
                              <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_OTHERS')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="observations" 
                                name="observations"
                                value={formPurchaseDelivery.observations}
                                inputProps={{ style: {width: i18n.language==='es'?1322:1335}, maxLength: 255 }} 
                                style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                label={errorsForm.observations!=null?t(errorsForm.observations):t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE_OTHERS')}
                                placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE_OTHERS')}
                                variant="standard"
                                onBlur={handleBlurDataEditPurchaseDelivery}
                                onChange={handleChangeDataEditPurchaseDelivery}
                              />
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <label className="text-primary">{t('Text_PurchaseDeliver_Payment')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="payment_id"
                                name="payment_id"
                                value={ (formPurchaseDelivery.payment_id === undefined || formPurchaseDelivery.payment_id ===null || formPurchaseDelivery.payment_id ===""
                                      || formPurchaseDelivery.payment_id ==="0" || formPurchaseDelivery.payment_id ===0)?2:formPurchaseDelivery.payment_id}
                                inputProps={{ style: {width: i18n.language==='es'?300:300}, maxLength: 255 }} 
                                variant="outlined"
                                onBlur={handleBlurDataEditPurchaseDelivery}
                                onChange={handleChangeDataEditPurchaseDelivery}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (formPurchaseDelivery.payment_id === undefined || formPurchaseDelivery.payment_id ===null || formPurchaseDelivery.payment_id ===""
                                      || formPurchaseDelivery.payment_id ==="0" || formPurchaseDelivery.payment_id ===0)?2: formPurchaseDelivery.payment_id
                                }}> 
                                {paymenetTypeSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_PurchaseDeliver_TOTAL_WEIGHT')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {2}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap3}
                                id="totalWeigthNeto" 
                                name="totalWeigthNeto"
                                suffix=' Tn'
                                value={formPurchaseDelivery.totalWeigthNeto}
                                style={{width:250, color:"#red", background:"#black",fontSize: 28, textAlign:'right', paddingRight:5}} 
                                onBlur={(e) => handleBlurTotalWeightNeto(e.target.value)}
                                onChange={(e) => handleChangeTotalWeightNeto(e.target.value)}
                              />   
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <TableSortingPurchaseDeliveryArticleList
                                dataArticles={formPurchaseDelivery.linespurchasedelivery} 
                                numTotRegArticles={formPurchaseDelivery.linespurchasedelivery.length}
                                setOpenDeleteRowArticles={setOpenDeleteRowArticles}
                                setEditRowArticles={setEditRowArticles}
                                formPurchaseDelivery={formPurchaseDelivery}                                  
                                setFormPurchaseDelivery={setFormPurchaseDelivery}
                                setShowModalSearchArticles={setShowModalSearchArticles}
                                LIMITPAGESIZE={LIMITPAGESIZE}
                                setShowModalSearchDI={setShowModalSearchDI}
                              />
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <Grid container spacing={8} justify="center">
                                <Grid item xs={2} sm={4} md={2} key={1}>
                                  <Card sx={{ width: 250 }}>
                                    <CardMedia
                                      sx={{ height: 150 }}
                                      image={(formPurchaseDelivery.signature_dispatched_by!==undefined && formPurchaseDelivery.signature_dispatched_by!=="")?formPurchaseDelivery.signature_dispatched_by:image1}
                                      title={t("Text_Signature_DispatchedBy")}
                                    />
                                    <CardContent>
                                      <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                        {t( "Text_Signature_DispatchedBy")}
                                      </Typography>
                                    </CardContent>
                                    <CardActions>
                                      <Button size="small" onClick={(e) => selectModalSignature(1)}>{t('Button_Sign')}</Button>
                                      <Button size="small" onClick={(e) => deleteSignature(1)}>{t('Button_Delete')}</Button>
                                    </CardActions>
                                  </Card>
                                </Grid>
                                <Grid item xs={2} sm={4} md={2} key={2}>
                                  <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                    <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <ButtonGroup
                                        orientation="vertical"
                                        aria-label="Vertical button group"
                                        variant="contained"
                                        sx={{ gap: 2 }}
                                      >
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 14, padding: 5 }} onClick={handleSetSignatureFrancisco} >
                                          {t("Text_SIGN_FRANCISCO")}
                                        </Button>
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 14, padding: 5 }} onClick={handleSetSignatureGonzalo}>
                                          {t("Text_SIGN_GONZALO")}
                                        </Button>
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 14, padding: 5 }} onClick={handleSetSignatureGuillermo}>
                                          {t("Text_SIGN_GUILLLERMO")}
                                        </Button>
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 14, padding: 5 }} onClick={handleSetSignatureJorge}>
                                          {t("Text_SIGN_JORGE")}
                                        </Button>
                                      </ButtonGroup>
                                    </CardContent>
                                  </Card>
                                </Grid>

                                
                                <Grid item xs={2} sm={4} md={2} key={3}>
                                  <Card sx={{ width: 250 }}>
                                    <CardMedia
                                      sx={{ height: 150 }}
                                      image={(formPurchaseDelivery.signature_client!==undefined && formPurchaseDelivery.signature_client!=="")?formPurchaseDelivery.signature_client:image1}
                                      title={t("Text_Signature_client")}
                                    />
                                    <CardContent>
                                      <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                        {t("Text_Signature_client")}
                                      </Typography>
                                    </CardContent>
                                    <CardActions>
                                      <Button size="small" onClick={(e) => selectModalSignature(2)}>{t('Button_Sign')}</Button>
                                      <Button size="small" onClick={(e) => deleteSignature(2)}>{t('Button_Delete')}</Button>
                                    </CardActions>
                                  </Card>
                                </Grid> 
                              </Grid>
                              <hr className="sidebar-divider d-none d-md-block"/>
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 2, md: 12 }}>
                                    {articlesButtonsList.map((post, index) => (
                                      <Grid item xs={2} sm={2} md={2} key={index}>
                                        <Item>
                                          <Button variant="contained" style={{color:"#ffffff", background:post.button_color,fontSize: 14}} onClick={()=>selectArticle(post)}>
                                            {i18n.language==='es'?post.button_namees:post.button_nameen}
                                          </Button>
                                        </Item>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                              </div>                              
                            </div>
                          </div>   
                        </div>
                      </div>
                    </div>
                  </div>
                </>:
                  <Loading isLoadingData={isLoadingData}/>
                }
              </form>
            </Box>
            <br/>
            
            <PurchaseDeliverySearchStores
              showModalSearchStores={showModalSearchStores}
              setShowModalSearchStores={setShowModalSearchStores}
              selectStores={tetxtSearch}
              setFormPurchaseDelivery={setFormPurchaseDelivery}
            />
            <PurchaseDeliverySearchVehicles
              showModalSearchVehicles={showModalSearchVehicles}
              setShowModalSearchVehicles={setShowModalSearchVehicles}
              setFormPurchaseDelivery={setFormPurchaseDelivery}
              formPurchaseDelivery={formPurchaseDelivery}
            /> 
            <PurchaseDeliverySearchStoresAddresses
              showModalSearchStoresAddress={showModalSearchStoresAddress}
              setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
              setFormPurchaseDelivery={setFormPurchaseDelivery}
              titleTable={formPurchaseDelivery.ori_tras_tradename}
              store_id={formPurchaseDelivery.ori_tras_store_id}
              priority={2}
            />
            <PurchaseDeliverySearchArticles
              showModalSearchArticles={showModalSearchArticles}
              setShowModalSearchArticles={setShowModalSearchArticles}
              formPurchaseDelivery={formPurchaseDelivery}                                  
              setFormPurchaseDelivery={setFormPurchaseDelivery} 
            /> 
            <PurchaseDeliverySearchDI
              showModalSearchDI={showModalSearchDI}
              setShowModalSearchDI={setShowModalSearchDI}
              editRowArticles={editRowArticles}
              setEditRowArticles={setEditRowArticles}
              formPurchaseDelivery={formPurchaseDelivery} 
              setFormPurchaseDelivery={setFormPurchaseDelivery} 
            /> 
            <Dialog open={openSignDispatched} onClose={handleCancelSignDispatched}>
              <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_DispatchedBy')} </DialogTitle>
                <DialogContent>
                  <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                      ref={dataOptTras=>setSignDispatchedCanvas(dataOptTras)}
                    />
                  </div>
                </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignDispatched}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignDispatched}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignDispatched}>{t('Button_Sign')}</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openSignClient} onClose={handleCancelSignClient}>
              <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_client')} </DialogTitle>
                <DialogContent>
                  <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                      ref={dataDestTras=>setSignClientCanvas(dataDestTras)}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignClient}>{t('Button_Cancel')}</Button>
                  <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignClient}>{t('Button_Delete')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignClient}>{t('Button_Sign')}</Button>
                </DialogActions>
            </Dialog> 
            
            <Dialog open={openDeleteRowArticles} onClose={handleCancelDeleteRow}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {editRowArticles.indexrowarticle}  - {editRowArticles.article_code} - {i18n.language==='es'?editRowArticles.article_namees:editRowArticles.article_nameen} </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeletePurchaseDelivery}
                  onChange={handleChangeDeleteRow}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

PurchaseDeliveryEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalPurchasesDeliverySettings: PropTypes.bool.isRequired,
  typeOfList: PropTypes.string.isRequired
};

export default PurchaseDeliveryEditOrCreate;